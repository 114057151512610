/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './fullReviewPage.css';
// react states
import { useEffect, useState } from 'react';
// react redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Features/userSlice';
// react router dom
import { useParams, useNavigate } from 'react-router-dom';
// custom hooks
import { useGetUserReview } from '../../../Hooks/GameReviews/useGetUserReview';
import { useGameDetailsFetch } from '../../../Hooks/useGameDetailsFetch';
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
import { useGiveReactions } from '../../../Hooks/Reactions/useGiveReactions';
import { useGetConsoleFromAcronym } from '../../../Hooks/Consoles/useGetConsoleFromAcronym';
// custom components
import Sharing from '../../Sharing/sharing';
import DiscussionArea from '../../DiscussionAreaComponents/discussionArea';
import { getReadableTime } from '../../TimeVarianceUtilityComponent/timingFunction';
// mui components
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Button, 
    // CircularProgress, Dialog, DialogContent, 
    IconButton, 
    // ListItemIcon, ListItemText, Menu, MenuItem 
} from '@mui/material';



export default function FullReviewPage(){
    const { review_id } = useParams();

    // To get the currently signed in user
    const signed_in_user = useSelector(selectUser);

    // To get the review
    const { reviewDetails } = useGetUserReview(review_id);

    // To get the game which is reviewed
    const { fetchingGame, gameDetails } = useGameDetailsFetch(reviewDetails !== null ? reviewDetails.gameID : null);

    // Variables to get how many days or hours before the review was posted.
    const [finalRevTime, setFinalRevTime] = useState(null);
    
    // To fetch the userDetails of the user who wrote the review
    const { getProfileDetails, userData, loadingUser } = useGetUserProfile();
    useEffect(()=>{
        if(reviewDetails !== null){
            getProfileDetails(reviewDetails.userID);

            // To get the time elapsed.
            const timeElapsed = getReadableTime(reviewDetails.date);
            setFinalRevTime(timeElapsed);
        }
    },[reviewDetails]);

    // To give and to get the reactions
    const { prevReaction, tempLikes, tempDislikes,
        handleClickLike, handleClickDislike } = useGiveReactions(
        'review',
        review_id,
        signed_in_user !== null ? signed_in_user.uid : null
    );

    // To share the review
    const [startSharingRev, setStartSharingRev] = useState(false);
    const openReviewSharingDialog = ()=>{
        setStartSharingRev(true);
    }
    const closeReviewSharingDialog = ()=>{
        setStartSharingRev(false);
    }

    // To navigate to gamepage & user profile
    const fullReviewNavigate = useNavigate();
    const navigateToMainGamePage = ()=>{
        if(reviewDetails !== null)
            fullReviewNavigate(`/game_page/${reviewDetails.gameID}`,{
                gameid: reviewDetails.gameID
            })
        else
            return;
    }

    const navigateToReviewUserProfile = ()=>{
        if(reviewDetails !== null){
            fullReviewNavigate(`/user_profile/${reviewDetails.userID}`)
        }else{
            return;
        }
    }

    // To navigate to reporting the review section.
    const handleNavigateToReporting = ()=>{
        if(signed_in_user !== null && signed_in_user !== undefined){
            fullReviewNavigate('/report', {
                state:{
                    'item': reviewDetails,
                    'reporter': signed_in_user.uid,
                    'type':'review'
                }
            })
        }else{
            fullReviewNavigate('/user_auth');
        }
    }

    // To navigate the user to the console page.
    const { consoleID } = useGetConsoleFromAcronym((reviewDetails !== null && reviewDetails !== undefined) ? reviewDetails.console_played_on : null);
    const handleNavigateToConsole = ()=>{
        if(consoleID !== null && consoleID !== undefined){
            fullReviewNavigate(`/consoles/${consoleID}`);
        }else{
            return;
        }
    }

    // To open the reply section of the review
    const [openCommentSection, setOpenCommentSection] = useState(true);
    const toggleCommentSection = ()=>{
        if(openCommentSection === true)
            setOpenCommentSection(false);
        else if(openCommentSection === false)
            setOpenCommentSection(true);
    }

    // To get the count of number of comments on the full review
    const [fullRevCommentsCount, setFullRevCommentsCount] = useState(0);
    // console.log(fullRevCommentsCount);

    return(
        <div className="full-review-container">
            {
                startSharingRev === true &&
                <Sharing
                    // type, sharingLink, extraText, startSharing, closeSharingDialog
                    type={'review'}
                    sharingLink = {`https://thegamerbroz.com/full_review/${review_id}`}
                    extraText={reviewDetails!==null ? reviewDetails.review_text.substring(0,25) : 'Check out this review on The Gamer Bros'}
                    startSharing={startSharingRev}
                    closeSharingDialog={closeReviewSharingDialog}
                />
            }
            {
                loadingUser === false && userData &&
                <div className="review-user-details-container">
                    <div className="review-user-details-pfp-box"
                         onClick={()=>navigateToReviewUserProfile()}
                    >
                    {
                        userData &&
                        userData.profile_picture === null &&
                        <p>{userData.Name.substring(0,1).toUpperCase()}</p>
                    }
                    {
                        userData && userData.profile_picture !== null &&
                        <img src={userData.profile_picture} alt=""/>
                    }
                    </div>
                    <button className='review-user-btn'
                            onClick={()=>navigateToReviewUserProfile()}
                    >
                    {
                        userData && `${userData.Name}`
                    }
                    </button>
                    <p className='review-user-details-container-p'>review of</p>
                </div>
            }
            {
                loadingUser === true &&
                <div className="full-review-user-loaders">
                    <div className="review-user-details-pfp-loader"></div>
                    <div className="review-user-btn-loader"></div>
                </div>
            }
            {
                fetchingGame === true &&
                <div className="full-review-game-details-loader"></div>
            }
            {
                fetchingGame === false && gameDetails &&
                <div className="full-review-game-details-container">
                {
                    gameDetails &&
                    <div className="full-review-game-pfp-box"
                         onClick={()=>navigateToMainGamePage()}
                    >
                    {
                        gameDetails.thumbnail_image !== null &&
                        <img src={gameDetails.thumbnail_image} alt={`${gameDetails.name}`}/>
                    }
                    {
                        (gameDetails.thumbnail_image === null || gameDetails.thumbnail_image === undefined) &&
                        <p className='review-user-details-container-p'>{gameDetails.name}</p>
                    }
                    </div>
                }
                    <div className="full-review-game-details-txts">
                    {
                        reviewDetails &&
                        <div className="full-review-spoiler-and-recommendations">
                        {
                            reviewDetails.spoilers === true &&
                            <div className="full-review-spoiler-prompt">
                                Spoiler Alert
                            </div>
                        }
                        {
                            reviewDetails.recommended === true &&
                            <div className="full-review-recommendation-prompt">
                                Recommended
                            </div>
                        }
                        </div>
                    }
                    {
                        gameDetails &&
                        <button className='full-review-game-name'
                                onClick={()=>navigateToMainGamePage()}
                        >
                            {gameDetails.name}
                        </button>
                    }
                    {reviewDetails && <p className='full-game-review-score'>{reviewDetails.score}/10</p>}
                    {finalRevTime && <p className='final-review-time-elapsed'>Written {finalRevTime}</p>}
                    </div>
                </div>
            }
            {
                reviewDetails &&
                <div className="full-review-game-console-div">
                    PLAYED ON : 
                    <Button variant='contained' sx={{ backgroundColor:'transparent', color:'#f8f8f8',
                            '&:hover': {
                                        backgroundColor: 'inherit', // Keeps the original background color on hover
                                        boxShadow: 'none',           // Removes the shadow on hover
                                    },
                            }}
                            onClick={()=>handleNavigateToConsole()}
                    >
                        {reviewDetails.console_played_on}
                    </Button>
                </div>
            }
            {
                reviewDetails &&
                <div className="full-game-review-text"
                     dangerouslySetInnerHTML={{
                        __html:reviewDetails.review_text
                     }}
                >

                </div>
            }
            <div className="full-review-action-container">
                <div className="review-like-div">
                    <IconButton aria-label='Like'
                                onClick={()=>handleClickLike()}
                    >
                        <ThumbUpIcon sx={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}/>
                    </IconButton>
                    <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}>
                        {tempLikes}
                    </p>
                </div>
                <div className="review-dislike-div">
                    <IconButton aria-label='Dislike'
                                onClick={()=>handleClickDislike()}
                    >
                        <ThumbDownIcon sx={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}/>
                    </IconButton>
                    <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}>
                        {tempDislikes}
                    </p>
                </div>
                <Button variant='contained' sx={{ 'backgroundColor':'transparent', border:'none', outline:'none',
                        color:(openCommentSection === true) ? '#FFE74C' : '#F8F8F8',
                        '&:hover': {
                            backgroundColor: 'inherit', // Keeps the original background color on hover
                            boxShadow: 'none',           // Removes the shadow on hover
                        },
                    }}
                        startIcon={<CommentIcon sx={{ 'fontSize':'18px', 'color':(openCommentSection === true) ? '#FFE74C' : '#F8F8F8'}}/>}
                    onClick={()=>toggleCommentSection()}
                >
                    Reply
                </Button>
                <Button aria-label='Share'
                        onClick={()=>openReviewSharingDialog()}
                        startIcon={<ShareIcon sx={{ color:'#f8f8f8' }}/>}
                        variant='contained' sx={{ 'backgroundColor':'transparent', border:'none', outline:'none',
                            color:'#F8F8F8',
                            '&:hover': {
                                backgroundColor: 'inherit', // Keeps the original background color on hover
                                boxShadow: 'none',           // Removes the shadow on hover
                            },
                        }}
                >
                    Share
                </Button>
                <Button aria-label='Share'
                        onClick={()=>handleNavigateToReporting()}
                        startIcon={<ReportProblemIcon sx={{ color:'#f8f8f8' }}/>}
                        variant='contained' sx={{ 'backgroundColor':'transparent', border:'none', outline:'none',
                            color:'#F8F8F8',
                            '&:hover': {
                                backgroundColor: 'inherit', // Keeps the original background color on hover
                                boxShadow: 'none',           // Removes the shadow on hover
                            },
                        }}
                >
                    Report
                </Button>
            </div>
            <div className="review-comments-area">
            {
                openCommentSection === true &&
                review_id !== null &&
                reviewDetails !== null &&
                <DiscussionArea discussionID={review_id} 
                                // gameID={discussionTopic.gameID}
                                taggedGames={[reviewDetails.gameID]}
                                section={'review'}
                                commentsCounter={setFullRevCommentsCount}
                                fullDoc={reviewDetails}
                />
            }
            </div>
        </div>
    )
}