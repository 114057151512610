// styles
import './createdCommentForum.css';
// custom hook
import { useDiscussionFetch } from '../../../Hooks/Forums/useDiscussionFetch';
// routing
import { useNavigate } from 'react-router-dom';

export default function CreatedCommentForumDetails({ discID }){
    
    const { fetching, discussionTopic } = useDiscussionFetch(discID);

    // To navigate to a discussion
    const profileCommentNavigate = useNavigate();
    const navigateToDiscussion = ()=>{
        profileCommentNavigate(`/discussion/${discID}`);
    }
    
    return(
        <p className='user-comment-box-forum'
           onClick={()=>navigateToDiscussion()}
        >
        {
            fetching === false && discussionTopic !== null && 
            `${discussionTopic.title}`
        }
        </p>
    )
}