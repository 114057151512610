// styles
import './addGamesToCollection.css';
// react states
import { useState } from 'react';
// mui material components
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
// custom hook
import { useFetchGamesFromDatabase } from '../../../Hooks/Searching/useFetchGamesFromDatabase';
import { useGameSearching } from '../../../Hooks/Searching/useGameSearching';
// custom components
import UserSearchGameTile from '../UserGamesComponents/userSearchGameTile';
export default function AddGamesToCollection({ openGameCollection, openDialog, collectionType, userCollectionArray }){
    // To handle opening and closing of the dialog
    const handleOnClose = ()=>{
        openDialog(false);
    }

    // To get the search text
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchInput = (e)=>{
        setSearchTerm(e.target.value);
    }

    // custom hook to fetch the games on the basis of user search
    const { fetchedGamesFirstList, fetchedGamesSecondList, fetchedGamesThirdList } = useFetchGamesFromDatabase();

    // To search for the terms
    const result_list_one = useGameSearching(searchTerm, fetchedGamesFirstList);
    const result_list_two = useGameSearching(searchTerm, fetchedGamesSecondList);
    const result_list_three = useGameSearching(searchTerm, fetchedGamesThirdList);

    return(
        <Dialog
            open={openGameCollection}
            onClose={handleOnClose}
            fullWidth
            maxWidth={'sm'}
        >
            <DialogTitle className='add-games-collection-title'>
                {collectionType ==='allGames' ? `Add games to your collection` : `Add games to your wishlist` }
            </DialogTitle>
            <DialogContent className='add-games-collection-container'>
                <input className='add-games-collection-input'
                       placeholder='Search games you want to add here...'
                       value={searchTerm}
                       onChange={handleSearchInput}
                       autoFocus={true}
                />
                <div className="game-collection-list">
                {
                    result_list_one.finalSearchList.length > 0 &&
                    result_list_one.finalSearchList.map((firstgame)=>(
                        Boolean(!userCollectionArray.includes(firstgame.id)) &&
                        <UserSearchGameTile key={firstgame.id} 
                            gameInfo={firstgame}
                            collectionType={collectionType}    
                        />
                    ))
                }
                {
                    result_list_two.finalSearchList.length > 0 &&
                    result_list_two.finalSearchList.map((secondgame)=>(
                        Boolean(!userCollectionArray.includes(secondgame.id)) &&
                        <UserSearchGameTile key={secondgame.id} 
                            gameInfo={secondgame}
                            collectionType={collectionType}    
                        />
                    ))
                }
                {
                    result_list_three.finalSearchList.length > 0 &&
                    result_list_three.finalSearchList.map((thirdgame)=>(
                        Boolean(!userCollectionArray.includes(thirdgame.id)) &&
                        <UserSearchGameTile key={thirdgame.id} 
                            gameInfo={thirdgame}
                            collectionType={collectionType}    
                        />
                    ))
                }
                </div>
            </DialogContent>
        </Dialog>
    )
}