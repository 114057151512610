/* eslint-disable no-unused-vars */
// react states
import { useEffect, useState } from 'react';
// firebase
import { db } from '../Firebase/firebase';
import { collection, onSnapshot, query } from "firebase/firestore";


export const useCheckUsernameAvailibility = ()=>{
    const [isAvailable, setIsAvailable] = useState(null);
    const [checking, setChecking] = useState(false);
    // const [listener, setListener] = useState(null);
    const [usernameArr, setUsernameArr] = useState([]);

    useEffect(()=>{
        const unsub = onSnapshot(query(collection(db,"Users")),
            (snapshot)=>{
                if(!snapshot.empty){
                    var tempArr = [];
                    for(const user of snapshot.docs){
                        tempArr.push(user.data().Name);
                    }
                    // console.log('tempArr => ',tempArr);
                    setUsernameArr(tempArr);
                }
            },
            (error)=>{
                console.log(error.message);
            }
        );
        // setListener(unsub);
    },[])

    // console.log('user_array => ',usernameArr);
    const checkAvailibility = (usernameText)=>{
        setChecking(true);
        // console.log('usernameText in checkAvailibility => ',usernameText);
        if((usernameText && usernameText.length === 0) || !usernameText){
            setChecking(false);
            setIsAvailable(null);
            return;
        }
        else{
            if(usernameArr && 
                usernameArr.length > 0
                && usernameArr.includes(usernameText)
            ){
                setIsAvailable(false);
                setChecking(false);
                return;
            }else{
                setIsAvailable(true);
                setChecking(false);
                return;
            }
        }
    }

    return { isAvailable, checking, checkAvailibility };
}