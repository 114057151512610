/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useEffect, useState } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { collection, getDocs, limit, query, startAfter } from 'firebase/firestore';


export const useFetchGamesFromDatabase = (limitPerList = 168)=>{
    const [fetchedGamesFirstList, setFetchedGamesFirstList] = useState([]);
    const [fetchedGamesSecondList, setFetchedGamesSecondList] = useState([]);
    const [fetchedGamesThirdList, setFetchedGamesThirdList] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [firstListLastGame, setFirstListLastGame] = useState(null);
    const [secondListLastGame, setSecondListLastGame] = useState(null);

    useEffect(()=>{
        // Putting everything back to normal
        setFetchedGamesFirstList([]);
        setFirstListLastGame(null);
        // Putting everything back to normal

        const fetchGamesFirstList = async()=>{
            setFetching(true);
            var searchQuery = query(collection(db,'Games'), limit(limitPerList));

            const searchQuerySnapshot = await getDocs(searchQuery).catch((err)=>{
                console.log(err.message);
                setFetching(false);
                return;
            });

            const tempFetchedArr = [];
            for(const game of searchQuerySnapshot.docs){
                tempFetchedArr.push({
                    'id':game.id,
                    ...game.data()
                })
            }

            // console.log('tempFetchArr => ',tempFetchedArr);

            const lastDoc = searchQuerySnapshot.docs[searchQuerySnapshot.docs.length - 1];
            setFirstListLastGame(lastDoc);
            setFetchedGamesFirstList(fetchedGamesFirstList => [...fetchedGamesFirstList, ...tempFetchedArr]);
            setFetching(false);
        }
        fetchGamesFirstList();
    },[])

    useEffect(()=>{
        setFetchedGamesSecondList([]);
        setSecondListLastGame(null);
        const fetchGamesSecondList = async()=>{
            setFetching(true);
            var searchQuery = query(collection(db,'Games'), startAfter(firstListLastGame), limit(limitPerList));

            const searchQuerySnapshot = await getDocs(searchQuery).catch((err)=>{
                console.log(err.message);
                setFetching(false);
                return;
            });

            const tempFetchedArr = [];
            for(const game of searchQuerySnapshot.docs){
                tempFetchedArr.push({
                    'id':game.id,
                    ...game.data()
                })
            }

            // console.log('tempFetchArr => ',tempFetchedArr);

            const lastDoc = searchQuerySnapshot.docs[searchQuerySnapshot.docs.length - 1];
            setSecondListLastGame(lastDoc);
            setFetchedGamesSecondList(fetchedGamesSecondList => [...fetchedGamesSecondList, ...tempFetchedArr]);
            setFetching(false);
        }
        if(firstListLastGame){
            fetchGamesSecondList();
        }
    },[firstListLastGame])

    useEffect(()=>{
        setFetchedGamesThirdList([]);
        const fetchGamesThirdList = async()=>{
            setFetching(true);
            var searchQuery = query(collection(db,'Games'), startAfter(secondListLastGame), limit(limitPerList));

            const searchQuerySnapshot = await getDocs(searchQuery).catch((err)=>{
                console.log(err.message);
                setFetching(false);
                return;
            });

            const tempFetchedArr = [];
            for(const game of searchQuerySnapshot.docs){
                tempFetchedArr.push({
                    'id':game.id,
                    ...game.data()
                })
            }
            setFetchedGamesThirdList(fetchedGamesThirdList => [...fetchedGamesThirdList, ...tempFetchedArr]);
            setFetching(false);
        }
        if(secondListLastGame){
            fetchGamesThirdList();
        }
    },[secondListLastGame])

    return { fetchedGamesFirstList, fetchedGamesSecondList, fetchedGamesThirdList, fetching };

}