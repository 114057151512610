// react states
import { useEffect, useState } from 'react';
// database
import { db } from '../../Firebase/firebase';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { addConsoleToUser, removeConsoleFromUser, selectUserData } from '../../Features/userPrivateDataSlice';

export const useConsoleOperations = ()=>{

    const [consoleOperationError, setConsoleOperationError] = useState(null);
    const [userCurrentConsoleArr, setUserCurrentConsoleArr] = useState([]);

    const userData = useSelector(selectUserData);
    useEffect(()=>{
        if(userData !== null && userData !== undefined){
            setUserCurrentConsoleArr(userData.all_consoles);
        }
    },[userData]);

    // To dispatch the changes in user state to show in frontend.
    const consoleDispatch = useDispatch();

    // For adding the console to user id in the backend
    const [addingConsole, setAddingConsole] = useState(false);
    const [addingStatus, setAddingStatus] = useState(null);
    const addConsoleToUserCollection = async (consoleData, userID)=>{
        setAddingConsole(true);
        setConsoleOperationError(null);
        setAddingStatus(null);
        // Make changes in the backend
        const userRef = doc(db, "Users", `${userID}`);
        // Atomically add a new region to the "regions" array field.
        await updateDoc(userRef, {
            'all_consoles': arrayUnion(consoleData.id)
        }).catch((err)=>{
            setConsoleOperationError(err.message);
            setAddingConsole(false);
            setAddingStatus('fail');
            return;
        });

        // To show changes in the frontend
        consoleDispatch(addConsoleToUser({
            'consoleID':consoleData.id
        }));

        setAddingConsole(false);
        setAddingStatus('success');

    }

    // Removing console from user
    const removeConsoleFromUserCollection = async (consoleData, userID)=>{
        setAddingConsole(true);
        setConsoleOperationError(null);
        setAddingStatus(null);
        // Make changes in the backend
        const userRef = doc(db, "Users", `${userID}`);
        // Atomically add a new region to the "regions" array field.
        await updateDoc(userRef, {
            'all_consoles': arrayRemove(consoleData.id)
        }).catch((err)=>{
            setAddingConsole(false);
            setAddingStatus('fail');
            setConsoleOperationError(err.message);
            return;
        });
        // // To show changes in the frontend
        consoleDispatch(removeConsoleFromUser({
            'consoleID':consoleData.id
        }));

        setAddingConsole(false);
        setAddingStatus('success');
        setConsoleOperationError(null);

    }

    return { addConsoleToUserCollection, removeConsoleFromUserCollection, consoleOperationError,
        addingConsole, addingStatus, userCurrentConsoleArr
     };
}