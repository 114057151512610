// styles
import './userWishlistOptions.css';
// mui components
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RemoveIcon from '@mui/icons-material/Remove';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
// react-routing
import { Link } from 'react-router-dom';
// custom hooks
import { useRemoveFromProfile } from '../../../Hooks/GameUserInteraction/useRemoveFromProfile';

export default function UserWishlistOptions({gameID, openOptions, setOpenOptions,
    userProfileID, loggedInUserID}){

    const { removingFromWishlist, removeFromUserWishlist } = useRemoveFromProfile();

    const handleRemoveFromUserWishlist = ()=>{
        removeFromUserWishlist({ 'id': gameID });
    }

    return(
        <Dialog
            open={openOptions}
            onClose={()=>setOpenOptions(false)}
            maxWidth={'sm'}
        >
            <DialogContent className='user-wishlist-options-box'>
                <Link to={`/game_page/${gameID}`} style={{ 'textDecoration':'none', 'width':'100%' }}>
                    <div className="user-wishlist-option-tile">
                        <SportsEsportsIcon style={{ fontSize:'2rem', color : '#FFE74C' }}/>
                        <p className='user-wishlist-option-tile-text'>Go to gamepage</p>
                    </div>
                </Link>
                {
                    userProfileID === loggedInUserID &&
                    <div className="user-wishlist-option-tile"
                         onClick={()=>handleRemoveFromUserWishlist()}
                    >
                        {
                            removingFromWishlist === true && <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                        }
                        {
                            removingFromWishlist === false && 
                            <>
                                <RemoveIcon style={{ fontSize:'2rem', color : '#FFE74C' }}/>
                                <p className='user-wishlist-option-tile-text'>Remove from wishlist</p>
                            </>
                        }
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}