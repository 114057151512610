// styles
import './report.css';
// react states
import { useState } from 'react';
// react router dom
import { useLocation } from 'react-router-dom';
import { useReporting } from '../../Hooks/useReporting';
import { CircularProgress } from '@mui/material';

export default function Report(){

    const reportLocation = useLocation();
    const { item, reporter, type } = reportLocation.state;

    // Custom hook to manage the sending of report to admins
    const { error, success, reporting, sendReportToAdmin } = useReporting();

    // console.log({
    //     item,
    //     reporter,
    //     type
    // })

    // To take input about the reason for reporting
    const [reason, setReason] = useState('');
    const [missingReason, setMissingReason] = useState(false);
    const handleReasonChange = (e)=>{
        e.preventDefault();
        setReason(e.target.value);
    }

    const sendReport = ()=>{
        if(reason.length === 0 || reason === null){
            setMissingReason(true);
            return;
        }else{
            // Do something here.
            if(type === 'picture'){
                sendReportToAdmin(reporter, reason, type, item.id, 'GameScreenshots');
            }

            if(type === 'review'){
                sendReportToAdmin(reporter, reason, type, item.id, 'GameReviews');
            }

            if(type === 'forum_discussion'){
                sendReportToAdmin(reporter, reason, type, item.id, 'Forums');
            }
        }
    }


    return(
        <div className='report-page-container'>
            <p>{`Why are you reporting this ${type}?`}</p>
            {
                type === 'picture' && 
                <div className="picture-container">
                    { item && <img src={item.image} alt=""/>}
                </div>
            }
            <input type="text" 
                required
                placeholder={`Tell us your reason for reporting this content`}
                onChange={(e)=>{
                    setMissingReason(false);
                    handleReasonChange(e);
                }}
                value={reason}
                disabled={ reporting === true ? true : false }
                className='reason-input'
            />
            { missingReason === true && <p style={{ margin:0, padding:0, 'color':'red', fontSize:'14px' }}>{'Please provide a reason for your reporting'}</p>}
            {
                reporting === true && <CircularProgress size={16} style={{ 'color':'#FFE74C' }}/>
            }
            {
                reporting === false && 
                    <button className='report-item-cta'
                            // onClick={()=>console.log({
                            //     item,
                            //     reporter,
                            //     reason,
                            //     type
                            // })}
                            onClick={()=>sendReport()}
                    >
                        Report
                    </button>
            }
            {
                error && <p style={{ margin:0, padding:0, 'color':'red', fontSize:'14px' }}>{error}</p>
            }
            {
                success && <p style={{ margin:0, padding:0, 'color':'green', fontSize:'15px' }}>{success}</p>
            }
        </div>
    )
}