//firebase
import { db } from '../Firebase/firebase';
import { doc, getDoc, setDoc, updateDoc, deleteDoc,
         collection, getDocs, where, query
       } from 'firebase/firestore';
import { useEffect, useState } from 'react';

export const useReactions = (reviewID, userID, gameID)=>{

    const [reviewReactionLoading, setReviewReactionLoading] = useState(false);
    const [totalLike, setTotalLike] = useState(0);
    const [totalDislike, setTotalDislike] = useState(0);
    const [userReaction, setUserReaction] = useState([]);


    useEffect(()=>{
        const getReviewReactions = async(reviewID, userID)=>{
            const likeQuery = query(collection(db, `GameReviews/${reviewID}/Liked`), where('state','==',1));
            const likeQuerySnapshot = await getDocs(likeQuery);
            setTotalLike(likeQuerySnapshot.docs.length);
    
            const dislikeQuery = query(collection(db, `GameReviews/${reviewID}/Liked`), where('state','==',-1));
            const dislikeQuerySnapshot = await getDocs(dislikeQuery);
            setTotalDislike(dislikeQuerySnapshot.docs.length);
    
            if(userID !== null){
                const userReactionRef = doc(db, `GameReviews/${reviewID}/Liked`, `${userID}`);
                const userReactionSnap = await getDoc(userReactionRef);
    
                let tempReactions = [];
                if( userReactionSnap.exists()){
                    if(userReactionSnap.data().state === 1){
                        tempReactions.push('L');
                        setUserReaction(tempReactions);
                    }else if(userReactionSnap.data().state === -1){
                        tempReactions.push('D');
                        setUserReaction(tempReactions);
                    }
                }else{
                    setUserReaction(tempReactions);
                }
            }
        }

        const getEditorReviewReactions = async(gameID, userID)=>{
            const editorLikeQuery = query(collection(db, `Games/${gameID}/Reviews/Editors/Liked`), where('state','==',1));
            const editorLikeQuerySnapshot = await getDocs(editorLikeQuery);
            setTotalLike(editorLikeQuerySnapshot.docs.length);
    
            const editorDislikeQuery = query(collection(db, `Games/${gameID}/Reviews/Editors/Liked`), where('state','==',-1));
            const editorDislikeQuerySnapshot = await getDocs(editorDislikeQuery);
            setTotalDislike(editorDislikeQuerySnapshot.docs.length);
    
            if(userID !== null){
                const userReactionRef = doc(db, `Games/${gameID}/Reviews/Editors/Liked`, `${userID}`);
                const userReactionSnap = await getDoc(userReactionRef);
    
                let tempReactions = [];
                if( userReactionSnap.exists()){
                    if(userReactionSnap.data().state === 1){
                        tempReactions.push('L');
                        setUserReaction(tempReactions);
                    }else if(userReactionSnap.data().state === -1){
                        tempReactions.push('D');
                        setUserReaction(tempReactions);
                    }
                }else{
                    setUserReaction(tempReactions);
                }
            }
        }
        
        if(reviewID === 'editors'){
            getEditorReviewReactions(gameID, userID);
        }else{
            getReviewReactions(reviewID, userID);
        }
    },[reviewID, userID, gameID])

    const reviewLike = async (reviewID, userID)=>{
        setReviewReactionLoading(true);
        const likeRef = doc(db, `GameReviews/${reviewID}/Liked`, `${userID}`);
        const likeSnap = await getDoc(likeRef);

        if(likeSnap.exists()){
            if(likeSnap.data().state === 1){
                // Delete this doc
                await deleteDoc(doc(db, `GameReviews/${reviewID}/Liked`, `${userID}`))
                .catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }else if(likeSnap.data().state === -1){
                //update state as 1
                await updateDoc(likeRef, {
                    'state': 1
                }).catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, `GameReviews/${reviewID}/Liked`, `${userID}`), {
                'state':1
            }).catch(()=>{
                setReviewReactionLoading(false);
                return;
            });;
        }

        setReviewReactionLoading(false);

    }

    const reviewDislike = async (reviewID, userID)=>{
        setReviewReactionLoading(true);
        const dislikeRef = doc(db, `GameReviews/${reviewID}/Liked`, `${userID}`);
        const dislikeSnap = await getDoc(dislikeRef);

        if(dislikeSnap.exists()){
            if(dislikeSnap.data().state === -1){
                // Delete this doc
                await deleteDoc(doc(db, `GameReviews/${reviewID}/Liked`, `${userID}`))
                .catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }else if(dislikeSnap.data().state === 1){
                //update state as 1
                await updateDoc(dislikeRef, {
                    'state': -1
                }).catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, `GameReviews/${reviewID}/Liked`, `${userID}`), {
                'state':-1
            }).catch(()=>{
                setReviewReactionLoading(false);
                return;
            });;
        }

        setReviewReactionLoading(false);

    }

    const editorReviewLike = async (gameID, userID)=>{
        setReviewReactionLoading(true);
        const editorLikeRef = doc(db, `Games/${gameID}/Reviews/Editors/Liked`, `${userID}`);
        const editorLikeSnap = await getDoc(editorLikeRef);

        if(editorLikeSnap.exists()){
            if(editorLikeSnap.data().state === 1){
                // Delete this doc
                await deleteDoc(doc(db, `Games/${gameID}/Reviews/Editors/Liked`, `${userID}`))
                .catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }else if(editorLikeSnap.data().state === -1){
                //update state as 1
                await updateDoc(editorLikeRef, {
                    'state': 1
                }).catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, `Games/${gameID}/Reviews/Editors/Liked`, `${userID}`), {
                'state':1
            }).catch(()=>{
                setReviewReactionLoading(false);
                return;
            });;
        }

        setReviewReactionLoading(false);

    }

    const editorReviewDislike = async (gameID, userID)=>{
        setReviewReactionLoading(true);
        const editorDislikeRef = doc(db, `Games/${gameID}/Reviews/Editors/Liked`, `${userID}`);
        const editorDislikeSnap = await getDoc(editorDislikeRef);

        if(editorDislikeSnap.exists()){
            if(editorDislikeSnap.data().state === -1){
                // Delete this doc
                await deleteDoc(doc(db, `Games/${gameID}/Reviews/Editors/Liked`, `${userID}`))
                .catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }else if(editorDislikeSnap.data().state === 1){
                //update state as 1
                await updateDoc(editorDislikeRef, {
                    'state': -1
                }).catch(()=>{
                    setReviewReactionLoading(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, `Games/${gameID}/Reviews/Editors/Liked`, `${userID}`), {
                'state':-1
            }).catch(()=>{
                setReviewReactionLoading(false);
                return;
            });;
        }

        setReviewReactionLoading(false);
    }

    

    return { reviewReactionLoading, totalLike, totalDislike,
             userReaction, reviewLike, reviewDislike, editorReviewLike, 
             editorReviewDislike };
}