// react states
import { useState } from 'react';
// firebase
import { 
    db,
     auth } from '../Firebase/firebase';
import { createUserWithEmailAndPassword, updateProfile,
         updateEmail
       } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
// react-redux
import { useDispatch } from 'react-redux';
// react-router-dom
import { useNavigate } from 'react-router-dom';
// react slices
import { login } from '../Features/userSlice';

export const useSignup = ()=>{

    const [error, setError] = useState(null);
    const [signingUp, setSigningUp] = useState(null);
    const [status, setStatus] = useState(null);
    
    const userSignupDispatch = useDispatch();
    const userSignupNavigate = useNavigate();

    const createUserAccount = (displayName, email, password)=>{
        setSigningUp(true);
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredentials)=>{
                // add user details to the firebase auth object
                updateProfile(userCredentials.user, {
                    'displayName': displayName,
                });
                // updateEmail(userCredentials.user,email);
                auth.currentUser.emailVerified = false;
                // Do this after user has verified mail.
                // Add the user in collection of users
                await setDoc(doc(db, "Users", `${userCredentials.user.uid}`), {
                    'Name':displayName,
                    'email':email,
                    'all_consoles':[],
                    'current_console':"",
                    'profile_picture':null,
                    'user_cover_image':null,
                    'isInDatabase':false
                }).then(()=>{
                    userSignupDispatch(login({
                        'uid':userCredentials.user.uid,
                        'email':email,
                        'isEmailVerified':false
                    }))
                    setSigningUp(false);
                }).then(()=>{
                    userSignupNavigate('/email_verification')
                }).catch((err)=>{
                    // console.log(err.message);
                    setError(err.message);
                    setSigningUp(false);
                    setStatus('Something went wrong! Please try again after few minutes...');
                    return;
                })
            })
    }

    // EXTERNAL AUTH PROVIDER SIGNUPS
    const createAccountWithGoogleAuth = async(userGoogleCredentials)=>{
        setSigningUp(true);
        updateProfile(userGoogleCredentials.user, {
            'displayName': userGoogleCredentials.user.displayName,
        });

        await setDoc(doc(db, "Users", `${userGoogleCredentials.user.uid}`), {
            'Name':userGoogleCredentials.user.displayName,
            'email':userGoogleCredentials.user.email,
            'all_consoles':[],
            'current_console':"",
            'profile_picture':userGoogleCredentials.user.photoURL,
            'user_cover_image':null,
            'isInDatabase':true
        }).then(async()=>{
            await updateEmail(auth.currentUser, userGoogleCredentials.user.email).catch((emailUpdateError)=>{
                setError(emailUpdateError);
                setSigningUp(false);
                return;
            });
            await setDoc(doc(db, `Users/${userGoogleCredentials.user.uid}/PrivateInfo`,"userInfo"),{
                'DOB':null,
                'favourites':[],
                'game_collection':[],
                'currently_playing':[],
                'games_reviewed':[],
                'country':'',
                'playlist':[],
                'wishlist':[]
            }).catch((profileUpdateError)=>{
                setError(profileUpdateError);
                setSigningUp(false);
                return;
            });
        }).then(()=>{
            userSignupDispatch(login({
                'uid':userGoogleCredentials.user.uid,
                'email':userGoogleCredentials.user.email,
                'isEmailVerified':userGoogleCredentials.user.emailVerified
            }));
            setSigningUp(false);
        }).catch((err)=>{
            // console.log(err.message);
            setError(err.message);
            setSigningUp(false);
            setStatus('Something went wrong! Please try again after few minutes...');
            return;
        })
    }

    const createAccountWithFacebookAuth = async(userFbCred)=>{
        setSigningUp(true);
        updateProfile(userFbCred.user, {
            'displayName': userFbCred.user.displayName,
        });

        await setDoc(doc(db, "Users", `${userFbCred.user.uid}`), {
            'Name':userFbCred.user.displayName,
            'email':userFbCred.user.email,
            'all_consoles':[],
            'current_console':"",
            'profile_picture':userFbCred.user.photoURL,
            'user_cover_image':null,
            'isInDatabase':true
        }).then(async()=>{
            await updateEmail(auth.currentUser, userFbCred.user.email).catch((emailUpdateError)=>{
                setError(emailUpdateError);
                setSigningUp(false);
                return;
            });
            await setDoc(doc(db, `Users/${userFbCred.user.uid}/PrivateInfo`,"userInfo"),{
                'DOB':null,
                'favourites':[],
                'game_collection':[],
                'currently_playing':[],
                'games_reviewed':[],
                'country':'',
                'playlist':[],
                'wishlist':[]
            }).catch((profileUpdateError)=>{
                setError(profileUpdateError);
                setSigningUp(false);
                return;
            });
        }).then(()=>{
            userSignupDispatch(login({
                'uid':userFbCred.user.uid,
                'email':userFbCred.user.email,
                'isEmailVerified':userFbCred.user.emailVerified
            }));
            setSigningUp(false);
        }).catch((err)=>{
            // console.log(err.message);
            setError(err.message);
            setSigningUp(false);
            setStatus('Something went wrong! Please try again after few minutes...');
            return;
        })
    }

    return { error, status, signingUp, createUserAccount, 
        createAccountWithGoogleAuth, createAccountWithFacebookAuth };

}