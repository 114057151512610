/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useEffect, useState } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { query, collection, doc, getDocs, getDoc, setDoc, where
         , deleteDoc, updateDoc
       } from 'firebase/firestore';
       
export const useCommentsOperations = (section, comment_id, user_id)=>{
    // const [discussionError, setDiscussionError] = useState(null);
    const [processingComment, setProcessingComment] = useState(false);
    const [currentCommentLikes, setCurrentCommentLikes] = useState(0);
    const [currentCommentDislikes, setCurrentCommentDislikes] = useState(0);
    const [userCommentsReaction, setUserCommentsReaction] = useState([]);

    useEffect(()=>{
        if(comment_id !== null)
            setUserCommentReaction(comment_id, user_id);
    },[comment_id, user_id]);

    const setUserCommentReaction = async(commentID, userID)=>{
        var path = '';
        // console.log('commentID => ',typeof(comment_id));
        if(section === 'forum'){
            path = `ForumComments/${commentID}/Likes`;
        }

        if(section === 'review'){
            path = `ReviewComments/${commentID}/Likes`;
        }

        const likeQuery = query(collection(db, path), where('state','==',1));
        const likeQuerySnapshot = await getDocs(likeQuery);
        setCurrentCommentLikes(likeQuerySnapshot.docs.length);

        const dislikeQuery = query(collection(db, path), where('state','==',-1));
        const dislikeQuerySnapshot = await getDocs(dislikeQuery);
        setCurrentCommentDislikes(dislikeQuerySnapshot.docs.length);

        if(userID !== null){
            const userReactionRef = doc(db, path, `${userID}`);
            const userReactionSnap = await getDoc(userReactionRef);

            let tempReactions = [];
            if( userReactionSnap.exists()){
                if(userReactionSnap.data().state === 1){
                    tempReactions.push('L');
                    setUserCommentsReaction(tempReactions);
                }else if(userReactionSnap.data().state === -1){
                    tempReactions.push('D');
                    setUserCommentsReaction(tempReactions);
                }
            }else{
                setUserCommentsReaction(tempReactions);
            }
        }
    }
    
    const likeComment = async(commentID, userID)=>{
        var path = '';
        if(section === 'forum'){
            path = `ForumComments/${commentID}/Likes`;
        }
        
        if(section === 'review'){
            path = `ReviewComments/${commentID}/Likes`;
        }

        setProcessingComment(true);
        const likeRef = doc(db, path, `${userID}`);
        const likeSnap = await getDoc(likeRef);

        if(likeSnap.exists()){
            if(likeSnap.data().state === 1){
                // Delete this doc
                await deleteDoc(doc(db, path, `${userID}`))
                .catch(()=>{
                    setProcessingComment(false);
                    return;
                });
            }else if(likeSnap.data().state === -1){
                //update state as 1
                await updateDoc(likeRef, {
                    'state': 1
                }).catch(()=>{
                    setProcessingComment(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, path, `${userID}`), {
                'state':1
            }).catch(()=>{
                setProcessingComment(false);
                return;
            });;
        }

        setProcessingComment(false);
    }

    const dislikeComment = async(commentID, userID)=>{
        var path = '';
        if(section === 'forum'){
            path = `ForumComments/${commentID}/Likes`;
        }
        if(section === 'review'){
            path = `ReviewComments/${commentID}/Likes`;
        }
        setProcessingComment(true);
        const dislikeRef = doc(db, path, `${userID}`);
        const dislikeSnap = await getDoc(dislikeRef);

        if(dislikeSnap.exists()){
            if(dislikeSnap.data().state === -1){
                // Delete this doc
                await deleteDoc(doc(db, path, `${userID}`))
                .catch(()=>{
                    setProcessingComment(false);
                    return;
                });
            }else if(dislikeSnap.data().state === 1){
                //update state as 1
                await updateDoc(dislikeRef, {
                    'state': -1
                }).catch(()=>{
                    setProcessingComment(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, path, `${userID}`), {
                'state':-1
            }).catch(()=>{
                setProcessingComment(false);
                return;
            });
        }

        setProcessingComment(false);
    }

    return { processingComment, currentCommentLikes, currentCommentDislikes, 
             userCommentsReaction, likeComment, dislikeComment
           };
}
