// styles
import './externalAuthenticationComponent.css';
// brand icons
import gauthIcon from '../../../assets/Logo/Google-Logo/Web (mobile + desktop)/png@4x/light/web_light_rd_na@4x.png';
// import fauthIcon from '../../../assets/Logo/Facebook Brand Asset Pack/Logo/Primary Logo/Facebook_Logo_Primary.png';
// import twitterIcon from '../../../assets/Logo/X-Logo/logo-white.png';
// Custom hooks
import { useLogin } from '../../../Hooks/useLogin';
// mui icons
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';


export default function ExternalAuthenticationComponent(){

    // custom hook for logging in
    const { logInWithGoogle } = useLogin();

    // Google login
    const handleGoogleLogin = ()=>{
        logInWithGoogle();
    }

    // const handleFacebookAuth = ()=>{
    //     loginWithFacebook();
    // }

    return(
        <div className="auth-providers-container">
            <button className="auth-provider"
                    onClick={()=>handleGoogleLogin()}
            >
                <div className="auth-provider-logo-container">
                    <img src={gauthIcon} alt="google authentication"/>
                </div>
                <p className='auth-provider-txt'>Sign in with Google</p>
                <ArrowForwardRoundedIcon style={{ color:'#f8f8f8' }}/>
            </button>
            {/* <button className='auth-provider'
                    onClick={()=>handleFacebookAuth()}
            >
                <div className="auth-provider-logo-container">
                    <img src={fauthIcon} alt="facebook authentication"/>
                </div>
                <p className='auth-provider-txt'>Sign in with Facebook</p>
                <ArrowForwardRoundedIcon style={{ color:'#f8f8f8' }}/>
            </button> */}
        </div>
    );
}