// react states
import { useState } from 'react';
// database
import { useGetConsoleData } from './useGetConsoleData';

export const useGetMultipleConsoles = ()=>{
    const [multipleConsoleData, setMultipleConsoleData] = useState([]);
    const [loadingMultipleConsoles, setLoadingMultipleConsoles] = useState(false);
    const [multipleConsoleError, setMultipleConsoleError] = useState(null);

    const { getCurrentConsoleData } =  useGetConsoleData();

    const getMultipleConsoles = async( consoleIds )=>{
        setLoadingMultipleConsoles(true);
        try{
            var tempConsoleDataArr = [];
            for(const console_id of consoleIds){
                const consoleData = await getCurrentConsoleData(console_id);
                if(consoleData){
                    tempConsoleDataArr.push(consoleData);
                }
            }
            setMultipleConsoleData(tempConsoleDataArr);
        }catch(err){
            setMultipleConsoleError(err.message);
            setLoadingMultipleConsoles(false);
            return;
        }

        setLoadingMultipleConsoles(false);
    }

    return { multipleConsoleData, loadingMultipleConsoles, multipleConsoleError,
        getMultipleConsoles
     };
}