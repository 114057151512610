// styles
import './search_user_tile.css';
// routing
import { useNavigate } from 'react-router-dom';

export default function SearchUserTile({ searchedUser }){

    const userProfileNav = useNavigate();

    const userProfileNavigation = (usrId)=>{
        userProfileNav(`/user_profile/${usrId}`)
    }

    return(
        <div key={searchedUser.id} className="search-user-tile"
             onClick={()=>userProfileNavigation(searchedUser.id)}
        >
            <div className="search-tile-dp">
            {
                searchedUser.profile_picture !== null &&
                <img src={searchedUser.profile_picture} alt="" />
            }
            {
                searchedUser.profile_picture === null &&
                <p className='search-tile-no-dp'>{searchedUser.Name.substring(0,1).toUpperCase()}</p>
            }
            </div>
            <p className='search-tile-username'>{searchedUser.Name}</p>
        </div>
    );
}