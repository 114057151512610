// styles
import './user_review_write_console.css';
// mui components
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

export default function UserReviewWriteConsoles({ consoleList, selectedConsole, setSelectedConsole }){

    const handleChange = (console_event)=>{
        setSelectedConsole(console_event.target.value);
    }

    return(
        <FormControl sx={{ m:1, minWidth:'50%' }}>
            <InputLabel sx={{ color: '#ffe74c' }}>Select console played on</InputLabel>
            <Select
                labelId="dropdown-label"
                value={selectedConsole}
                onChange={handleChange}
                label="Select console played on"
                sx={{
                    color: '#f8f8f8', // Text color
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#f8f8f8', // Outline color
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#f8f8f8',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#f8f8f8',
                    },
                    '.MuiSvgIcon-root': {
                        color: '#f8f8f8', // Arrow icon color
                    }
                }}
            >
            {
                consoleList && consoleList.length > 0 &&
                consoleList.map((console, index)=>(
                    <MenuItem key={index}
                              value={console}
                    >
                        {console}
                    </MenuItem>
                ))
            }
            </Select>
        </FormControl>
    );

}