// styles
import './newNavbar.css';
// react states
import { useState } from 'react';
// react router dom
import { useNavigate } from 'react-router-dom';
// Database
import { auth } from '../Firebase/firebase';
import { signOut } from 'firebase/auth';
// Material UI components
import { AppBar, Toolbar, IconButton, Drawer, useMediaQuery, Button, Menu, MenuItem, ListItemIcon, ListItemText, Typography, CircularProgress } from '@mui/material';
import { Search, Notifications, AccountCircle, Menu as MenuIcon, Close as CloseIcon, Settings, Logout } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
// Assets
import company_logo from '../assets/Logo/tgb_logo-removebg-preview.png';
// Custom components
import ConsoleMenuDropdown from '../Components/ConsolesPage/ConsoleDropdown/consoleMenu';
import GenreMenuDropdown from '../Components/GenreComponents/GenreDropdown/genreMenuDropdown';
// react redux
import { useSelector } from 'react-redux';
import { selectUser } from '../Features/userSlice';
import { selectUserData, unsetPrivateData } from '../Features/userPrivateDataSlice';
import { logout } from '../Features/userSlice';
import { useDispatch } from 'react-redux';
import { useNotifications } from '../Hooks/Notifications/useNotifications';
import NotificationTile from '../Components/Notifications/NotificationTile/notificationTile';
// for console and genre
import { idvar } from '../Components/ConsolesPage/csid';
import { gidvar } from '../Components/GenreComponents/gsid';

export default function NewNavbar(){

    // To check whether the user is signed in or not.
    const currentUser = useSelector(selectUser);
    const currentUserData = useSelector(selectUserData);
    // console.log(currentUser, currentUserData);

    // Custom hook for notifications.
    const { fetchingNotifications, allNotifications } = useNotifications();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','lg'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };
    const handleNotificationDrawerToggle = () => {
        setNotificationDrawerOpen(!notificationDrawerOpen);
    }

    // Anchor for console dropdown menu
    const [anchorConsole, setAnchorConsole] = useState(null);
    // Anchor for genres dropdown menu
    const [anchorGenres, setAnchorGenres] = useState(null);
    // Anchor for user profile menu
    const [anchorUserAccount, setAnchorUserAccount] = useState(null);
     
    // For opening the console menu
    const handleConsoleMenuOpen = (consoleEvent)=>{
        setAnchorConsole(consoleEvent.currentTarget);
    }
    // For toggling the genre menu
    const handleGenreMenuOpen = (genreEvent)=>{
        setAnchorGenres(genreEvent.currentTarget);
    }
    // For toggling the user account menu
    const handleUserAccountMenuOpen = (accountEvent)=>{
        setAnchorUserAccount(accountEvent.currentTarget);
    }
    const handleUserAccountMenuClose = ()=>{
        setAnchorUserAccount(null);
    }

    // For navigation purposes
    const navbarMenuNav = useNavigate();
    const navigateToArticles = ()=>{
        navbarMenuNav(`/articles`);
    }
    const navigateToForums = ()=>{
        navbarMenuNav(`forums`);
    }
    const navigateToSearch = ()=>{
        navbarMenuNav(`search_page`);
    }
    const navigateToUserAuth = ()=>{
        handleUserAccountMenuClose();
        navbarMenuNav(`/user_auth`);
    }
    const navigateToSettings = ()=>{
        if(Object.keys(currentUserData).length === 0){

        }else{
            handleUserAccountMenuClose();
            navbarMenuNav(`/user_settings`,
                {
                    state:{ 'loggedInUserID':{'id': currentUser.uid ,...currentUserData} } 
                }
            );
        }
    }
    const navigateToHome = ()=>{
        handleUserAccountMenuClose();
        navbarMenuNav(`/`);
    }
    // For navigation purposes

    // FOR THE USER ACCOUNT FUNCTIONALITIES
    // TO MAKE SURE USER GETS THE FUNCTIONALITY ON THE BUTTON CLICK
    const handleUserAccountBtnClick = (user_account_event)=>{
        if(currentUser === null || currentUser === undefined){
            navigateToUserAuth();
            return;
        }else{
            handleUserAccountMenuOpen(user_account_event);
        }
    }
    // TO SIGNOUT THE USER.
    // For dispatching to the redux
    const navbarDispatch = useDispatch();
    const handleUserSignout = async()=>{
        await signOut(auth);
        navbarDispatch(logout());
        // console.log("User after logout:", selectUser(store.getState()));
        navbarDispatch(unsetPrivateData());
        handleUserAccountMenuClose();
        navigateToUserAuth();
    }
    // FOR THE USER ACCOUNT FUNCTIONALITIES

    return(
        <AppBar position="static" className='main-app-bar'>
            <Toolbar className='navbar-toolbar'>
                <div className="logo-container">
                    {
                        isMobile && 
                        <IconButton onClick={handleDrawerToggle}>
                            <MenuIcon sx={{ color:'#f8f8f8' }}/>
                        </IconButton>
                    }
                    <div className="logo-img-container" onClick={()=>navigateToHome()}>
                        <img src={company_logo} alt="The Gamer Bros" />
                    </div>
                    {
                        !isMobile && !isTablet && <p className='company-name-txt' onClick={()=>navigateToHome()}>The Gamer Bros</p>
                    }
                </div>
                {
                    !isMobile ? (
                    <>
                        <div className="options-container">
                            <Button variant='contained' 
                                    endIcon={<ArrowDropDownIcon/>}
                                    onClick={(e)=>handleConsoleMenuOpen(e)}
                                    sx={{ backgroundColor:'transparent', color:'#f8f8f8', "&:hover": {
                                        backgroundColor: "inherit", // Keeps the same background color on hover
                                    },}}
                            >
                                Consoles
                            </Button>
                            <Button variant='contained'
                                    endIcon={<ArrowDropDownIcon/>}
                                    onClick={(e)=>handleGenreMenuOpen(e)}
                                    sx={{ backgroundColor:'transparent', color:'#f8f8f8', "&:hover": {
                                        backgroundColor: "inherit", // Keeps the same background color on hover
                                    },}}
                            >
                                Genres
                            </Button>   
                            <Button variant='contained'
                                    onClick={()=>navigateToArticles()}
                                    sx={{ backgroundColor:'transparent', color:'#f8f8f8', "&:hover": {
                                        backgroundColor: "inherit", // Keeps the same background color on hover
                                    },}}
                            >
                                Articles
                            </Button> 
                            <Button variant='contained'
                                    onClick={()=>navigateToForums()}
                                    sx={{ backgroundColor:'transparent', color:'#f8f8f8', "&:hover": {
                                        backgroundColor: "inherit", // Keeps the same background color on hover
                                    },}}
                            >
                                Forums
                            </Button>                 
                        </div>
                        <div className="profile-container">
                            <div className={isTablet ? "navbar-search-field tablet":"navbar-search-field"}
                                 onClick={()=>navigateToSearch()}
                            >
                                <IconButton>
                                    <Search sx={{ color:'#f8f8f8' }}/>
                                </IconButton>
                                <p className={isTablet ? 'navbar-search-prompt-txt tablet':'navbar-search-prompt-txt'}>Search games</p>
                            </div>
                            <IconButton onClick={()=>handleNotificationDrawerToggle()}>
                                <Notifications sx={{ color:'#f8f8f8' }}/>
                            </IconButton>
                            <IconButton onClick={(e)=>handleUserAccountBtnClick(e)}>
                            {
                                (currentUser === null || currentUser === undefined) &&
                                <AccountCircle sx={{ color:'#f8f8f8' }}/>
                            }
                            {
                                (currentUser !== null && currentUser !== undefined) &&
                                <div className="signed-in-user-pfp-container">
                                {
                                    currentUserData !== null && currentUserData !== undefined &&
                                    currentUserData.profile_picture !== null && currentUserData.profile_picture !== undefined &&
                                    <img src={currentUserData.profile_picture} alt=""/>
                                }
                                {
                                    currentUserData !== null && currentUserData !== undefined &&
                                    (currentUserData.profile_picture === null || currentUserData.profile_picture === undefined) &&
                                    currentUserData.Name !== undefined && <p className='signed-in-user-txt'>{currentUserData.Name.substring(0,1).toUpperCase()}</p>
                                }
                                </div>
                            }
                            </IconButton>
                        </div>
                    </>
                    ) : (
                        <div className="profile-container">
                            <div className="navbar-search-field mobile">
                                <IconButton onClick={()=>navigateToSearch()}>
                                    <Search sx={{ color:'#f8f8f8' }}/>
                                </IconButton>
                                <p className='navbar-search-prompt-txt mobile'>Search games</p>
                            </div>
                            <IconButton onClick={()=>handleNotificationDrawerToggle()}>
                                <Notifications sx={{ color:'#f8f8f8' }}/>
                            </IconButton>
                            <IconButton onClick={(e)=>handleUserAccountBtnClick(e)}>
                            {
                                (currentUser === null || currentUser === undefined) &&
                                <AccountCircle sx={{ color:'#f8f8f8' }}/>
                            }
                            {
                                (currentUser !== null && currentUser !== undefined) &&
                                <div className="signed-in-user-pfp-container">
                                {
                                    currentUserData !== null && currentUserData !== undefined &&
                                    currentUserData.profile_picture !== null && currentUserData.profile_picture !== undefined &&
                                    <img src={currentUserData.profile_picture} alt=""/>
                                }
                                {
                                    currentUserData !== null && currentUserData !== undefined &&
                                    (currentUserData.profile_picture === null || currentUserData.profile_picture === undefined) &&
                                    currentUserData.Name !== undefined && <p className='signed-in-user-txt'>{currentUserData.Name.substring(0,1).toUpperCase()}</p>
                                }
                                </div>
                            }
                            </IconButton>
                        </div>
                    )
                }

                {/* DRAWER FOR MOBILE VIEW */}
                <Drawer anchor='left' open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className="drawer-content">
                        <IconButton onClick={handleDrawerToggle}>
                            <CloseIcon sx={{ color:'#f8f8f8' }}/>
                        </IconButton>
                        <Button variant='contained' 
                                // sx={{ color:'#f8f8f8', width:'100%', backgroundColor:'transparent' }}
                                sx={{ backgroundColor:'transparent', color:'#f8f8f8', width:'100%', "&:hover": {
                                    backgroundColor: "#191917", // Keeps the same background color on hover
                                },}}
                                onClick={()=>{
                                    handleDrawerToggle();
                                    navbarMenuNav(`/consoles/${idvar}`)
                                }}
                        >
                            Consoles
                        </Button>
                        <Button variant='contained' 
                                sx={{ backgroundColor:'transparent', color:'#f8f8f8', width:'100%', "&:hover": {
                                    backgroundColor: "#191917", // Keeps the same background color on hover
                                },}}
                                onClick={()=>{
                                    handleDrawerToggle();
                                    navbarMenuNav(`/genres/${gidvar}`)
                                }}
                        >
                            Genres
                        </Button>
                        <Button variant='contained'
                                sx={{ backgroundColor:'transparent', color:'#f8f8f8', width:'100%', "&:hover": {
                                    backgroundColor: "#191917", // Keeps the same background color on hover
                                },}}
                                onClick={()=>{
                                    handleDrawerToggle();
                                    navigateToArticles();
                                }}
                        >
                            Articles
                        </Button>
                        <Button variant='contained'
                                sx={{ backgroundColor:'transparent', color:'#f8f8f8', width:'100%', "&:hover": {
                                    backgroundColor: "#191917", // Keeps the same background color on hover
                                },}}
                                onClick={()=>{
                                    handleDrawerToggle();
                                    navigateToForums();
                                }}
                        >
                            Forums
                        </Button>
                    </div>
                </Drawer>
                {/* DRAWER FOR MOBILE VIEW */}

                {/* DRAWER FOR NOTIFICATIONS */}
                <Drawer anchor='right' open={notificationDrawerOpen} onClose={handleNotificationDrawerToggle}>
                    <div className={!isMobile ? "notification-drawer-content" : "notification-drawer-content mobile"}>
                        <div className="notification-drawer-heading">
                            <IconButton onClick={()=>handleNotificationDrawerToggle()}>
                                <CloseIcon sx={{ color:'#f8f8f8' }}/>
                            </IconButton>
                            <Typography sx={{ color:'#f8f8f8', fontFamily:'Paciencia, sans-serif' }}>Notifications</Typography>
                        </div>
                        {
                            fetchingNotifications && 
                            <div className="fetching-notification-prompt">
                                <CircularProgress sx={{ color:'#f8f8f8' }}/>
                                <p className='loading-notification-prompt-txt'>Loading notifications...</p>
                            </div>
                        }
                        {
                            fetchingNotifications === false &&
                            allNotifications &&
                            allNotifications.length === 0 &&
                            <p className='loading-notification-prompt-txt'>No notifications yet. Start interacting on the site to fill this place up.</p>
                        }
                        {
                            fetchingNotifications === false &&
                            allNotifications &&
                            allNotifications.length > 0 &&
                            allNotifications.map((notification)=>(
                                <NotificationTile key={notification.id} notificationObj={notification}/>
                            ))
                        }
                    </div>
                </Drawer>
                {/* DRAWER FOR NOTIFICATIONS */}

                {/* THE DROPDOWN MENU FOR CONSOLES */}
                <ConsoleMenuDropdown consoleMenuAnchor={anchorConsole} setConsoleMenuAnchor={setAnchorConsole}/>
                {/* THE DROPDOWN MENU FOR CONSOLES */}

                {/* THE DROPDOWN MENU FOR GENRES */}
                <GenreMenuDropdown genreMenuAnchor={anchorGenres} setGenreMenuAnchor={setAnchorGenres}/>
                {/* THE DROPDOWN MENU FOR GENRES */}

                {/* USER PROFILE ACCOUNT MENU HERE */}
                <Menu
                    anchorEl={anchorUserAccount}
                    open={Boolean(anchorUserAccount)}
                    onClose={handleUserAccountMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        "& .MuiPaper-root": {
                          backgroundColor: "#191917",
                          color:'#f8f8f8',
                          height:'auto',
                          width:!isMobile ? '15vw' :'45vw',
                          display:'flex',
                          flexDirection:'column',
                          gap:'25px',
                          justifyContent:'flex-start',
                          alignItems:'flex-start'
                        }
                    }}
                >
                    {/* Item to take user to their profile */}
                    <MenuItem sx={{ m:1 }} onClick={()=>navigateToUserAuth()}>
                        <ListItemIcon>
                        {
                            (currentUser === null || currentUser === undefined) &&
                            <AccountCircle sx={{ color:'#f8f8f8' }}/>
                        }
                        {
                            (currentUser !== null && currentUser !== undefined) &&
                            <div className="signed-in-user-pfp-container">
                            {
                                currentUserData !== null && currentUserData !== undefined &&
                                currentUserData.profile_picture !== null && currentUserData.profile_picture !== undefined &&
                                <img src={currentUserData.profile_picture} alt=""/>
                            }
                            {
                                currentUserData !== null && currentUserData !== undefined &&
                                (currentUserData.profile_picture === null || currentUserData.profile_picture === undefined) &&
                                currentUserData !== null && currentUserData !== undefined &&
                                (currentUserData.profile_picture === null || currentUserData.profile_picture === undefined) &&
                                currentUserData.Name !== undefined && <p className='signed-in-user-txt'>{currentUserData.Name.substring(0,1).toUpperCase()}</p>
                            }
                            </div>
                        }
                        </ListItemIcon>
                        <ListItemText primary='Profile'/>
                    </MenuItem>
                    <MenuItem sx={{ color:'#f8f8f8' , m:1 }}
                              onClick={()=>navigateToSettings()}
                    >
                        <ListItemIcon>
                            <Settings sx={{ color:'#f8f8f8' }}/>
                        </ListItemIcon>
                        <ListItemText primary='Settings'/>
                    </MenuItem>
                    <MenuItem sx={{ color:'#f8f8f8', m:1}} onClick={()=>handleUserSignout()}>
                        <ListItemIcon>
                            <Logout sx={{ color:'#f8f8f8' }}/>
                        </ListItemIcon>
                        <ListItemText primary='Logout'/>
                    </MenuItem>
                </Menu>
                {/* USER PROFILE ACCOUNT MENU HERE */}
            </Toolbar>
        </AppBar>
    )
}