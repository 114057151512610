// react states
import { useEffect } from 'react';
import { ad_source_1 } from './ad_sources';

export default function AdComponent({ container_id }){

    useEffect(()=>{
        // Create the script
        const script = document.createElement('script');
        // Make this script asynchronus
        script.async = true;
        // Set attributes as provided by the adsterra codes.
        script.setAttribute("data-cfasync","false");
        // Provide the source as provided by the adsterra codes.
        script.src = `${ad_source_1}`;

        // Append the given script to the wanted container. In our case, the container id.
        const adContainer = document.getElementById(container_id);
        // If the container component has mounted(very important), then only start the script.
        if(adContainer){
            adContainer.appendChild(script);
        }
        
        // And now handle the case when the container component unmounts.
        return ()=>{
            if(adContainer && adContainer.contains(script)){
                adContainer.removeChild(script);
            }
        };

    },[container_id]);

    
    return(
        <div id={container_id}></div>
    );
}