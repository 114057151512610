// styles
import './consoleDetailsComponent.css';
// react states
import { useEffect, useRef, useState } from 'react';
// react router dom
import { useNavigate } from 'react-router-dom';
// custom hooks
import { useGetConsoleGames } from '../../Hooks/Consoles/useGetConsoleGames';
import { useConsoleOperations } from '../../Hooks/Consoles/useConsoleOperations';
// react redux
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Features/userPrivateDataSlice';
import { selectUser } from '../../Features/userSlice';
// mui icons
import { CircularProgress } from '@mui/material';

export default function ConsoleDetailComponent({ consoleDetails }){

    // console.log(consoleDetails);

    // To obtain the games for the current console
    const { consoleGames, loadingConsoleGames } = useGetConsoleGames(consoleDetails.acronym);

    // console.log(consoleGames);
    // console.log(errorLoadingConsoleGames);

    // To get the signed in user's console collection.
    const signedInUserData = useSelector(selectUserData);
    const signedInUserID = useSelector(selectUser);

    // console.log(consoleDetails);
    const [collapse, setCollapse] = useState(false);
    const [collapseHeight, setCollapseHeight] = useState('0px');
    const [showCollapseBtn, setShowCollapseBtn] = useState(false);

    const defaultHeight = 250;

    const toggleCollapse = ()=>{
        if(collapse)
            setCollapse(false);
        else
            setCollapse(true);
    }

    const collapseDivRef = useRef(null);
    useEffect(()=>{
        if(collapseDivRef.current){
            setCollapseHeight(collapse ? `${collapseDivRef.current.scrollHeight}px` : `${defaultHeight}px`);
            setShowCollapseBtn(collapseDivRef.current.scrollHeight > defaultHeight);
        }
    },[collapse])

    const consoleNav = useNavigate();
    const navigateToGamePage = (game_id)=>{
        consoleNav(`/game_page/${game_id}`);
    }

    // TO ADD OR REMOVE CONSOLE FROM USER COLLECTION
    const { addingConsole, addConsoleToUserCollection,
        removeConsoleFromUserCollection
     } = useConsoleOperations();

    const handleAddToUser = ()=>{
        if(signedInUserID === null || signedInUserID === undefined){
            consoleNav('/user_auth');
            return;
        }
        if(signedInUserID !== null && signedInUserID !== undefined){
            addConsoleToUserCollection(consoleDetails, signedInUserID.uid);
        }
    }

    const handleRemoveFromUser = ()=>{
        if(signedInUserID === null || signedInUserID === undefined){
            consoleNav('/user_auth');
            return;
        }
        if(signedInUserID !== null && signedInUserID !== undefined){
            removeConsoleFromUserCollection(consoleDetails, signedInUserID.uid);
        }
    }

    return(
        <div className="console-full-data-container">
            <div className="console-heading-and-cta-area">
                <p className='console-heading'>{consoleDetails.name}</p>
                
                <div className="console-cta-container">
                {
                    addingConsole === false &&
                    (signedInUserData === null || signedInUserData === undefined ||
                        JSON.stringify(signedInUserData)==='{}') &&
                        <button className='console-add-cta'
                            onClick={()=>handleAddToUser()}
                        >
                            Add to profile
                        </button>
                }
                {
                    addingConsole === false &&
                    signedInUserData &&
                    JSON.stringify(signedInUserData)!=='{}' &&
                    !signedInUserData.all_consoles.includes(consoleDetails.id)
                    &&
                    <button className='console-add-cta'
                            onClick={()=>handleAddToUser()}
                    >
                        Add to profile
                    </button>
                }
                {
                    addingConsole === false &&
                    signedInUserData &&
                    JSON.stringify(signedInUserData)!=='{}' &&
                    signedInUserData.all_consoles.includes(consoleDetails.id)
                    &&
                    <button className='console-add-cta'
                            onClick={()=>handleRemoveFromUser()}
                    >
                        Remove from profile
                    </button>
                }
                {
                    addingConsole === true &&
                    <CircularProgress size={16} style={{ color:'#FFE74C' }}/>
                }
                </div>
            </div>
            <p className='console-subheading'>{consoleDetails.company}</p>
            <div className="console-space-div"></div>
            <p className='console-subheading-1'>About</p>
            <div className="console-about-div" 
                 ref={collapseDivRef}
                 style={{ 'maxHeight':collapseHeight }}
            >
                <div className='console-normal-text'>
                    <span>
                    <div className="console-about-picture">
                        <img src={consoleDetails.pictures[0]} alt={consoleDetails.name} />
                    </div>
                    </span>    
                    {consoleDetails.about}
                </div>
            </div>
            {
                showCollapseBtn &&
                <div className="collapse-div-action">
                    <button className='console-about-read-more-cta'
                            onClick={()=>toggleCollapse()}
                    >
                    {
                        collapse === false ? 'Read more':'Show less'
                    }
                    </button>
                </div>
            }
            <div className="console-space-div"></div>
            <p className='console-subheading-1'>{`Popular Games on ${consoleDetails.name}`}</p>
            <div className="console-space-div"></div>
            <div className="console-games-list-container">
            {
                loadingConsoleGames === false &&
                consoleGames && consoleGames.length > 0
                &&
                consoleGames.map((consoleGame)=>(
                    <div key={consoleGame.id}
                         className='console-game-tile'
                         onClick={()=>navigateToGamePage(consoleGame.id)}
                    >
                        <img src={consoleGame.thumbnail_image} alt={consoleGame.name} />
                    </div>
                ))
            }
            </div>
        </div>
    )
}