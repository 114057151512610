// styles
import { useParams } from 'react-router-dom';
import './write_review_page.css';
// react states
// import { useState, useEffect } from 'react';
// react-redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../Features/userSlice';
import { selectUserData } from '../../Features/userPrivateDataSlice';
// custom hooks
import { useGameDetailsFetch } from '../../Hooks/useGameDetailsFetch';
// custom components
import UserReviewWriteContainer from '../../Components/WriteReviewPageComponents/UserReviewWriteComponent/user_review_write_container';

export default function WriteReviewPage(){

    // We'll get the game info from here.
    const { game_id } = useParams();
    // To get the game which is reviewed
    const { fetchingGame, gameDetails } = useGameDetailsFetch(game_id !== null ? game_id : null);

    // Get the user info
    const currentUserID = useSelector(selectUser);
    const currentUserData = useSelector(selectUserData);

    
    return(
        <div className='write-review-page-container'>
            <div className="review-page-ad-slot"></div>
            <div className="review-write-up-form-container">
            {
                fetchingGame === false && gameDetails && gameDetails!==null &&
                <>
                    <div className="review-game-img-container">
                        <img src={gameDetails.cover_image_url} alt={gameDetails.name} />
                    </div>
                    <div className="review-game-name">
                        <p>{gameDetails.name}</p>
                    </div>
                </>
            }
            <UserReviewWriteContainer
                userIDObject={currentUserID!==null?currentUserID:null}
                userDataObject={currentUserData!==null?currentUserData:null}
                gameDetails={gameDetails!==null?gameDetails:null}
            />
            </div>
            <div className="review-page-ad-slot"></div>
        </div>
    );
}