// styles
import './loginForm.css';
// react states
import { useState, useEffect } from 'react';
// react-router-dom
import { useLocation } from 'react-router-dom';
// firebase
import { auth } from '../../../../Firebase/firebase';
import { applyActionCode } from 'firebase/auth';
// custom hook(s)
import { useLogin } from '../../../../Hooks/useLogin';
// custom components
import ExternalAuthenticationComponent from '../../ExternalAuthenticationComponents/externalAuthenticationComponent';
// mui icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, CircularProgress, TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { login } from '../../../../Features/userSlice';
// import CheckIcon from '@mui/icons-material/Check';
// import { CircularProgress } from '@mui/material';

export default function LoginForm(){

    // Hook to use for logging in
    const { error, setError, sendVerificationForMail, 
        loginStatus, loggingIn, logUserIn } = useLogin();

    // This is only for verification time, not for normal login.
    const [message, setMessage] = useState(null);
    
    // Routing
    // const authNavigate = useNavigate();

    // VERY IMPORTANT: TO CHECK WHETHER IT'S A NORMAL VISIT OR EMAIL VERIFICATION VISIT.
    const location = useLocation();
    // const navigate = useNavigate();
    const loginUserDispatch = useDispatch();

    useEffect(()=>{
        // Getting all the parameters in the url
        const queryParams = new URLSearchParams(location.search);
        const oobCode = queryParams.get("oobCode");
        const mode = queryParams.get("mode");

        // Now checking whether it's a normal login visit or a verification login visit.
        if(mode === "verifyEmail" && oobCode){
            // If these 2 params are not null or undefined or '', then it means that it's a verification visit.
            // Start verifying the link.
            applyActionCode(auth, oobCode).then(() => {
                setMessage("Email verified successfully! You can now log in.");
                loginUserDispatch(login({
                    'uid': auth.currentUser.uid,
                    'email':auth.currentUser.email,
                    'isEmailVerified': auth.currentUser.emailVerified
                }))
            })
            .catch((error) => {
                // console.error("Error verifying email:", error.message);
                setMessage("Error verifying email. The link may have expired.");
            });
        }   
    },[location])

    // To toggle the visibility of the password
    const [visible, setVisible] = useState(false);
    const toggleVisibility = ()=>{
        if(visible === false){
            setVisible(true);
        }else{
            setVisible(false);
        }
    }

    // To handle the submit events
    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');
    
    
    const handleLoginBtnClick = async()=>{
        if(mail.length === 0 || pass.length === 0){
            setError('Please enter the required fields.');
            return;
        }else{
            await logUserIn(mail, pass);
        }
    }

    const handleEmailVerification = async()=>{
        if(auth.currentUser !== null){
            await sendVerificationForMail(auth.currentUser);
        }
    }


    return(
        <>
        <div className="external-auth-provider-container">
            <p className='external-auth-provider-heading'>Sign in with</p>
            <ExternalAuthenticationComponent/>
            <div className="auth-form-divider">
                OR
            </div>
            <div className="email-link-auth-area">
                <TextField variant='outlined' 
                           sx={{ 'width':'100%', '& .MuiOutlinedInput-root': {
                                    color: '#f8f8f8', // Text color
                                    '& fieldset': {
                                        borderColor: '#9e9e9e', // Outline color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#f8f8f8', // Outline color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#f8f8f8', // Outline color when focused
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#9e9e9e', // Label color
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#f8f8f8', // Label color when focused
                                },
                               }}
                           label='Enter your email'
                        //    helperText={sendingEmailError}
                           value={mail}
                           onChange={(e)=>setMail(e.target.value)}
                />
                <TextField variant='outlined' 
                           sx={{ 'width':'100%', '& .MuiOutlinedInput-root': {
                                    color: '#f8f8f8', // Text color
                                    '& fieldset': {
                                        borderColor: '#9e9e9e', // Outline color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#f8f8f8', // Outline color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#f8f8f8', // Outline color when focused
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#9e9e9e', // Label color
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#f8f8f8', // Label color when focused
                                },
                               }}
                           label='Enter your Password'
                           InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={()=>toggleVisibility()}
                                        edge="end"
                                        sx={{ color:'#f8f8f8' }}
                                    >
                                    {visible === false ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                ),
                            }}
                           value={pass}
                           type={visible === true ? 'text' : 'password'}
                           onChange={(e)=>setPass(e.target.value)}
                />
                {
                    loggingIn === true && <CircularProgress size={24} sx={{ color:'#ff374c' }}/>
                }
                {
                    (loggingIn === false || loggingIn === null) && loginStatus !== 'success' &&
                    <Button variant='contained'
                        sx={{ width:'100%', height:'45px', backgroundColor:'#ffe74c', color:'#080808', 
                            borderRadius:'5px 5px 5px 5px',
                            '&:hover': {
                                backgroundColor: '#fff74c', // Hover color
                            },
                        }}
                        endIcon={<ArrowForwardIcon/>}
                        onClick={()=>handleLoginBtnClick()}
                    >
                        Log in
                    </Button>
                }
                {
                    loggingIn === false && 
                    <Button variant='contained'
                        sx={{ width:'100%', height:'45px', backgroundColor:'#ffe74c', color:'#080808', 
                            borderRadius:'5px 5px 5px 5px',
                            '&:hover': {
                                backgroundColor: '#fff74c', // Hover color
                            },
                        }}
                        endIcon={<ArrowForwardIcon/>}
                        onClick={()=>handleEmailVerification()}
                    >
                        Verify email
                    </Button>
                }
                {
                    message !== null && <p className='sending-email-feedback'>{message}</p>
                }
                {
                    error !== null &&
                    <p className='sending-email-feedback'>{error}</p>
                }
            </div>
            <p className='sending-email-feedback'>Don't have an account? <a style={{ textDecoration:'None', color:'#f8f8f8', fontStyle:'italic' }} href='https://thegamerbroz.com/signup'>Create account</a></p>
        </div>
        </>
    )
}