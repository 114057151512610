// styles
import './search_game_tile_box.css';

// SEO
import { Helmet } from 'react-helmet';

export default function SearchGameTile({ searchedGame }){
    return(
        <div className="search-game-tile-box">
            <Helmet>
                <title>{`${searchedGame.name}`}</title>
                <meta name='description' content={`${searchedGame.name}`}/>
                <meta name='keywords' content={`search, ${searchedGame.name}, ${searchedGame.year_of_release}`}/>
                <meta name='mainImage' content={`${searchedGame.mainImg}`} />
            </Helmet>
            {
                searchedGame.mainImg!==null && <img loading='lazy' src={searchedGame.mainImg} alt={searchedGame.name}/>
            }
            <p>{searchedGame.name}</p>
        </div>
    );
}