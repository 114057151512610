// styles
import './aboutus.css';
// getting the premade asset
import sabihImg from '../../assets/Sabih.png';
import sarimImg from '../../assets/Sarim.png';
import architImg from '../../assets/Archit.png';

export default function AboutUs(){

    const sabih = "Sabih is the creative mind behind our venture, and he's the one who brought our website to life. Currently pursuing a masters degree in Computer Systems Engineering at the University of Glasgow, Sabih's passion for technology and his knack for coding make him the driving force behind the technical aspects of our project. With his knowledge in web development, he has transformed our ideas into a user-friendly and visually appealing platform. In addition to his technical skills, Sabih possesses a keen eye for design, ensuring that our website not only functions flawlessly but also looks aesthetically pleasing. His dedication and his tireless work ethic are the cornerstones of our success.";
    const sarim = "Sarim is the brains behind the business side of our venture. As the one who came up with the initial idea, he's been the guiding force in shaping our vision. Currently pursuing a degree in Business Management at Heriot-Watt University, Sarim's education and passion for entrepreneurship have been instrumental in the strategic planning and growth of our venture. Being an avid gamer since childhood, Sarim has always been fascinated with video games as a whole and wanted to make something for gamers all around the world. He came up with the idea of creating a place where the voices of the people could be heard and they could just discuss, review and rate games as they wanted to. That's why he along with the help of Sabih and Archit decided to create The Gamer Bros. Sarim's ability to see opportunities where others may not, coupled with his excellent leadership skills, has been pivotal in securing partnerships and funding for our project. He envisions a future where our venture not only thrives but also contributes positively to the community.";
    const archit = "Archit is the backbone of our venture, responsible for the smooth working of our database. From the beginning, he's been an indispensable part of the team. His dedication to maintaining the integrity of our information is unwavering. Archit ensures that we make data-driven decisions and continually improve our services. His ability to handle the technical complexities of our database allows us to focus on delivering the best possible experience to our users.";
    const about_gamer_bros = "Welcome to The Gamer Bros, a haven created by three passionate gaming enthusiasts for all those who share our love for gaming or are eager to delve into the gaming universe! At The Gamer Bros, we believe in the power of community and the joy that gaming brings. Our platform invites you to explore, connect, and express your thoughts on the games you adore. Dive into our review section to share your insights on the latest releases or classics, participate in engaging discussions within our forum, and craft a unique gaming identity with a personalized profile. Showcase your gaming prowess by adding titles to your collection, curate a wishlist for future adventures, and proudly display the consoles that fuel your gaming passion. Join us at The Gamer Bros, where every gamer's voice is heard, and the gaming community comes together in celebration of our shared love for the digital realm.";

    return(
        <div className="about-us-container">
            <div className="about-us-heading">About us</div>
            <div className="about-gamer-bros">
                {about_gamer_bros}
            </div>
            <p className='about-us-heading'>The Team</p>
            <div className="about-us-content-container">
                <div className="person-tab">
                    <div className="person-img-container">
                        <img src={sabihImg} alt="" />
                    </div>
                    <p className='about-us-subheading'>Sabih</p>
                    <p className='about-us-text'>{sabih}</p>
                </div>
                <div className="person-tab">
                    <div className="person-img-container">
                        <img src={sarimImg} alt="" />
                    </div>
                    <p className='about-us-subheading'>Sarim</p>
                    <p className='about-us-text'>{sarim}</p>
                </div>
                <div className="person-tab">
                    <div className="person-img-container">
                        <img src={architImg} alt="" />
                    </div>
                    <p className='about-us-subheading'>Archit</p>
                    <p className='about-us-text'>{archit}</p>
                </div>
            </div>
        </div>
    )
}