// styles
import SearchUserTile from './SearchUserComponents/search_user_tile';
import './search_user_box.css';
// mui components
import { CircularProgress } from '@mui/material';


export default function SearchUserBox({ loadingUsers, searchUserList }){

    return(
        <div className="user-search-result-box">
        {
            loadingUsers === true && 
            <div className="loading-users-prompt">
                <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                <p style={{ fontSize:'0.9rem', fontWeight:'600',
                            color:'#FFE74C', marginTop:'7px'
                            }}
                >
                    Loading users...
                </p>
            </div>
        }
        {
            searchUserList && searchUserList.length > 0 &&
            searchUserList.map((userSearched)=>(
                <SearchUserTile key={userSearched.id} searchedUser={userSearched}/>
            ))
        }   
        </div>
    );
}