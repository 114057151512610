// styles
import './password_forget.css';
// react states
import { useEffect, useState } from 'react';
// custom hooks
import { usePasswordForgetMailSend } from '../../../Hooks/usePasswordForgetMailSend';
// mui components
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function PasswordForget(){
    
    const [userMail, setUserMail] = useState('');

    // hook to send the password reset mail
    const { error, status, sending, sendUserPasswordResetMail } = usePasswordForgetMailSend();

    // to give user feedback regarding the status of mail sent or not.
    const [openStatus, setOpenStatus] = useState(false);
    const handleOpenStatus = ()=>{
        setOpenStatus(true);
    }
    const handleCloseStatus = ()=>{
        setOpenStatus(false);
    }

    useEffect(()=>{
        handleOpenStatus();
    },[status, sending])

    const sendMail = ()=>{
        if(userMail.length === 0){
            return;
        }else{
            sendUserPasswordResetMail(userMail.trim());
        }
    }

    return(
        <div className='password-forget-container'>
            {
                status !== null && 
                <Snackbar open={openStatus} autoHideDuration={4000} onClose={handleCloseStatus}>
                    <Alert onClose={handleCloseStatus} severity={`${status}`} sx={{ width: '100%' }}>
                    {
                        status === 'success' && <p className='status-text'>Password reset mail sent.</p>
                    }
                    {
                        status === 'error' && <p className='status-text'>Password reset mail not sent. Try again in a few minutes</p>
                    }
                    </Alert>
                </Snackbar>
            }
            <div className="password-reset-prompt-container">
                <p className='message-prompt-1'>Don't worry. We got you</p>
                <p className='message-prompt-2'>Give us your email so that we can send you the password reset link</p>
                <input 
                    className='password-forget-input'
                    type='text'
                    required
                    placeholder='Enter your email'
                    onChange={(e)=>setUserMail(e.target.value)}
                    value={userMail}
                />
                {
                    sending !== true && 
                    <button 
                        className='forgot-password-cta'
                        onClick={()=>sendMail()}
                    >
                        Send password reset email
                    </button>
                }
                {
                    sending === true && <CircularProgress size={24} style={{'color':'#FFE74C'}} />
                }
                {
                    error !== null && <p>{error}</p>
                }
            </div>
        </div>
    );
}