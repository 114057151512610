/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';

// firebase
import { db } from '../../Firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const useGetConsoleData = (consoleID)=>{
    const [consoleData, setConsoleData] = useState(null);
    const [loadingConsoleData, setLoadingConsoleData] = useState(false);
    const [consoleError, setConsoleError] = useState(null);

    var currentConsoleKey = consoleID;

    const getCurrentConsoleData = async(console_id)=>{
        currentConsoleKey = console_id;

        const currentConsoleData = sessionStorage.getItem(currentConsoleKey);
        if(currentConsoleData){
            setConsoleData(JSON.parse(currentConsoleData));
            return JSON.parse(currentConsoleData);
        }else{
            setLoadingConsoleData(true);
            setConsoleError(null);

            const consoleRef = doc(db, 'Consoles', `${console_id}`);
            const consoleSnap = await getDoc(consoleRef)
            .catch((err)=>{
                setConsoleError(err.message);
                setLoadingConsoleData(false);
                return;
            });

            if(consoleSnap.exists()){
                const consoleObj = {
                    'id':consoleSnap.id,
                    ...consoleSnap.data()
                }
                setConsoleData(consoleObj);
                sessionStorage.setItem(currentConsoleKey, JSON.stringify(consoleObj));
                setLoadingConsoleData(false);
                setConsoleError(null);
                return consoleObj;
            }else{
                setConsoleError('No console found');
                setLoadingConsoleData(false);
                return;
            }
        }
    }

    useEffect(()=>{
        if(consoleID !== null && consoleID !== undefined){
            getCurrentConsoleData(consoleID);
        }
    },[consoleID]);

    return { consoleData, loadingConsoleData, consoleError, getCurrentConsoleData };
}