// react states
import { useState } from 'react';
// firebase
import { db, auth, googleAuthProvider, facebookAuthProvider } from '../Firebase/firebase';
import { FacebookAuthProvider, fetchSignInMethodsForEmail, signInWithEmailAndPassword, signInWithPopup,
            linkWithCredential, sendEmailVerification
 } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
// react-redux
import { useDispatch } from 'react-redux';
// react-router-dom
// import { useNavigate } from 'react-router-dom';
// react slices
import { login, logout } from '../Features/userSlice';
// custom hook for onboarding the user straightaway in case of external provider login.
import { useSignup } from './useSignup';

export const useLogin = ()=>{

    const [error, setError] = useState(null);
    const [loggingIn, setLoggingIn] = useState(null);
    const [loginStatus, setLoginStatus] = useState(null);
    
    const userLoginDispatch = useDispatch();

    // custom hook to signup the user first.
    const { createAccountWithGoogleAuth, createAccountWithFacebookAuth } = useSignup();

    const sendVerificationForMail = async( current_user )=>{
        if(current_user !== null){
            setLoggingIn(true);
            await sendEmailVerification(current_user);
            setLoggingIn(false);
            return;
        }else{
            return;
        }
    }

    const logUserIn = async(email, password)=>{
        setLoggingIn(true);
        setError(null);
        // console.log('Log in successfully');
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredentials) => {
            // We are supposed to check here, whether user is email verified or not.
            if(userCredentials.user.emailVerified === true){
                // Okay they have verified their mail, we can sign them in.
                userLoginDispatch(login({
                    'uid':userCredentials.user.uid,
                    'email':email,
                    'isEmailVerified':userCredentials.user.emailVerified,
                }))
                setLoggingIn(false);
                setLoginStatus('success');
                return;
            }else{
                // The email is not verified, ask them to verify it.
                userLoginDispatch(logout());
                setLoggingIn(false);
                return;
            }

        }).catch((err)=>{
            setError(err.message);
            setLoggingIn(false);
            setLoginStatus('fail');
            return;
        })
    }

    const logInWithGoogle = ()=>{
        setLoggingIn(true);
        signInWithPopup(auth, googleAuthProvider).then(async(userGoogleCredentials)=>{
            // Check if the user is in the database or not.
            const authUserRef = doc(db, "Users", `${userGoogleCredentials.user.uid}`);
            const authUserSnap = await getDoc(authUserRef);
            if(authUserSnap.exists()){
                // console.log("User exists, sign them in");
                userLoginDispatch(login({
                    // 'uid': userCredentials.user.uid
                    'uid':userGoogleCredentials.user.uid,
                    'email':userGoogleCredentials.user.email,
                    'isEmailVerified':userGoogleCredentials.user.emailVerified,
                }))
                setLoggingIn(false);
            }else{
                // console.log("User doesn't exist, sign them up");
                createAccountWithGoogleAuth(userGoogleCredentials);
            }
        })
        .catch((googleAuthError)=>{
            setError(googleAuthError.message);
            setLoggingIn(false);
        })
    }

    // Facebook login
    const loginWithFacebook = ()=>{
        setLoggingIn(true);
        signInWithPopup(auth, facebookAuthProvider)
        .then(async(result) => {
            const currentUser = result.user;
            // Check if the user exists or not.
            const authUserRef = doc(db, "Users", `${currentUser.uid}`);
            const authUserSnap = await getDoc(authUserRef);
            if(authUserSnap.exists()){
                // console.log("User exists, sign them in");
                userLoginDispatch(login({
                    // 'uid': userCredentials.user.uid
                    'uid':currentUser.uid,
                    'email':currentUser.email,
                    'isEmailVerified':currentUser.emailVerified,
                }))
                setLoggingIn(false);
            }else{
                // User doesn't exist, create a new user.
                createAccountWithFacebookAuth(result);
            }
        }).catch((fbAuthError)=>{
            // If the user has already registered via a different provider.
            if(fbAuthError.code === 'auth/account-exists-with-different-credential'){
                // Fetch the credentials
                const pendingCred = FacebookAuthProvider.credentialFromError(fbAuthError);
                const email = fbAuthError.customData.email;

                // Now fetch the sign up provider for the fetched mail
                fetchSignInMethodsForEmail(auth, email).then((methods)=>{
                    if (methods.includes('google.com')) {
                        // Ask user to sign in with Google
                        signInWithPopup(auth, googleAuthProvider).then((result) => {
                                // Link the Facebook credential to the Google account
                                linkWithCredential(result.user, pendingCred).then(() => {
                                console.log("Facebook account linked to Google account");
                            });
                        });
                    }else if(methods.length === 0){
                        signInWithPopup(auth, googleAuthProvider).then((result) => {
                                // Link the Facebook credential to the Google account
                                linkWithCredential(result.user, pendingCred).then(() => {
                                console.log("Facebook account linked to Google account");
                            });
                        });
                    }
                });
            }else{
                setError(fbAuthError.message);
                setLoggingIn(false);
                return;
            }
        })
    }

    return { error, loggingIn, loginStatus, setError, sendVerificationForMail,
         logUserIn, logInWithGoogle, loginWithFacebook };

}