/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './commentDeletionConfirmation.css';
// custom hooks
import { useCommentsDelete } from '../../Hooks/Forums/ForumComments/useCommentsDelete';
// mui components
import { CircularProgress, Dialog, DialogContent, DialogActions } from '@mui/material';
import { useEffect } from 'react';

export default function CommentDeletionConfirmation({ section, comment_id, 
                    openCommentDeletionBox, setOpenCommentDeletionBox }){
    
    const { deleting, deleteStatus, deleteUserComments } = useCommentsDelete(section);
    
    const startCommentDeletion = ()=>{
        deleteUserComments(comment_id);
    }

    const handleCloseDeletionBox = ()=>{
        setOpenCommentDeletionBox(false);
    }

    useEffect(()=>{
        if(deleteStatus !== null){
            handleCloseDeletionBox();
        }
    },[deleteStatus])

    return(
        <Dialog
            open={openCommentDeletionBox}
            scroll={'paper'}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogContent className='user-comment-deletion-container'>
            {
                deleting && 
                <>
                    <CircularProgress size={24} style={{ 'color':'#FFE74C' }}/>
                    { deleteStatus === 'success' && <p>Comment deleted successfully</p> }
                </>
            }
            {
                !deleting && 'Are you sure you want to delete this comment?'
            }
            </DialogContent>
            {
                !deleting && 
                <DialogActions className='user-comment-deletion-actions'>
                    <button className='user-comment-deletion-button'
                            onClick={()=>startCommentDeletion()}
                    >
                        Yes, I am sure
                    </button>
                    <button className='user-comment-deletion-cancel-button'
                            onClick={()=>handleCloseDeletionBox()}
                    >
                        No
                    </button>
                </DialogActions>
            }
            
        </Dialog>
    )
}