/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './allConsolesComponent.css';
// react states
import { useEffect } from 'react';
// react-router-dom
import { useNavigate } from 'react-router-dom';
// custom hooks
import { useGetUserConsoles } from '../../Hooks/Consoles/useGetUserConsoles';

// custom loading components
import ProfileComponentLoader from '../../ProgressIndicators/LoaderScreens/userProfile/profileComponentLoader';
import { useGetUserProfile } from '../../Hooks/useGetUserProfile';

// SEO
import { Helmet } from 'react-helmet';


export default function AllConsoles({ userProfileID, loggedInUserID  }){

    // To show only the consoles that are in user collection
    const { userConsoles, loadingUserConsoles } = useGetUserConsoles(userProfileID); 

    const { getProfileDetails, userData } = useGetUserProfile();
    useEffect(()=>{
        getProfileDetails(userProfileID,"");
    },[userProfileID])


    // TO NAVIGATE TO THE NEW CONSOLE PAGE
    const consolePageNav = useNavigate();
    const handleNavigateToConsolePage = (console_id)=>{
        consolePageNav(`/consoles/${console_id}`)
    }

    return(
        <>
            {
                userData !== null &&
                <Helmet>
                    <title>{`${userData.Name} - Consoles Collection`}</title>
                    <meta name='description' content={`${userData.Name} all consoles collection`}/>
                    <meta name='keywords' content={`${userData.Name}, consoles, games, collection, games collection, consoles collection, all games, all consoles, add games, add consoles ,like game collection`}/>
                </Helmet>
            }
            <div className='all-consoles-container' id='user_consoles'>
                {
                    (loggedInUserID === userProfileID) && 
                    <div className="add-consoles-area">
                        <button className="adding-prompt"
                            onClick={()=>{
                                // handleChoseConsoleBoxOpen()
                                handleNavigateToConsolePage("0mMjWtzIBjkq7yWSiQLD");
                            }}
                        >
                            Add consoles
                        </button>
                    </div>
                }
                {
                    loadingUserConsoles === false && (userConsoles !== null || ( userConsoles && userConsoles.length > 0)) &&
                    <div className="selected-consoles-area">
                    {
                        (userConsoles && userConsoles.length > 0)
                        && userConsoles.map((console)=>(
                            <div key={console.id} className="console-detail-container"
                                 onClick={()=>{
                                    // setSelectedConsole(console);
                                    // handleChoseConsoleBoxOpen();
                                    handleNavigateToConsolePage(console.id);
                                 }}
                            >
                                <img src={console.pictures[0]} alt='thumbnail'/>
                                <p>{console.name}</p>
                            </div>
                        ))
                    }
                    </div>
                }
                {
                    loadingUserConsoles === false && (userConsoles === null || ( userConsoles && userConsoles.length === 0)) &&
                    <div className="no-consoles-area">
                    {
                        userProfileID === loggedInUserID
                        && <p>You haven't added any consoles yet. Start doing it now.</p>
                    }
                    {
                        userProfileID !== loggedInUserID
                        && userData && <p>{`${userData.Name} hasn't added any consoles yet.`}</p>
                    }
                    </div>
                }
                {
                    loadingUserConsoles === true && <ProfileComponentLoader/>
                }
            </div>
        </>
    )
}