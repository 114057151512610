/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './articleTile.css';
// react states
import { useEffect, useState } from 'react';
// custom hook(s)
import { useFetchMultipleGameData } from '../../Hooks/GameData/useFetchMultipleGameData';
import { useGetMultipleConsoles } from '../../Hooks/Consoles/useGetMultipleConsoles';


export default function ArticleTile({ articleData }){
    
    // Tp get the relevant games
    const { multipleGameData, fetchMultipleGameData } = useFetchMultipleGameData();
    
    // To get the relevant consoles
    const { multipleConsoleData, getMultipleConsoles } = useGetMultipleConsoles();

    //To get the date in the format wanted.
    const [articleDate, setArticleDate] = useState(null); 

    useEffect(()=>{
        if(articleData !== undefined && articleData !== null){
            fetchMultipleGameData(articleData.relevantGames);
            getMultipleConsoles(articleData.relevantConsoles);
            
            // Timestamp conversion to date format desired.
            const date = new Date(articleData.createdAt.seconds * 1000 + articleData.createdAt.nanoseconds / 1000000);
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            const formattedDate = date.toLocaleDateString('en-US', options);
            setArticleDate(formattedDate);
        }
    },[])

    // console.log(articleData);
    // console.log(multipleGameData);
    // console.log('consoles => ', multipleConsoleData);
    
    return(
        <div className="article-tile-container">
            {
                multipleGameData && multipleConsoleData &&
                <div className="article-bg-img-container">
                {
                    multipleGameData.length > 0 && multipleConsoleData.length === 0 && 
                    <img src={multipleGameData[0].cover_image_url} alt={multipleGameData[0].name} />
                }
                {
                    multipleGameData.length === 0 && multipleConsoleData.length > 0 && 
                    <img src={multipleConsoleData[0].pictures[1]} alt={multipleConsoleData[0].name} />
                }
                {
                    multipleGameData.length > 0 && multipleConsoleData.length > 0 && 
                    <img src={multipleGameData[0].cover_image_url} alt={multipleGameData[0].name} />
                }
                </div>
            }
            <div className="article-tile-overlay-div">
                {
                    articleDate && <p className='article-title-normal-text'>{articleDate}</p>
                }
                <p className='article-title-heading'>{articleData.titleText}</p>
                <div className="article-tile-space-div"></div>
                {
                    multipleGameData && multipleGameData.length > 0 &&
                    <div className="relevant-games-tags">
                    {
                        multipleGameData.slice(0,3).map((game)=>(
                            <div key={game.id} className="article-relevance-tag">
                                {game.name}
                            </div>
                        ))
                    }
                    </div>

                }
                <div className="article-tile-space-div"></div>
                {
                    multipleConsoleData && multipleConsoleData.length > 0 &&
                    <div className="relevant-games-tags">
                    {
                        multipleConsoleData.slice(0,3).map((console)=>(
                            <div key={console.id} className="article-relevance-tag">
                                {console.acronym}
                            </div>
                        ))
                    }
                    </div>

                }
                <div className="article-tile-space-div"></div>
            </div>
        </div>
    )
}