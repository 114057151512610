export function getReadableTime( dateObject ){
    
    let { seconds, nanoseconds, _seconds, _nanoseconds } = dateObject;
    if(seconds === undefined || seconds === null){
        if(_seconds !== undefined && _seconds !== null){
            seconds = _seconds;
        }
    }

    if(nanoseconds === undefined || nanoseconds === null){
        if(_nanoseconds !== undefined && _nanoseconds !== null){
            nanoseconds = _nanoseconds;
        }
    }

    // console.log({
    //     'seconds':seconds,
    //     'nanoseconds':nanoseconds
    // })

    const date = new Date(seconds * 1000 + Math.floor(nanoseconds / 1000000))
    const now = Date.now();
    
    const seconds_passed = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds_passed / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} days ago`;
    } else if (hours > 0) {
        return `${hours} hours ago`;
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `${seconds_passed} seconds ago`;
    }
}