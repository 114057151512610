/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './gameReviewBox.css';
// react states
import { useEffect, useState } from 'react';
// react router dom
import { useNavigate } from 'react-router-dom';
// hooks
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
// custom component
import Sharing from '../../Sharing/sharing';
import DiscussionArea from '../../DiscussionAreaComponents/discussionArea';
import { getReadableTime } from '../../TimeVarianceUtilityComponent/timingFunction';
// mui icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, Dialog, DialogContent, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';


// to give reactions
import { useGiveReactions } from '../../../Hooks/Reactions/useGiveReactions';
import { useDeleteReview } from '../../../Hooks/useDeleteReview';
import { useGetConsoleFromAcronym } from '../../../Hooks/Consoles/useGetConsoleFromAcronym';

const GameReviewBox = ({ review, currentUser, gameName }) => {

    const { getProfileDetails, userData } = useGetUserProfile();
    const currentUserData = useGetUserProfile();

    // to navigate to full review if needed
    const reviewNavigate = useNavigate();
    const navigateToFullReview = ()=>{
        reviewNavigate(`/full_review/${review.id}`);
    }

    // To navigate to user profile if needed
    const navigateToReviewUserProfile = ()=>{
        reviewNavigate(`/user_profile/${review.userID}`);
    }

    // To navigate to reporting the review section.
    const handleNavigateToReporting = ()=>{
        reviewNavigate('/report', {
            state:{
                'item': review,
                'reporter': currentUser,
                'type':'review'
            }
        })
    }

    // To navigate the user to the console page.
    const { consoleID } = useGetConsoleFromAcronym((review !== null && review !== undefined) ? review.console_played_on : null);
    const handleNavigateToConsole = ()=>{
        if(consoleID !== null && consoleID !== undefined){
            reviewNavigate(`/consoles/${consoleID}`);
        }else{
            return;
        }
    }

    // To get the review in a particular word limit.
    const [reviewText, setReviewText] = useState(null);
    const [enableReadMore, setEnableReadMore] = useState(false);

    // To get the time since the posting of the review.
    const[reviewTimeElapsed, setReviewTimeElapsed] = useState(null);
 
    useEffect(()=>{
        getProfileDetails(review.userID);
        currentUserData.getProfileDetails(currentUser);

        if(review.review_text.length > 400){
            setReviewText(review.review_text.slice(0,400)+"...");
            setEnableReadMore(true);
        }else{
            setReviewText(review.review_text);
        }

        // Getting how long ago the review was written.
        const revTimeGoneBy = getReadableTime(review.date);
        setReviewTimeElapsed(revTimeGoneBy);
    },[review])


    // To get reactions
    const { prevReaction, tempLikes, tempDislikes,
        handleClickLike, handleClickDislike } = useGiveReactions(
            'review',
            review.id,
            currentUser
        );

    // To share the review
    const [startSharingRev, setStartSharingRev] = useState(false);
    const openReviewSharingDialog = ()=>{
        setStartSharingRev(true);
    }
    const closeReviewSharingDialog = ()=>{
        setStartSharingRev(false);
    }

    // To open the comments section
    const [openRevComments, setOpenRevComments] = useState(false);
    const toggleRevBoxComments = ()=>{
        if(openRevComments === false)
            setOpenRevComments(true);
        else if(openRevComments === true)
            setOpenRevComments(false);
    }

    // To get the number of comments on the review
    const [commentsCount, setCommentsCount] = useState(0);
    // console.log(commentsCount);

    // To delete the review
    const [openRevDeletionBox, setOpenRevDeletionBox] = useState(false);
    const handleOpenRevDeletionBox = ()=>{
        setOpenRevDeletionBox(true);
    }
    const handleCloseRevDeletionBox = ()=>{
        setOpenRevDeletionBox(false);
    }

    // Hook to use to delete the review
    const { deleting, deleteReview, success } = useDeleteReview();

    // To open the menu for more options to select from reviews.
    const [anchor, setAnchor] = useState(null);
    const openMenu = Boolean(anchor);

    const handleOpenMenu = (menuEvent)=>{
        setAnchor(menuEvent.currentTarget);
    }
    const handleCloseMenu = ()=>{
        setAnchor(null);
    }

    return(
        <div className='user-review-container' id={`${review.id}`}>
            {
                startSharingRev === true &&
                <Sharing
                    type={'review'}
                    sharingLink = {`https://thegamerbroz.com/full_review/${review.id}`}
                    extraText={reviewText}
                    startSharing={startSharingRev}
                    closeSharingDialog={closeReviewSharingDialog}
                />
            }

            <Dialog
                open={openRevDeletionBox}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogContent className='game-rev-box-deletion-content'>
                    {
                        deleting === false && success === null &&
                        <>
                            <p>Are you sure you want to delete this review ?</p>
                            <div className="game-rev-del-box-action-row">
                                <button className="user-rev-del-btn"
                                        onClick={()=>deleteReview(review.id, review.gameID, currentUser)}
                                >
                                    Yes, I am sure
                                </button>
                                <button className="user-rev-del-btn secondary"
                                        onClick={()=>handleCloseRevDeletionBox()}                             
                                >
                                    No
                                </button>
                            </div>
                        </>
                    }
                    {
                        deleting === true && <CircularProgress size={16} style={{ color:'#FFE74C' }}/>
                    }
                    {
                        deleting === false && success === 'success' &&
                        <>
                            <p>Review deleted successfully.</p>
                            <button className="user-rev-del-btn"
                                    onClick={()=>handleCloseRevDeletionBox()}
                            >
                                OK
                            </button>    
                        </>
                    }
                    {
                        deleting === false && success === 'fail' &&
                        <>
                            <p>Oops!!! There seems to be some problem. Please try again later.</p>
                            <button className="user-rev-del-btn"
                                    onClick={()=>handleCloseRevDeletionBox()}
                            >
                                OK
                            </button>    
                        </>
                    }
                </DialogContent>
            </Dialog>
            <div className="spoiler-prompt-div">
            {
                review.spoilers === true &&
                <div className="spoiler-alert-div">
                    Spoiler alert
                </div>
            }
            {
                review.recommended === true &&
                <div className="review-recommend-div">
                    Recommended
                </div>
            }
            {
                review.console_played_on !== '' &&
                review.console_played_on !== undefined &&
                review.console_played_on !== null &&
                <div className="review-console-played-div">
                    PLAYED ON : 
                    <Button aria-label='User played this game on this platform/console/setup'
                            onClick={()=>handleNavigateToConsole()}
                    >
                        {review.console_played_on}
                    </Button>
                </div>
            }
            </div>
            <div className="review-score-and-share-div">
                <div className="review-score-div">
                    {review.score} / 10
                </div>
                <div className="review-share-cta">
                    <IconButton aria-label='Share'
                                onClick={()=>openReviewSharingDialog()}
                    >
                        <ShareIcon sx={{ color:'#f8f8f8' }}/>
                    </IconButton>
                </div>
            </div>
            {
                userData !== null && userData !== undefined &&
                <div className="review-user-data-div"
                     onClick={()=>navigateToReviewUserProfile()}
                >
                    <div className="review-user-pfp-box">
                    {
                        userData && userData !== null && userData !== undefined &&
                        userData.profile_picture !== null && userData.profile_picture !== undefined && 
                        userData.profile_picture !== '' &&
                        <img src={userData.profile_picture} alt=""/>
                    }
                    {
                        userData && userData !== null && userData !== undefined &&
                        (userData.profile_picture === null || userData.profile_picture === undefined ||
                        userData.profile_picture === '') &&
                        <p>{userData.Name.substring(0,1).toUpperCase()}</p>
                    }
                    </div>
                    <div className="review-user-data">
                    {
                        userData.Name !== null &&
                        userData.Name !== undefined &&
                        userData.Name !== '' &&
                        <p className='review-user-name'>{userData.Name}</p>
                    }
                    {reviewTimeElapsed !== null && reviewTimeElapsed !== undefined &&
                        <p className='review-date-time'>{reviewTimeElapsed}</p>
                    }
                    </div>
                </div>
            }
            {
                reviewText !== null && reviewText !== undefined &&
                reviewText !== '' &&
                <div className="review-para-div">
                    {reviewText}
                </div>
            }
            <div className="review-action-bar-div">
                <div className="review-like-dislike-div">
                    <div className="review-like-div">
                        <IconButton aria-label='Like'
                                    onClick={()=>handleClickLike()}
                        >
                            <ThumbUpIcon sx={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}/>
                        </IconButton>
                        <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}>
                            {tempLikes}
                        </p>
                    </div>
                    <div className="review-dislike-div">
                        <IconButton aria-label='Dislike'
                                    onClick={()=>handleClickDislike()}
                        >
                            <ThumbDownIcon sx={{ 'fontSize':'18px', 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}/>
                        </IconButton>
                        <p style={{ 'color':(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}>
                            {tempDislikes}
                        </p>
                    </div>
                    <Button variant='contained' sx={{ 'backgroundColor':'transparent', border:'none', outline:'none',
                            color:(openRevComments === true) ? '#FFE74C' : '#F8F8F8',
                            '&:hover': {
                                backgroundColor: 'inherit', // Keeps the original background color on hover
                                boxShadow: 'none',           // Removes the shadow on hover
                            },
                     }}
                            startIcon={<CommentIcon sx={{ 'fontSize':'18px', 'color':(openRevComments === true) ? '#FFE74C' : '#F8F8F8'}}/>}
                        onClick={()=>toggleRevBoxComments()}
                    >
                        Reply
                    </Button>
                    {
                        enableReadMore === true &&
                        <Button sx={{ color:'#f8f8f8', backgroundColor:'transparent', border:'none', outline:'none' }}
                                onClick={()=>navigateToFullReview()}
                        >
                            Read more
                        </Button>
                    }
                </div>
                {
                    currentUser !== null && currentUser !== undefined &&
                    <div className="review-menu-icon-div">
                        <IconButton aria-label='More options' onClick={(e)=>handleOpenMenu(e)}>
                            <MoreVertIcon sx={{ color:'#f8f8f8' }}/>
                        </IconButton>
                        <Menu
                            anchorEl={anchor}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                style: {
                                backgroundColor: '#333', // Sets the background color to black
                                color: '#f8f8f8', // Sets text color to white
                                },
                            }}
                        >
                            <MenuItem onClick={()=>handleNavigateToReporting()}>
                                <ListItemIcon>
                                    <ReportProblemIcon sx={{ color:'#f8f8f8' }}/>
                                </ListItemIcon>
                                <ListItemText>Report</ListItemText>
                            </MenuItem>
                            {
                                currentUser === review.userID &&
                                <MenuItem onClick={()=>handleOpenRevDeletionBox()}>
                                    <ListItemIcon>
                                        <DeleteIcon sx={{ color:'#f8f8f8' }}/>
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                </MenuItem>
                            }
                        </Menu>
                    </div>
                }
            </div>
            <div className="review-box-comments-area">
            {
                openRevComments === true &&
                review!== null &&
                review.id !== null &&
                <DiscussionArea discussionID={review.id} 
                                taggedGames={[review.gameID]}
                                section={'review'}
                                commentsCounter={setCommentsCount}
                                fullDoc={review}
                />
            }
            </div>
        </div>
    )
}

export default GameReviewBox;