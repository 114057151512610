import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCurrentlyPlaying, removeGameFromWishlist, removeGamesFromUser } from '../../Features/userPrivateDataSlice';

// firebase
import { db } from '../../Firebase/firebase';
import { doc, updateDoc, arrayRemove } from 'firebase/firestore';
import { selectUser } from '../../Features/userSlice';

export const useRemoveFromProfile = ()=>{
    const [removing, setRemoving] = useState(false);
    const [removeStatus, setRemoveStatus] =  useState(null);

    const[removingFromWishlist, setRemovingFromWishlist] = useState(false);
    const [removingFromCP, setRemovingFromCP] = useState(false);

    const signedInUser = useSelector(selectUser);
    const gameRemovalDispatch = useDispatch();

    const removeFromUserCollection = async(game)=>{
        if(signedInUser === null){
            return;
        }else{
            setRemoving(true);
            const gameCollectionRef = doc(db, `Users/${signedInUser.uid}/PrivateInfo`,'userInfo');
            await updateDoc(gameCollectionRef, {
                'game_collection':arrayRemove(game.id)
            }).catch((err)=>{
                setRemoving(false);
                setRemoveStatus(false);
                console.log(err);
                return;
            }).then(()=>{
                gameRemovalDispatch(removeGamesFromUser({ 
                    'userID':signedInUser.uid, 
                    'gameID':game.id
                }));

                setRemoving(false);
                setRemoveStatus(true);
                return;
            })
        }
    }

    const removeFromUserCurrentlyPlaying = async(game)=>{
        if(signedInUser === null){
            return;
        }else{
            setRemovingFromCP(true);
            const gameCollectionRef = doc(db, `Users/${signedInUser.uid}/PrivateInfo`,'userInfo');
            await updateDoc(gameCollectionRef, {
                'currently_playing':arrayRemove(game.id)
            }).catch((err)=>{
                setRemovingFromCP(false);
                // setRemoveStatus(false);
                console.log(err);
                return;
            }).then(()=>{
                gameRemovalDispatch(removeFromCurrentlyPlaying({ 
                    'userID':signedInUser.uid, 
                    'gameID':game.id
                }));

                setRemovingFromCP(false);
                // setRemoveStatus(true);
                return;
            })
        }
    }

    const removeFromUserWishlist = async(game)=>{
        if(signedInUser === null){
            return;
        }else{
            setRemovingFromWishlist(true);
            const gameCollectionRef = doc(db, `Users/${signedInUser.uid}/PrivateInfo`,'userInfo');
            await updateDoc(gameCollectionRef, {
                'wishlist':arrayRemove(game.id)
            }).catch((err)=>{
                setRemovingFromWishlist(false);
                // setRemoveStatus(false);
                console.log(err);
                return;
            }).then(()=>{
                gameRemovalDispatch(removeGameFromWishlist({ 
                    'userID':signedInUser.uid, 
                    'gameID':game.id
                }));

                setRemovingFromWishlist(false);
                // setRemoveStatus(true);
                return;
            })
        }
    }

    return { removing, removingFromWishlist, removingFromCP, removeStatus, removeFromUserCollection,
             removeFromUserWishlist, removeFromUserCurrentlyPlaying
    };
}