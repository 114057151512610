/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './discussionArea.css';
// react states
import { useState, useEffect } from 'react';
// custom components
import TGBTextInput from '../../PaywallFeatures/PaywallInputs/TGBtextInput';
import { useCommentsFetch } from '../../Hooks/Forums/ForumComments/useCommentsFetch';
// import ForumComment from '../Forum/forumComment';
import ForumComment from './forumComment';
import { useCommentsPost } from '../../Hooks/Forums/ForumComments/useCommentsPost';
import { CircularProgress } from '@mui/material';
import { useSendEmailNotifications } from '../../Hooks/EmailNotifications/useSendEmailNotifications';

export default function DiscussionArea({ section, discussionID, taggedGames, commentsCounter, fullDoc }){


    // For EMAIL Notifications
    const { makeEmailHTML } = useSendEmailNotifications();

    // To fetch Comments
    const { fetchedComments, fetchComments, fetchedCommentsCount } = useCommentsFetch(section);

    // To post Comment on this comment
    const { posting, postingError, postingStatus, setPostingError, postComment } = useCommentsPost(section);

    useEffect(()=>{
        // if(forumID !== null){
            fetchComments(discussionID, null);
        // }
    },[discussionID]);

    useEffect(()=>{
        commentsCounter(fetchedCommentsCount)
    },[fetchedCommentsCount])

    // console.log('fetchingComments => ',fetchingComments);
    // console.log('fetchedComments => ',fetchedComments);

    const [commentText, setCommentText] = useState('');

    const postingComment = ()=>{
        if(commentText === '' || commentText.length === 0){
            setPostingError('Please write something to post');
            return;
        }else{
            setPostingError(null);
            // console.log(`<p>${commentText}</p>`);
            const userComment = `<p>${commentText}</p>`;
            var commentObject = {};
            if(section === 'forum'){
                commentObject = {
                    'parent': null,
                    'commentText': userComment,
                    'commentType': 'T',
                    'forumID':discussionID,
                    'taggedGames': taggedGames,
                    'parentCommentUserID':fullDoc.userID,
                    'parentObject':fullDoc
                    // 'gameID':gameID,
                };
            }
            if(section === 'review'){
                commentObject = {
                    'parent': null,
                    'commentText': userComment,
                    'commentType': 'T',
                    'reviewID':discussionID,
                    'taggedGames': taggedGames,
                    'parentCommentUserID':fullDoc.userID,
                    'parentObject': fullDoc
                    // 'gameID':gameID,
                };
            }

            postComment(commentObject);

            setCommentText('');
        }
    }

    useEffect(()=>{
        if(postingStatus === true){
            makeEmailHTML('comment',section === 'forum' ? 'discussion' : 'review', taggedGames[0],
            (section === 'forum' ? `https://thegamerbroz.com/discussion/${discussionID}` : `https://thegamerbroz.com/full_review/${discussionID}`),
                fullDoc
            )
        }
    },[postingStatus])

    return(
        <div className="discussion-comment-area">
            <div className="normal-text-input-area">
                <TGBTextInput key={1} 
                            placeholderText={'Write here...'}
                            textInput={commentText}
                            setTextInput={setCommentText}
                />
                { postingError && <p className='comment-posting-error-alert'>{ postingError }</p> }
                <div className="forum-cta-btn"
                        onClick={()=>postingComment()}
                        onKeyDown={()=>postingComment()}
                >
                {
                    posting === true && <CircularProgress size={18} style={{ color:'#080808' }}/>
                }
                {
                    posting === false && 'Post'
                }    
                </div>
            </div>
            {
                fetchedComments && fetchedComments.length > 0
                && fetchedComments.map((comment)=>(
                    <ForumComment key={comment.id} commentObject={comment} section={section}/>
                ))
            }
        </div>
    )
}