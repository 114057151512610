/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './trendingReviewCarouselTile.css';
// react states
import { useState, useEffect } from 'react';
// react router dom
// import { useNavigate } from 'react-router-dom';
// utility functions
import { getReadableTime } from '../../../TimeVarianceUtilityComponent/timingFunction';


export default function TrendingReviewCarouselTile({ review }){

    const [revTime, setRevTime] = useState('');

    useEffect(()=>{
        if(review !== null && review !== undefined){
            const time = getReadableTime(review.reviewData.date);
            setRevTime(time);
        }
    },[])

    // const reviewCarouselNav = useNavigate();

    // const navigateToGamepage = ()=>{
    //     reviewCarouselNav(`/game_page/${review.reviewData.gameID}`);
    // }

    // const navigateToFullReview = ()=>{
    //     reviewCarouselNav(`/full_review/${review.id}`);
    // }

    return(
        <div className="review-carousel-tile">
            {/* About the game */}
            <div className="review-game-cover-container">
                <img src={review.reviewGameData.cover_image_url} alt={review.reviewGameData.name} />
                <div className="review-game-cover-overlay"></div>
                <div className="review-carousel-game-controls">
                    <p>{review.reviewGameData.name}</p>
                    <a className='review-carousel-gamepage-nav-btn'
                            // onClick={()=>navigateToGamepage()}
                        href={`https://thegamerbroz.com/game_page/${review.reviewData.gameID}`}
                    >
                        Go to game page
                    </a>
                </div>
            </div>
            {/* About the game */}

            {/* Now the starts the main review */}
            <div className="review-carousel-item-content">
                <div className="review-carousel-item-user-info">
                    <div className="review-carousel-item-user-info-pfp">
                    {
                        review && review.reviewUserData.profile_picture !== null &&
                        <img src={review.reviewUserData.profile_picture} alt={review.reviewUserData.Name} />
                    }
                    {
                        review && review.reviewUserData.profile_picture === null &&
                        `${review.reviewUserData.Name.substring(0,1).toUpperCase()}`
                    }
                    </div>
                    {review && <p className='review-carousel-item-user-info-username'>{review.reviewUserData.Name}</p>}
                    { revTime !== '' && revTime.length > 0 && <p className='review-carousel-item-date'>{revTime}</p> }
                </div>
                <div className="review-carousel-item-text">
                    <p className='review-carousel-item-score'>{review.reviewData.score}/10</p>
                    <p className='review-carousel-item-text-para'>{review.reviewData.review_text}</p>
                </div>
                <div className="review-carousel-item-action-buttons">
                    <a className='review-carousel-gamepage-nav-btn'
                            href={`https://thegamerbroz.com/full_review/${review.id}`}
                    >
                        Go to review
                    </a>
                </div>
            </div>
        </div>
    )
}