// firebase
import { useQuery } from '@tanstack/react-query';
import { db } from '../../Firebase/firebase';
import { getDocs, collection, query, where, orderBy } from 'firebase/firestore';

const fetchPictures = async(gameID)=>{
    if(!gameID){
        throw new Error('Picture cannot be displayed');
    }

    const pictureQuery = query(collection(db,'GameScreenshots'), where('gameID','==',gameID),
                            orderBy('createdAt','desc')
                        );
    const pictureSnapshots = await getDocs(pictureQuery);

    return pictureSnapshots.docs.map((picture)=>({ 'id':picture.id, ...picture.data() }));
}

export const useFetchGamePics = (game_id)=>{
    return useQuery({
        queryKey:['pictures',`${game_id}`],
        queryFn: ()=>fetchPictures(game_id),
        enabled: !!game_id
    })
}