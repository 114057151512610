// react states
import { useState } from "react"
// firebase
import { db } from '../../Firebase/firebase';
import { collection, query, orderBy, limit, getDocs, where, startAfter, 
         doc, deleteDoc, updateDoc } from "firebase/firestore";


export const useFetchUserDiscussions = ()=>{
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [userCreatedDiscussion, setUserCreatedDiscussion] = useState([]);
    const [lastVisibleDiscussion, setLastVisibleDiscussion] = useState(null);

    // For comments
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const [commentLoadingError, setCommentLoadingError] = useState(null);
    const [userCreatedComments, setUserCreatedComments] = useState([]);
    const [lastVisibleComment, setLastVisibleComment] = useState(null);

    // For deletion
    const [deletingError, setDeletingError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingStatus, setDeletingStatus] = useState(null);

    // For comment deletion
    const [commentDeletingError, setCommentDeletingError] = useState(null);
    const [isCommentDeleting, setIsCommentDeleting] = useState(false);
    const [deletingCommentStatus, setDeletingCommentStatus] = useState(null);

    const getUserDiscussions = async(userID)=>{
        // console.log('User id in getUserDiscussions => ', userID);
        if(userCreatedDiscussion && userCreatedDiscussion.length > 0){
            return;
        }
        setIsLoading(true);
        const firstBatch = query(collection(db, "Forums"), where("userID","==",`${userID}`), orderBy("createdAt","desc"), limit(10));
        const discussionSnapshots = await getDocs(firstBatch)
        .catch((err)=>{
            setLoadingError(err.message);
            setIsLoading(false);
        });
        // console.log('disc => ',discussionSnapshots);
        if(!discussionSnapshots.empty){
            const lastVisible = discussionSnapshots.docs[discussionSnapshots.docs.length-1];
            setLastVisibleDiscussion(lastVisible);

            var tempDiscussionArr = [];
            for(const disc of discussionSnapshots.docs){
                var discObj = {
                    'id':disc.id,
                    ...disc.data()
                }

                tempDiscussionArr.push(discObj);
            }

            setUserCreatedDiscussion(tempDiscussionArr);
            setLoadingError(null);
            setIsLoading(false);
        }else{
            setLastVisibleDiscussion(null);
            setLoadingError(null);
            setIsLoading(false);
        }
    }

    const fetchMoreUserDiscussions = async(userID)=>{
        setIsLoading(true);
        const nextBatch = query(collection(db, "Forums"),
        where("userID","==",`${userID}`),
        orderBy("createdAt","desc"),
        startAfter(lastVisibleDiscussion),
        limit(10));
        
        const discussionSnapshots = await getDocs(nextBatch)
        .catch((err)=>{
            setLoadingError(err.message);
            setIsLoading(false);
        });

        if(!discussionSnapshots.empty){
            const lastVisible = discussionSnapshots.docs[discussionSnapshots.docs.length-1];
            setLastVisibleDiscussion(lastVisible);

            var tempDiscussionArr = [];
            for(const disc of discussionSnapshots.docs){
                var discObj = {
                    'id':disc.id,
                    ...disc.data()
                }

                tempDiscussionArr.push(discObj);
            }

            setUserCreatedDiscussion(userCreatedDiscussion => [...userCreatedDiscussion, ...tempDiscussionArr]);
            setLoadingError(null);
            setIsLoading(false);
        }else{
            setLastVisibleDiscussion(null);
            setLoadingError(null);
            setIsLoading(false);
        }
    }

    const deleteUserDiscussion = async (forumid)=>{
        // Do the deleting process here.
        setIsDeleting(true);
        setDeletingStatus('comment_delete');
        // First remove all the comments if any.
        const discussionCommentsQuery = query(collection(db, "ForumComments"), where("forumID", "==", `${forumid}`));
        const discussionCommentSnapshots = await getDocs(discussionCommentsQuery)
        .catch((err)=>{
            setDeletingError(err.message);
            setIsDeleting(false);
            setDeletingStatus('fail');
            return;
        }); 
        
        // now go to each discussionCommentSnapshot and delete that doc.
        if(discussionCommentSnapshots && discussionCommentSnapshots.docs.length > 0){
            for(const discussionSnap of discussionCommentSnapshots.docs){
                await deleteDoc(doc(db, "ForumComments", `${discussionSnap.id}`))
                .catch((err)=>{
                    setDeletingError(err.message);
                    setIsDeleting(false);
                    setDeletingStatus('fail');
                    return;
                });
            }
        }
        // Comments deletion of the forum is done.

        // Now you'll have to delete the likes and dislikes on this discussion.
        setDeletingStatus('reaction_delete');
        const reactionQuery = query(collection(db, `Forums/${forumid}/Likes`));
        const reactionQuerySnapshot = await getDocs(reactionQuery);
        if(reactionQuerySnapshot && reactionQuerySnapshot.docs.length > 0){
            for(const reactionSnap of reactionQuerySnapshot.docs){
                await deleteDoc(doc(db, `Forums/${forumid}/Likes`, `${reactionSnap.id}`))
                .catch((err)=>{
                    setDeletingError(err.message);
                    setIsDeleting(false);
                    setDeletingStatus('fail');
                    return;
                });
            }
        }
        // Now you'll have to delete the likes and dislikes on this discussion.

        // Then remove the forum as a whole.
        setDeletingStatus('forum_delete');
        await deleteDoc(doc(db,"Forums",`${forumid}`)).catch((err)=>{
            setDeletingError(err.message);
            setIsDeleting(false);
            setDeletingStatus('fail');
            return;
        }).then(()=>{
            // After it is removed from the database, also remove it locally.
            const tempUserDiscussionArray = userCreatedDiscussion;
            const objWithIdIndex = tempUserDiscussionArray.findIndex((discussion) => discussion.id === forumid);
            if (objWithIdIndex > -1) {
                tempUserDiscussionArray.splice(objWithIdIndex, 1);
                setUserCreatedDiscussion(tempUserDiscussionArray);
            }
        });

        setIsDeleting(false);
        setDeletingError(null);
        setDeletingStatus('success');
    }

    const getUserComments = async(userID) => {
        // console.log('User id in getUserComments => ', userID);
        if(userCreatedComments && userCreatedComments.length > 0){
            return;
        }
        setIsLoadingComments(true);
        const firstBatch = query(collection(db, "ForumComments"), 
        where("user_id","==",`${userID}`),
        where("deleted",'==',false),
        limit(10));
        const commentSnapshots = await getDocs(firstBatch)
        .catch((err)=>{
            setCommentLoadingError(err.message);
            setIsLoadingComments(false);
        });
        // console.log('comments => ',commentSnapshots);
        if(!commentSnapshots.empty){
            const lastVisibleComment = commentSnapshots.docs[commentSnapshots.docs.length-1];
            setLastVisibleComment(lastVisibleComment);

            var tempCommentsArr = [];
            for(const comment of commentSnapshots.docs){
                var commentObj = {
                    'currentCommentID':comment.id,
                    ...comment.data()
                }

                tempCommentsArr.push(commentObj);
            }

            setUserCreatedComments(tempCommentsArr);
            setCommentLoadingError(null);
            setIsLoadingComments(false);
        }else{
            setLastVisibleComment(null);
            setCommentLoadingError(null);
            setIsLoadingComments(false);
        }
    }

    const fetchMoreUserComments = async(userID)=>{
        setIsLoadingComments(true);
        const nextBatch = query(collection(db, "ForumComments"),
        where("user_id","==",`${userID}`),
        where("deleted","==",false),
        orderBy("createdAt"),
        startAfter(lastVisibleComment),
        limit(10));
        
        const commentSnapshots = await getDocs(nextBatch)
        .catch((err)=>{
            setCommentLoadingError(err.message);
            setIsLoadingComments(false);
        });

        if(!commentSnapshots.empty){
            const lastVisible = commentSnapshots.docs[commentSnapshots.docs.length-1];
            setLastVisibleComment(lastVisible);

            var tempCommentArr = [];
            for(const comment of commentSnapshots.docs){
                var commentObj = {
                    'currentCommentID':comment.id,
                    ...comment.data()
                }

                tempCommentArr.push(commentObj);
            }

            setUserCreatedComments(userCreatedComments => [...userCreatedComments, ...tempCommentArr]);
            setCommentLoadingError(null);
            setIsLoadingComments(false);
        }else{
            setLastVisibleComment(null);
            setCommentLoadingError(null);
            setIsLoadingComments(false);
        }
    }

    const deleteUserComments = async(commentID) => {
        setIsCommentDeleting(true);
        const commentRef = doc(db, "ForumComments", `${commentID}`);
        await updateDoc(commentRef, {
            deleted: true
        }).catch((err)=>{
            setCommentDeletingError(err.message);
            setIsCommentDeleting(false);
            setDeletingCommentStatus('fail');
            return;
        }).then(()=>{
            setCommentDeletingError(null);
            setIsCommentDeleting(false);
            setDeletingCommentStatus('success');
        });
    }

    return { isLoading, loadingError, userCreatedDiscussion, getUserDiscussions
             , fetchMoreUserDiscussions, lastVisibleDiscussion, deleteUserDiscussion,
             isDeleting, deletingError, deletingStatus,
             isLoadingComments, setUserCreatedComments,
             getUserComments, fetchMoreUserComments, userCreatedComments, lastVisibleComment, 
             commentLoadingError, deleteUserComments, commentDeletingError, isCommentDeleting, deletingCommentStatus
            };
}