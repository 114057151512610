// styles
import './console_page.css';
// react router dom
import { useNavigate, useParams } from 'react-router-dom';
// custom hooks
import { useGetConsoleData } from '../../Hooks/Consoles/useGetConsoleData';
import { useGetAllConsoles } from '../../Hooks/Consoles/useGetAllConsoles';
// mui components
import { useEffect, useState } from 'react';
import ConsoleDetailComponent from '../../Components/ConsolesPage/consoleDetailsComponent';
// For page indexing and SEO
import { Helmet } from 'react-helmet';

export default function ConsolePage(){

    const { console_id } = useParams();

    // For SEO purposes
    const console_canonical_url = 'https://thegamerbroz.com/consoles/nzlgPH1Pypsf6noUXB19';

    const { consoleData,loadingConsoleData } = useGetConsoleData(console_id);
    const { allConsoleData, loadingAllConsoles } = useGetAllConsoles();
    // console.log(allConsoleData);

    // Now to manage the currently selected console.
    const [consoleSelected, setConsoleSelected] = useState(null);
    useEffect(()=>{
        if(console_id !== null && console_id !== undefined && consoleData){
            setConsoleSelected(consoleData);
        }
    },[console_id, consoleData])

    const consoleNav = useNavigate();
    const handleConsoleTileClick = (new_console_id)=>{
        consoleNav(`/consoles/${new_console_id}`);
    }

    return(
        <div className="console-page-container">
            <Helmet>
                {consoleData && <title>{consoleData.name}</title>}
                <link rel="canonical" href={console_canonical_url}/>
                <meta name='tagline' content={'For the gamers. By the gamers.'}/>
                <meta name='keywords' content={'game, consoles, gaming consoles, video game consoles'}/>
            </Helmet>
            <div className="all-console-tiles-container">
            {
                loadingAllConsoles === false && 
                consoleSelected && allConsoleData &&
                allConsoleData.map((setup)=>(
                    <button className={(setup.id !== consoleSelected.id) ? "all-console-tile" : "all-console-tile selected"} 
                            key={setup.id}
                            onClick={()=>handleConsoleTileClick(setup.id)}
                    >
                        {setup.name}
                    </button>
                ))
            }
            </div>
            <div className="main-console-page-area">
                <div className="console-ad-slot-1">
                    Ad area 1
                </div>
                <div className="selected-console-data-area">
                {
                    loadingConsoleData === false 
                    && consoleData &&
                    <ConsoleDetailComponent key={consoleData.id}
                                            consoleDetails={consoleData}
                    />
                }
                </div>
                <div className="console-ad-slot-2">
                    Ad area 2
                </div>
            </div>
        </div>
    );
}