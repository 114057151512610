import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../Features/userSlice';
import userPrivateDataReducer from '../Features/userPrivateDataSlice';
import allDatabaseGamesReducer from '../Features/gameData';
import articleReducer from '../Features/articleSlice';

export const store = configureStore({
    reducer:{
        'user' : userReducer,
        'userPrivateData': userPrivateDataReducer,
        'allGamesData': allDatabaseGamesReducer,
        // Articles
        'articles': articleReducer 
    }
})