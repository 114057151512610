/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../Firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';

export const useGameDetailsFetch = (game_id)=>{
    const [gameFetchError, setGameFetchError] = useState(null);
    const [gameDetails, setGameDetails] = useState(null);
    const [fetchingGame, setFetchingGame] = useState(false);

    const getGameDetails = async(id)=>{
        setFetchingGame(true);
        const gameRef = doc(db, "Games", `${id}`);
        const gameSnap = await getDoc(gameRef);

        if(!gameSnap.exists()){
            gameFetchError("Game doesn't exist in the database");
            setFetchingGame(false);
        }else{
            var gameObj = {
                'id':gameSnap.id,
                ...gameSnap.data()
            }

            setGameDetails(gameObj);
            setFetchingGame(false);
            setGameFetchError(null);
        }
    }

    useEffect(()=>{
        if(game_id !== null){
            getGameDetails(game_id);
        }
    },[game_id]);

    return { gameFetchError, fetchingGame, gameDetails };
}