/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './profileUserReviews.css';
// react states
import { useEffect, useState } from 'react';
// firebase
import { auth } from '../../Firebase/firebase';
// custom hooks
import { useGetProfileUserReviews } from '../../Hooks/useGetProfileUserReviews';
// import UserReviewReadBox from './ProfileUserReviewsComp/userRevReadBox';
import { useGetUserProfile } from '../../Hooks/useGetUserProfile';
import GameReviewBox from '../GamepageComponents/GameReviewComponent/gameReviewBox';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


export default function ProfileUserReviews({ userProfileID, loggedInUserID }){

    const { loadingProfileUserReviews, profileUserReview,
            setProfileUserReviews, 
            getNextBatchReviews, endPoint
          } = useGetProfileUserReviews(userProfileID);

    const signedInUser = auth.currentUser;

    const { getProfileDetails, userData } = useGetUserProfile();
    useEffect(()=>{
        getProfileDetails(userProfileID);
    },[userProfileID])

    // console.log('loading profile reviews => ', loadingProfileUserReviews);
    // console.log('profile reviews => ',profileUserReview);

    // To keep track of which review was deleted
    const [deletedIndex, setDeletedIndex] = useState(-1);

    useEffect(()=>{
        // console.log('Index deleted => ',deletedIndex);
        var tempRevArray = [];
        if(deletedIndex!==-1){
            tempRevArray = profileUserReview;
            tempRevArray.splice(deletedIndex,1);
            setProfileUserReviews(tempRevArray);
            getNextBatchReviews();
        }
    },[deletedIndex, setDeletedIndex]);

    // console.log('profileUserReview => ',profileUserReview);
    const userProfileReviewsNav = useNavigate();
    const navigateToGamePage = (gameID)=>{
        userProfileReviewsNav(`/game_page/${gameID}#reviews`)
    }

    return(
        <div className="profile-user-reviews-container" id='user_reviews'>
        {
            userData !== null &&
            <Helmet>
                <title>{`${userData.Name} - Reviews`}</title>
                <meta name='description' content={`${userData.Name} all game reviews`}/>
                <meta name='keywords' content={`${userData.Name}, ${userData.Name} reviews, game reviews, reviews, impressions, user impressions, game thoughts`}/>
            </Helmet>
        }
        {
            (loadingProfileUserReviews === false && profileUserReview!==null
            && profileUserReview.length > 0) && 
            profileUserReview.map((current_rev, index)=>(
                // <UserReviewReadBox key={current_rev.reviewID} review={current_rev}
                //                    localRevIndex = {index}
                //                    currentUser={userProfileID} 
                //                    signedInUser={signedInUser!==null ? signedInUser.uid : null}
                //                    onDeletion = {setDeletedIndex}
                // />
                <div key={current_rev.reviewID} className="user-profile-game-rev-box">
                    <Button variant='contained' sx={{ backgroundColor:'#ffe74c', color:'#080808', 
                                                borderRadius:'25px 25px 25px 25px',
                                                '&:hover': {
                                                    backgroundColor: '#FFE74C', // Keeps the original background color on hover
                                                    boxShadow: 'none',           // Removes the shadow on hover
                                                }}}
                            onClick={()=>navigateToGamePage(current_rev.gameID)}
                    >
                        {current_rev.gameName}
                    </Button>
                    <GameReviewBox  review={current_rev}
                            currentUser={signedInUser!==null ? signedInUser.uid : null}
                            gameName={current_rev.gameID}
                    />
                </div>
                
            ))
        }
        {
            (loadingProfileUserReviews === false) && profileUserReview === null
                && 
                <div className="no-profile-review-area">
                {
                    userProfileID === loggedInUserID
                    && <p>You haven't reviewed any games yet. Start doing it now.</p>
                }
                {
                    userProfileID !== loggedInUserID
                    && userData && <p>{`${userData.Name} hasn't reviewed any games yet.`}</p>
                }
                </div>
        }
        {
            (loadingProfileUserReviews === true) && 
            // <CircularProgress style={{ 'color':'black' }}/>
            <div className="user-profile-rev-loader">
                <div className="user-profile-rev-tile-loader">
                    <div className="tile-game-name-loader"></div>
                    <div className="tile-game-date-loader"></div>
                    <div className="tile-game-score-loader"></div>
                    <div className="tile-game-text-loader"></div>
                    <div className="tile-game-reactions-loader"></div>
                </div>
                <div className="user-profile-rev-tile-loader">
                <div className="tile-game-name-loader"></div>
                    <div className="tile-game-date-loader"></div>
                    <div className="tile-game-score-loader"></div>
                    <div className="tile-game-text-loader"></div>
                    <div className="tile-game-reactions-loader"></div>
                </div>
                <div className="user-profile-rev-tile-loader">
                <div className="tile-game-name-loader"></div>
                    <div className="tile-game-date-loader"></div>
                    <div className="tile-game-score-loader"></div>
                    <div className="tile-game-text-loader"></div>
                    <div className="tile-game-reactions-loader"></div>
                </div>
                <div className="user-profile-rev-tile-loader">
                <div className="tile-game-name-loader"></div>
                    <div className="tile-game-date-loader"></div>
                    <div className="tile-game-score-loader"></div>
                    <div className="tile-game-text-loader"></div>
                    <div className="tile-game-reactions-loader"></div>
                </div>
                <div className="user-profile-rev-tile-loader">
                <div className="tile-game-name-loader"></div>
                    <div className="tile-game-date-loader"></div>
                    <div className="tile-game-score-loader"></div>
                    <div className="tile-game-text-loader"></div>
                    <div className="tile-game-reactions-loader"></div>
                </div>
            </div>
        } 
        <div className="load-more-container">
        {
            endPoint === false && 
            <div className='load-more-button'
                onClick={()=>getNextBatchReviews()}
            >
                Load more
            </div>
        }
        </div>
        </div>
    )
}