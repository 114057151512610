// styles
import './genreMenuDropdown.css';
// react router dom
import { useNavigate } from 'react-router-dom';
// Material UI
import { CircularProgress, Menu, MenuItem} from '@mui/material';
// Custom hooks
import { useGetAllGenres } from '../../../Hooks/Genres/useGetAllGenres';


export default function GenreMenuDropdown({ genreMenuAnchor, setGenreMenuAnchor }){
    const { loadingAllGenres, allGenres } = useGetAllGenres();

    const handleCloseGenreDropdown = ()=>{
        setGenreMenuAnchor(null);
    }

    // For navigation to the genre page
    const genreMenuNav = useNavigate();
    const navigateToGenrePage = (genre_id)=>{
        if(genre_id !== null && genre_id !== undefined){
            handleCloseGenreDropdown();
            genreMenuNav(`/genres/${genre_id}`);
        }
    }

    return(
        <Menu anchorEl={genreMenuAnchor}
              open={Boolean(genreMenuAnchor)}
              onClose={()=>handleCloseGenreDropdown()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 0.5,
                horizontal: 0,
              }}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#191917",
                  color:'#f8f8f8',
                  padding:'2%',
                  height:'auto',
                  width:'50%'
                }
              }}
        >
        {
            loadingAllGenres && 
            <div className="genre-menu-loading">
                <CircularProgress sx={{ color:'#f8f8f8' }}/>
                <p>Loading all genres...</p>
            </div>
        }
        {
            !loadingAllGenres && allGenres &&
            <div className="genre-menu-data">
            {
                allGenres.map((genre)=>(
                    <MenuItem key={genre.id} className='genre-menu-item' onClick={()=>navigateToGenrePage(genre.id)}>
                        {genre.name}
                    </MenuItem>
                ))
            }
            </div>
        }
        </Menu>
    );
}   