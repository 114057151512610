// styles
import './search_game_box.css';
// routing
import { Link } from 'react-router-dom';
// custom component
import SearchGameTile from './SearchGameComponents/search_game_tile_box';
// SEO
import { Helmet } from 'react-helmet';

export default function SearchGameBox({ searchList }){

    return(
        <div className="search-result-box">
        <Helmet>
            <title>Search Games</title>
            <meta name='description' content={`Search games`}/>
        </Helmet>
        {
            searchList.length!==0 && 
                searchList.map((obj)=>(
                    <Link key={obj.id} to={`/game_page/${obj.id}`} state={{ gameid : obj.id }} style={{ textDecoration: 'none', color:'inherit' }}>
                        <SearchGameTile searchedGame={obj}/>
                    </Link>
                ))
        }
        </div>
    );
}