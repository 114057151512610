// styles
import './commentUserProfile.css';

// react routing
import { Link } from 'react-router-dom';

export default function CommentUser({ userObject }){
    // console.log('userObject => ',userObject);
    return(
        <div className='comment-user-profile'>
            <Link to={`/user_profile/${userObject.id}`} style={{ textDecoration:'none' }}>
                <div className="comment-user-info">
                    <div className="comment-user-pfp">
                    {
                        userObject.profile_picture !== null &&
                        <img src={userObject.profile_picture} alt=""/>
                    }
                    {
                        userObject.profile_picture === null &&
                        <p>{userObject.Name.substring(0,1).toUpperCase()}</p>
                    }    
                    </div>
                    <p className='comment-user-name'>{userObject.Name}</p>
                </div>
            </Link>
        </div>
    )
}