// styles
import './userGameOptions.css';
// mui components
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';

// react routing
import { Link } from 'react-router-dom';

// custom hooks
import { useAddToProfile } from '../../../Hooks/GameUserInteraction/useAddToProfile';
import { useRemoveFromProfile } from '../../../Hooks/GameUserInteraction/useRemoveFromProfile';
import GameProfileArea from '../../GamepageComponents/AboutComponent/GameProfileComponent/gameProfile';

export default function UserGameOptions({ gameID, openOptions, setOpenOptions,
                                            userProfileID, loggedInUserID,
                                            currentGameIsCP, gameData
}){
    
    // To add to currently playing
    const { addingToCP, addGameToCurrentlyPlaying } = useAddToProfile();                                
    const { removingFromCP, removing, removeFromUserCollection,
            removeFromUserCurrentlyPlaying
    } = useRemoveFromProfile();

    const handleAddToCurrentlyPlaying = ()=>{
        addGameToCurrentlyPlaying({ 'id': gameID});
    }

    const handleRemoveFromCurrentlyPlaying = ()=>{
        removeFromUserCurrentlyPlaying({ 'id': gameID});
    }

    const handleRemoveFromUserCollection = ()=>{
        if(currentGameIsCP === true){
            removeFromUserCurrentlyPlaying({ 'id': gameID });
            removeFromUserCollection({ 'id': gameID });
        }else{
            removeFromUserCollection({ 'id': gameID });
        }
        
    }

    
    return(
        <Dialog
            open={openOptions}
            onClose={()=>setOpenOptions(false)}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogContent className='user-game-options-box'
                           style={{ 
                                background:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.95)), url(${gameData.thumbnail_image})`,
                                backgroundRepeat:'no-repeat',
                                backgroundPosition:'center',
                                backgroundSize:'cover'
                           }}
            >
                <GameProfileArea gameDetails={gameData}/>
                <Link to={`/game_page/${gameID}`} style={{ 'textDecoration':'none', 'width':'100%' }}>
                    <div className="user-option-tile">
                        <SportsEsportsIcon style={{ fontSize:'2rem', color : '#FFE74C' }}/>
                        <p className='user-option-tile-text'>Go to gamepage</p>
                    </div>
                </Link>
                {
                    userProfileID === loggedInUserID &&
                    currentGameIsCP === false &&
                        <div className="user-option-tile"
                            onClick={()=>handleAddToCurrentlyPlaying()}
                        >
                            {
                                addingToCP === true && <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                            }
                            {
                                addingToCP === false &&
                                <>
                                    <AddIcon style={{ fontSize:'2rem', color : '#FFE74C' }}/>
                                    <p className='user-option-tile-text'>Add to currently playing</p>
                                </>
                            }
                        </div>
                }
                {
                    userProfileID === loggedInUserID &&
                    currentGameIsCP === true &&
                    <div className="user-option-tile"
                        onClick={()=>handleRemoveFromCurrentlyPlaying()}
                    >
                        {
                            removingFromCP === true && <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                        }
                        {
                            removingFromCP === false &&
                            <>
                                <RemoveIcon style={{ fontSize:'2rem', color : '#FFE74C' }}/>
                                <p className='user-option-tile-text'>Remove from currently playing</p>
                            </>
                        }
                    </div>
                }
                {
                    userProfileID === loggedInUserID &&
                    <div className="user-option-tile"
                        onClick={()=>handleRemoveFromUserCollection()}
                    >
                        {
                            removing === true && <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                        }
                        {
                            removing === false && 
                            <>
                                <RemoveIcon style={{ fontSize:'2rem', color : '#FFE74C' }}/>
                                <p className='user-option-tile-text'>Remove from collection</p>
                            </>
                        }
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}