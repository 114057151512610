/* eslint-disable react-hooks/exhaustive-deps */
// styles
import { useEffect, useState } from 'react';
import './userGamesTile.css';
import { useFetchGameData } from '../../../Hooks/GameData/useFetchGameData';
import UserGameOptions from './useGameOptions';

export default function UserGameTile({ gameID, userProfileID, loggedInUserID,
                                        isCurrentlyPlaying
                                    }){
    
    // custom hook to get game details of a game
    const { fetching, fetchGameData, gameData } = useFetchGameData();

    useEffect(()=>{
        if(gameID !== null && gameID !== undefined)
            fetchGameData(gameID);
    },[])

    // console.log(gameData, isCurrentlyPlaying);
    const [openOptionsDialog, setOpenOptionsDialog] = useState(false);
    const handleOpenOptionsDialog = ()=>{
        setOpenOptionsDialog(true);
    }


    return(
        <>
        {
            gameData !== null &&
                <UserGameOptions
                    gameID={gameID}
                    openOptions={openOptionsDialog}
                    setOpenOptions={setOpenOptionsDialog}
                    userProfileID={userProfileID}
                    loggedInUserID={loggedInUserID}
                    currentGameIsCP={isCurrentlyPlaying}
                    gameData={gameData}
                />
        }
        {
            fetching === false &&
            <div className="user-game-tile">
            {
                gameData !== undefined && gameData !== null &&
                <div className="user-game-tile-image-container"
                     onClick={()=>handleOpenOptionsDialog()}
                >
                    <img src={gameData.thumbnail_image} alt=""/>
                    {
                        isCurrentlyPlaying &&
                        <div className="currently-playing-tag">
                            Currently Playing
                        </div>
                    }
                </div>  
            }   
            </div>
        }
        </>
    )
}