// react states
import { useState } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

export const useGameRequest = ()=>{
    const [ requesting, setRequesting ] = useState(false);
    const [ requestStatus, setRequestStatus ] = useState(null);
    const [ requestTicket, setRequestTicket ] = useState(null);
    const [ requestError, setRequestError ] = useState(null);

    const requestGame = async(gameName, requestingPersonID)=>{
        setRequesting(true);
        const requestRef = collection(db, 'Requests');
        const requestDoc = await addDoc(requestRef,{
            'gameName':gameName,
            'requestingPersonID': requestingPersonID,
            'createdAt':serverTimestamp(),
            'isResolved':false,
            'status':'submitted',
            'resolvedDate':null
        }).catch((requestErr)=>{
            setRequesting(false);
            switch(requestErr.message){
                case 'Missing or insufficient permissions.':
                    setRequestError('Opps, looks like you are not signed in.');
                    break;
                
                default: setRequestError(requestErr.message);
            }
            
            setRequestStatus('fail');
            return;
        });

        const requestTicket = requestDoc.id;
        if(requestTicket !== null){
            setRequestTicket(requestTicket);
        }

        setRequesting(false);
        setRequestStatus('success');
    }

    return { requesting, setRequestError, requestError, requestStatus, requestTicket,
             requestGame
    };
}