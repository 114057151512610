// react states
import { useState } from 'react';
// firebase
import { db } from '../Firebase/firebase';
import { addDoc, collection } from 'firebase/firestore';


export const useReporting = ()=>{
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [reporting, setReporting] = useState(false);

    const sendReportToAdmin = async(reportedID,reason,docType,itemID,itemLocation)=>{
        setReporting(true);

        await addDoc(collection(db, "Reports"), {
            'reporterID': reportedID,
            'reason':reason,
            'document_type':docType,
            'item_id':itemID,
            'item_location':itemLocation
        }).then(()=>{
            setError(null);
            setSuccess('The item is reported to the admins. Sit back and relax, we are working on it and will take appropriate actions as soon as possible.');
            setReporting(false);
        }).catch((err)=>{
            setError(err.message);
            setSuccess(null);
            setReporting(false);
            return;
        });
    }


    return { error, success, reporting, sendReportToAdmin };
}
