// styles
import TrendingReviewCarousel from './TrendingReviewCarousel/trendingReviewCarousel';
import './trendingReviewArea.css';

export default function TrendingReviewArea(){
    return(
        <div className="trending-review-area">
            <p className='trending-review-area-heading'>Trending reviews</p>
            <TrendingReviewCarousel/>
        </div>
    )
}