// react states
import { useState } from "react"
// firebase
import { db } from '../../Firebase/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// react-router-dom
import { useNavigate } from 'react-router-dom';

export const usePostDiscussion = ()=>{
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const [status, setStatus] = useState('inProgress');

    const forumNavigate = useNavigate();

    // 'type':<String>
    // 'title':<String>
    // 'description':<String>|<null>
    // 'descriptionMedia':<string>|<null>
    // 'spoilers':<boolean>
    // 'NSFW':<boolean>
    // 'createdAt':<Timestamp>
    // 'approved':<boolean></boolean>
    const postDiscussion = async (type, title, desc, spoilers, nsfw, userID, taggedGamesIDs)=>{
        setPending(true);
        const postRef = await addDoc(collection(db, "Forums"), {
            'type':type,
            'taggedGames':taggedGamesIDs,
            'title':title,
            'description':desc,
            'descriptionMedia':null,
            'spoilers':spoilers,
            'NSFW':nsfw,
            'createdAt':serverTimestamp(),
            'approved':true,
            'likes':0,
            'dislikes':0,
            'userID':userID
        }).catch((err)=>{
            setPending(false);
            setError(err.message);
            setStatus('Failed');
            return;
        });

        setPending(false);
        setStatus('Success');
        forumNavigate(`/discussion/${postRef.id}`);
    }


    return {error, pending, status, postDiscussion};
}