// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, onMessage } from 'firebase/messaging';
import { getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';


// THE GAMER BROS
const firebaseConfig = {
  apiKey: "AIzaSyDgs5g0Refl-ClHUt30cqLIRlqHE2sSHEY",
  authDomain: "thegamerbros-9204e.firebaseapp.com",
  projectId: "thegamerbros-9204e",
  storageBucket: "thegamerbros-9204e.appspot.com",
  messagingSenderId: "402471789542",
  appId: "1:402471789542:web:f32534d0d82ac043ca2c27",
  measurementId: "G-XNS8L5K1LS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const fbFunctions = getFunctions(app);
const websitePerformance = getPerformance(app);
const googleAuthProvider = new GoogleAuthProvider(app);
const facebookAuthProvider = new FacebookAuthProvider(app);

const projectMessagingKey = 'BC-ha8G4zh0TNNIvsQzgVMhEBlJPs_T0l9CimC64U6DWrMCAsas-9C_YgRPmuvGzUtjJq6ubE4f2nOaqwDOtjqw';

export const onMessageListener = async () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export { db, auth, storage, analytics, messaging, fbFunctions, projectMessagingKey, websitePerformance,
          googleAuthProvider, facebookAuthProvider
};