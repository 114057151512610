import { useState } from "react";
// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from '../Firebase/firebase';
// slices
// import { selectUserData } from '../Features/userPrivateDataSlice';
// react redux
// import { useSelector } from 'react-redux';

var userDataMap = {};

export const useGetUserProfile = ()=>{

    const [userData, setUserData] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false);

    const getProfileDetails = async(user_id,type)=>{
        // console.log('user_id => ',user_id);
        setLoadingUser(true);
        if(userDataMap[user_id] !== undefined){
            setUserData(userDataMap[user_id]);
            setLoadingUser(false);
            return;
        }
        else{
            setLoadingUser(true);
            // console.log('Loading some other users data');
            const userRef = doc(db, "Users", `${user_id}`);
            const userSnap = await getDoc(userRef);
    
            if(userSnap.exists()){
                // userDataMap[`${userSnap.id}`] = userSnap.data();
                setUserData(userSnap.data());
                setLoadingUser(false);
            }else{
                setLoadingUser(false);
            }
        }
        
    }
    // console.log('userDataMap => ',userDataMap);
    // console.log('got user data here => ',userData);


    return { getProfileDetails, userData, loadingUser };
}