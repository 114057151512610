/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './userSearchGameTile.css';
// custom hooks
import { useAddToProfile } from '../../../Hooks/GameUserInteraction/useAddToProfile';
import { CircularProgress } from '@mui/material';

export default function UserSearchGameTile({ gameInfo, collectionType }){

     // To add this game to user collection
    const { addingToProfile, addGameToUserCollection,
            addingToWishlist, addGameToUserWishlist
    } = useAddToProfile();
    // To remove game from user collections
    // const { removing, removingFromWishlist, removeFromUserCollection, removeFromUserWishlist } = useRemoveFromProfile();
    const handleAddToProfile = ()=>{
        if(collectionType === 'allGames'){
            addGameToUserCollection({ 'id':gameInfo.id });
        }

        if(collectionType === 'wishlist'){
            addGameToUserWishlist({ 'id': gameInfo.id })
        }
    }

    return(
        <>
        {
            gameInfo !== null &&
                <div className="user-search-game-tile">
                    <div className="user-search-game-tile-img-box">
                        <img src={gameInfo.thumbnail_image} alt="" />
                    </div>
                    <p>{gameInfo.name}</p>
                    <div className="add-game-to-collection-box-cta"
                         onClick={()=>handleAddToProfile()}
                    >
                    {
                        (addingToProfile || addingToWishlist) && <CircularProgress size={18} style={{ color:'#080808' }}/>
                    }
                    {
                        (addingToProfile === false && addingToWishlist === false) && 'Add'
                    }
                    </div>
                </div>
        }
        </>
    );
}