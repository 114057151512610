// react states
import { useEffect } from 'react';

const useIncreaseHeightDynamically = ( textAreaRef, textValue )=>{
    
    useEffect(()=>{
        if(textAreaRef){
            textAreaRef.style.height = '0px';
            const scrollHeight = textAreaRef.scrollHeight;
            textAreaRef.style.height = scrollHeight + "px";
        }
    },[ textAreaRef, textValue ])

}

export default useIncreaseHeightDynamically;