// react states
import React, { useEffect, useRef } from 'react';

import { banner_ad_source_1 } from './ad_sources';

export default function BannerAdComponent({ banner_key }){

    const adRef = useRef(null);
    
    useEffect(() => {

        const currentAdRef = adRef.current;

        // Define atOptions as a global variable
        window.atOptions = {
          'key': banner_key, // replace with actual key
          'format': 'iframe',
          'height': 90,
          'width': 728,
          'params': {}
        };
    
        // Create and append the ad script
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = banner_ad_source_1; // replace with actual path
        script.async = true;
        
        if (currentAdRef) {
            currentAdRef.appendChild(script); // Append script to the specific div
          }
      
        return () => {
            // Cleanup the script on component unmount
            if (currentAdRef) {
                currentAdRef.removeChild(script);
            }
        };
    }, [banner_key]);

    return (
        <div ref={adRef} style={{ width: '728px', height: '90px' }}>
          {/* Banner Ad Container */}
        </div>
    );
}