/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './user_login.css';
// custom component(s)
import LoginForm from './LoginFormComponent/loginForm';
// Assets
import tgb_logo from '../../../assets/Logo/TGB-LOGO.png';

export default function UserLogin(){

    const welcomeMessage = "The Gamer Bros";

    return(
        <div className="user-login-container">
            <div className="welcome-banner">
                <div className="welcome-banner-logo-container">
                    <img src={tgb_logo} alt="The Gamer Bros" />
                </div>
                <p className='login-welcome-msg'>{welcomeMessage}</p>
            </div>
            <LoginForm/>
        </div>
    )
}