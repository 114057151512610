/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './discussion_page.css';
// react-states
// import { useEffect } from 'react';
// react-router-dom
import { useParams } from 'react-router-dom';
// custom hooks
import { useDiscussionFetch } from '../../Hooks/Forums/useDiscussionFetch';
// custom components
import DiscussionTile from '../../Components/Forums/Discussion/discussionTile';
// import DiscussionInput from '../../Components/Forums/Discussion/discussionInput';
// react redux
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Features/userPrivateDataSlice';
import { selectUser } from '../../Features/userSlice';
import { useEffect, useState } from 'react';
// import { usePostComments } from '../../Hooks/Forums/usePostComments';
// import Comment from '../../Components/Forums/Discussion/comment';

// Testing components
import DiscussionArea from '../../Components/DiscussionAreaComponents/discussionArea';

export default function DiscussionPage(){

    const loggedInUserData = useSelector(selectUserData);
    const loggedInUserID = useSelector(selectUser);

    const { discussion_id } = useParams();
    // console.log(discussion_id);

    const { discussionTopic } = useDiscussionFetch(discussion_id);
    // // To fetch the parent comments
    // const { allComments, setAllComments, fetchComments } = usePostComments();
    // const [localComments, setLocalComments] = useState([]);

    // useEffect(()=>{
    //     fetchComments(allComments, null,discussion_id);
    // },[discussion_id])

    const [currentUserData, setCurrentUserData] = useState(null);
    useEffect(()=>{
        if(loggedInUserID!==null && loggedInUserData!==null){
            const userObj = {
                ...loggedInUserID,
                ...loggedInUserData
            }

            setCurrentUserData(userObj);
        }
    },[loggedInUserData, loggedInUserID])
    // console.log('All comments in parent => ',allComments);

    // To get the number of comments on the discussion
    const [discussionCommentCounter, setDicussionCommentCounter] = useState(0);
    // console.log(discussionCommentCounter);
    
    return(
        <div className="discussion-page-container">
            <div className='discussion-page-tile-container'>
            {
                discussionTopic && 
                <DiscussionTile discussionTopic={discussionTopic} currentUser={currentUserData}/>
            }
            </div>
            <div className="discussion-comments-area">
            {
                discussion_id !== null &&
                discussionTopic !== null &&
                <DiscussionArea discussionID={discussion_id} 
                                // gameID={discussionTopic.gameID}
                                taggedGames={discussionTopic.taggedGames}
                                section={'forum'}
                                commentsCounter={setDicussionCommentCounter}
                                fullDoc={discussionTopic}

                />
            }
            </div>
        </div>
    )
}