// styles
import { v4 as uuid } from 'uuid';
import { useFetchAllDiscussions } from '../../../Hooks/Forums/useFetchAllDiscussions';
import './all_forums.css';
import PrevDiscussionTile from './prev_discussion_tile';
import { CircularProgress } from '@mui/material';
// slices/ react redux
import { selectUser } from '../../../Features/userSlice';
import { useSelector } from 'react-redux';

// SEO
import { Helmet } from 'react-helmet';

export default function AllForums(){

    const { fetching, discussions, lastVisibleSnapshot, fetchNextBatchDiscussions } = useFetchAllDiscussions();
    const currentUserData = useSelector(selectUser);

    // console.log('fetching => ',fetching);
    // console.log('discussions => ',discussions);
    // console.log('lastVisibleSnapshot => ',lastVisibleSnapshot);

    const getMoreDiscussions = ()=>{
        fetchNextBatchDiscussions();
    }

    return(
        <div className='all-forums-container'>
        <Helmet>
            <title>Game Forums</title>
            <meta name='description' content={'Gaming Forums'}/>
            <meta name='keywords' content={'Gamers, Gaming, Gaming forums, forums, discussion, game discussions'}/>
        </Helmet>
        <div className="discussion-preview-tile-container">
        {
            discussions && discussions.length > 0
            && discussions.map((discussion)=>(
                <PrevDiscussionTile key={uuid()} discussion={discussion} currentUser={currentUserData}/>
            ))
        }
        {
            fetching && 
            <div className="fetching-prompt">
                Fetching discussions...
                <CircularProgress size={24} sx={{ color:'#FFE74C' }}/>
            </div>
        }
        </div>
        {
            !fetching && lastVisibleSnapshot && 
            <div className="load-more-discussions-cta"
                 onClick={()=>getMoreDiscussions()}
                 onKeyDown={()=>getMoreDiscussions()}
            >
                Load more
            </div>
        }    
        </div>
    )
}