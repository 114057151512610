// styles
import './mainGameGallery.css';
// React states
import React from 'react';
// custom hooks
import { useFetchGamePics } from '../../../Hooks/GameData/useFetchGamePics';
import MainGameGalleryCarousel from './GalleryCarousel/mainGameGalleryCarousel';

const MainGameGallerySection = React.memo(({ gameID }) => {

    const { data: gamePics, isLoading } = useFetchGamePics(gameID);

    if(isLoading){
        return <p>Loading...</p>
    }

    if(gamePics.length === 0){
        return <p>No pictures have yet been added to this game.</p>
    }

    return(
        <div className="main-game-gallery-container">
        {
            gamePics && gamePics.length > 0 &&
                <MainGameGalleryCarousel items={gamePics}/>
        }
        </div>
    )
});

export default MainGameGallerySection;