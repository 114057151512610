// styles
import './nextArticleBtn.css';
// mui component(s)
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function NextArticleButton({ articleInfo }){

    return(
        <>
        {
            articleInfo !== undefined && articleInfo !== null &&
            <button
                className='next-article-cta'
            >
                <a href={`/articles/${articleInfo.id}`}>
                    <div className="next-article-cta-texts">
                        <p className='next-article-cta-heading'>You may also like</p>
                        <p className='next-article-cta-text'>{articleInfo.titleText}</p>
                    </div>
                    <ArrowForwardIcon className='forward-icon-cta' size={28} />
                </a>
            </button>
        }
        </>
    )
}