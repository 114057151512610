// styles
import './mainGameGalleryCarousel.css';
// For carousel
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';

import { Autoplay, Pagination, Navigation, EffectCoverflow } from 'swiper/modules';

export default function MainGameGalleryCarousel({ items }){
    return(
        <div className="main-game-gallery-carousel">
            <Swiper
                effect="coverflow"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView="auto"
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation, EffectCoverflow]}
            >
            {
                items.map((item, index)=>(
                    <SwiperSlide key={index}>
                        <div className="main-game-gallery-carousel-slide">
                            <img src={item.image} alt=""/>
                        </div>
                    </SwiperSlide>
                ))
            }
            </Swiper>
        </div>
    );
}