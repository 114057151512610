/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './userWishlistComponent.css';
// react states
import { useEffect, useState } from 'react';
// react-redux
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Features/userPrivateDataSlice';
// custom hooks
import { useGetUserGameCollection } from '../../Hooks/UserProfile/useGetUserGameCollection';
import UserWishlistTile from './UserWishlistComponents/userWishlistTile';
import AddGamesToCollection from './AddingGames/addGamesToCollection';
import { Helmet } from 'react-helmet';

export default function UserWishlist({ userProfileID, loggedInUserID, setTotalWishlistGamesCounter, userProfileData }){

    const loggedInUserData = useSelector(selectUserData);
    // console.log(loggedInUserData);

    const [ gameWishlist, setGameWishlist ] = useState([]);

    // custom hook to just get the game collection of user if its not their profile
    const { fetchingCollection, userWishlistCollection, getGameCollection
    } = useGetUserGameCollection();

    useEffect(()=>{
        if(userProfileID === loggedInUserID){
            if(JSON.stringify(loggedInUserData) !== '{}'){
                setGameWishlist(loggedInUserData.wishlist);
                setTotalWishlistGamesCounter((loggedInUserData && loggedInUserData.wishlist) ? loggedInUserData.wishlist.length : 0);
            }
        }else if(userProfileID !== loggedInUserID){
            // Get the game collection details of user here.
            getGameCollection(userProfileID);
        }
    },[userProfileID, loggedInUserID, loggedInUserData])

    useEffect(()=>{
        if(userWishlistCollection.length > 0){
            setGameWishlist(userWishlistCollection);
            setTotalWishlistGamesCounter(userWishlistCollection.length);
        }
    },[userWishlistCollection]);

    // console.log('user-wishlist => ',gameWishlist);

    const [openAddGameWishlistPrompt, setOpenAddGameWishlistPrompt] = useState(false);
    

    return(
        <div className='user-wishlist-container' id='user_wishlist'>
            {
                userProfileData !== null &&
                <Helmet>
                    <title>{`${userProfileData.Name} - Wishlist`}</title>
                    <meta name='description' content={`${userProfileData.Name} all wishlist games`}/>
                    <meta name='keywords' content={`${userProfileData.Name}, ${userProfileData.Name} wishlist, games, collection, games collection, wishlist collection, all games, add games, add to wishlist, like game collection`}/>
                </Helmet>
            }
            {/* <UserGameSelectionDialog
                openDialog={openAddGameWishlistPrompt}
                setOpenDialogPrompt={setOpenAddGameWishlistPrompt}
                userCollectionArray={gameWishlist}
                collectionType={'wishlist'}
            /> */}
            <AddGamesToCollection 
                openDialog={setOpenAddGameWishlistPrompt}
                openGameCollection={openAddGameWishlistPrompt}
                collectionType={'wishlist'}
                userCollectionArray={gameWishlist}
            /> 
            <div className="user-wishlist-actions">
            {
                userProfileID === loggedInUserID
                && 
                <button className="add-game-to-wishlist-cta"
                     onClick={()=>setOpenAddGameWishlistPrompt(true)}
                >
                    Add games
                </button>
            }    
            </div>
            <div className="user-wishlist-box">
            {
                fetchingCollection === false &&
                gameWishlist &&
                gameWishlist.length > 0 &&
                gameWishlist.map((wishlistedGame)=>(
                    <UserWishlistTile key={wishlistedGame} 
                                      gameID={wishlistedGame}
                                      userProfileID={userProfileID}
                                      loggedInUserID={loggedInUserID} 
                    />
                ))
            }
            {
                fetchingCollection === false &&
                gameWishlist.length === 0 &&
                <div className="wishlist-message-screen">
                {
                    userProfileID === loggedInUserID
                    && <p>You haven't added any games to your wishlist. Start doing it now.</p>
                }
                {
                    userProfileID !== loggedInUserID
                    && <p>{`No games added to wishlist yet.`}</p>
                }
                </div>
            }   
            </div>
        </div>
    )
}

// 2147483647 