// styles
import './user_review_write_text_input.css';
// react states
import { useRef, useEffect } from 'react';

export default function UserReviewWriteTextInput({ userReviewText, setUserReviewText }){
    
    const handleUserReviewTextChange = (e)=>{
        setUserReviewText(e.target.value);
    }

    // To auto increase the size
    const userRevInputTextRef = useRef(null);
    useEffect(() => {
        if (userRevInputTextRef.current) {
            userRevInputTextRef.current.style.height = 'auto';
            userRevInputTextRef.current.style.height = `${userRevInputTextRef.current.scrollHeight}px`;
        }
    }, [userReviewText]);

    return(
        <textarea className='user-review-write-text-input'
               ref = {userRevInputTextRef}
               type="text" 
               placeholder='Write review here...'
               value={userReviewText}
               onChange={(e)=>handleUserReviewTextChange(e)}
        />
    );
}