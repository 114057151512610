// styles
import './data_deletion_page.css';

export default function DataDeletionPage(){
    return(
        <div className="data-deletion-page">
            <div className="instruction-area">
                <p className='instruction-text'>
                    To request the deletion of your data collected via Facebook login, please email us at
                        <strong> thegamerbros.tgb.2022@gmail.com </strong> 
                    with the subject 
                    <strong> "Facebook Data Deletion Request" </strong> 
                    and include your <strong> Facebook user ID </strong>. 
                    We will process your request within <strong> 30 days </strong>.
                </p>
            </div>
        </div>
    )
}