// styles
import './trendingReviewLoading.css';

export default function TrendingReviewLoading(){
    return(
        <div className="trending-review-loading-tile">
            <div className="trending-rev-game-cover-loading"></div>
            <div className="review-texts-loading">
                <div className="review-user-pfp-loading">
                    <div className="review-user-pic-loading"></div>
                    <div className="review-user-usernmame-loading"></div>
                    <div className="review-date-loading"></div>
                </div>
                <div className="review-main-text-loading"></div>
                <div className="review-action-btns-section-loading">
                    <div className="review-action-btn-loading"></div>
                </div>
            </div>
        </div>
    )
}