// styles
import './gameRequestDialog.css';
// react states
import { useState, useEffect } from 'react';
// react router dom
import { useNavigate } from 'react-router-dom';
// react redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Features/userSlice';

export default function GameRequestDialog({ inputText }){
    
    const userSignedIn = useSelector(selectUser);

    const [showGameNotAvailable, setShowGameNotAvailable] = useState(false);
    useEffect(()=>{
        if(inputText !== '' || inputText.length > 0){
            setShowGameNotAvailable(true);
        }
    },[inputText]);

    // To navigate to requesting a game
    const searchNav = useNavigate();
    const handleRequestNavigation = ()=>{
        if(userSignedIn === null){
            searchNav('/user_auth')
        }else{
            searchNav('/game_request');
        }
    }
    
    return(
        <>
        {
            showGameNotAvailable === true &&
            <div className="request-game-dialog">
                <p className='request-game-dialog-text'>Couldn't find what you were looking for?</p>
                <div className='send-request-cta'
                    onClick={()=>handleRequestNavigation()}
                >
                    Request a game
                </div>
            </div>
        }
        </>
    );
}