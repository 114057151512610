/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './signInLandingPage.css';
// react states
import { useEffect, useState } from 'react';
// react router dom
import { useNavigate } from 'react-router-dom';
// firebase auths
import { auth } from '../../Firebase/firebase';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
// mui components
import { Button, CircularProgress, TextField } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function FinishSignIn(){

    const signinnav = useNavigate();

    const [signInFeedback, setSignInFeedback] = useState('Please wait! We are signing you in...');
    // To show the email entering prompt or not.
    const [showInputs, setShowInputs] = useState(false);
    // If we need to get the input, for storing that input.
    const [mailInput, setMailInput] = useState('');
    // To show that the progress is happening
    const [showProgress, setShowProgress] = useState(false);

    const signInWithTheLink = async( email )=>{
        setShowProgress(true);
        try {
            await signInWithEmailLink(auth, email, window.location.href);
            // Now since the sign in is done, remove the email from local storage for security.
            window.localStorage.removeItem("emailForSignIn");
            // Show the message.
            setSignInFeedback('Sign in successfull!!');
            // Navigate the user to their profile.
            if(auth.currentUser !== null){
                setShowProgress(false);
                signinnav(`/user_profile/${auth.currentUser.uid}`);
            }else{
                setShowProgress(false);
                signinnav('/');
            }
        } catch (error) {
            // Catch the error, show the message and take them back to user auth.
            setSignInFeedback('Something went wrong. Might be that the link expired. Please try again.');
            setShowProgress(false);
            signinnav('/user_auth');
            return;
        }
    }

    useEffect(()=>{
        // Start the progress.
        setShowProgress(true);
        // start verifying the link.
        const verifyTheLink = async()=>{
            if(isSignInWithEmailLink(auth, window.location.href)){
                // Get the email you stored to cross refer here...
                let userEmail = window.localStorage.getItem("emailForSignIn");
                // If email doesn't exists, prompt the user to enter the mail.
                if(!userEmail){
                    setShowInputs(true);
                    setShowProgress(false);
                }else{
                    // Now since we are sure that we've obtained the email, sign the user in now.
                    await signInWithTheLink(userEmail);
                    setShowProgress(false);
                }
            }
        }

        // call the verification method.
        verifyTheLink();
    },[])

    return(
        <div className="finish-sign-in-page">
        <p>Just finishing up... Your adventure with The Gamer Bros begins shortly...</p>
        <p>{signInFeedback}</p>
        {
            showInputs &&
            <div className="sign-in-email-prompt">
                {
                    showProgress === false && 
                    <>
                        <TextField
                            required
                            variant='outlined' 
                            sx={{ 'width':'100%', '& .MuiOutlinedInput-root': {
                                    color: '#f8f8f8', // Text color
                                    '& fieldset': {
                                        borderColor: '#9e9e9e', // Outline color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#f8f8f8', // Outline color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#f8f8f8', // Outline color when focused
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#9e9e9e', // Label color
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#f8f8f8', // Label color when focused
                                },
                                }}
                            label='Please provide the email for confirmation'
                            value={mailInput}
                            onChange={(e)=>setMailInput(e.target.value)}
                        />
                    
                        <Button variant='contained'
                            sx={{ width:'100%', height:'45px', backgroundColor:'#ffe74c', color:'#080808', 
                                borderRadius:'5px 5px 5px 5px',
                                '&:hover': {
                                    backgroundColor: '#fff74c', // Hover color
                                },
                            }}
                            endIcon={<ArrowForwardIcon/>}
                            onClick={()=>{
                                if(mailInput.length === 0)
                                    return;
                                else
                                    signInWithTheLink(mailInput)
                            }}
                        >
                            Continue
                        </Button>
                    </>
                }
            </div>
        }
        {
            showProgress === true && <CircularProgress size={24} sx={{ 'color':'#FFE74C' }}/>
        }
        </div>
    )
}