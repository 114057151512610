import { useState } from 'react';

// firebase
import { db } from '../../Firebase/firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../Features/userSlice';
import { addGameToWishlist, addGamesToUser, addToCurrentlyPlaying } from '../../Features/userPrivateDataSlice';

export const useAddToProfile = ()=>{
    const [addingToProfile, setAddingToProfile] = useState(false);
    const [addingToWishlist, setAddingToWishlist] = useState(false);
    const [addingToCP, setAddingToCP] = useState(false);
    // const [addingStatus, setAddingStatus] = useState(null);
    
    const signedInUser = useSelector(selectUser);
    const gameCollectionDispatch = useDispatch();

    // console.log('All games in user collection => ',allUserCollectionGames);

    const addGameToUserCollection = async(game)=>{
        if(signedInUser === null){
            return;
        }else{
            setAddingToProfile(true);
            const gameCollectionRef = doc(db, `Users/${signedInUser.uid}/PrivateInfo`,'userInfo');
            await updateDoc(gameCollectionRef, {
                'game_collection':arrayUnion(game.id)
            }).catch((err)=>{
                setAddingToProfile(false);
                // setAddingStatus(false);
                console.log(err);
            }).then(()=>{
                gameCollectionDispatch(addGamesToUser({ 
                    'userID':signedInUser.uid, 
                    'gameID':game.id
                }));

                setAddingToProfile(false);
                // setAddingStatus(true);
            })
        }
    }

    const addGameToCurrentlyPlaying = async(game)=>{
        if(signedInUser === null){
            return;
        }else{
            setAddingToCP(true);
            const gameCollectionRef = doc(db, `Users/${signedInUser.uid}/PrivateInfo`,'userInfo');
            await updateDoc(gameCollectionRef, {
                'currently_playing':arrayUnion(game.id)
            }).catch((err)=>{
                setAddingToCP(false);
                // setAddingStatus(false);
                console.log(err);
            }).then(()=>{
                gameCollectionDispatch(addToCurrentlyPlaying({ 
                    'userID':signedInUser.uid, 
                    'gameID':game.id
                }));

                setAddingToCP(false);
                // setAddingStatus(true);
            })
        }
    }

    const addGameToUserWishlist = async(game)=>{
        if(signedInUser === null){
            return;
        }else{
            setAddingToWishlist(true);
            const gameCollectionRef = doc(db, `Users/${signedInUser.uid}/PrivateInfo`,'userInfo');
            await updateDoc(gameCollectionRef, {
                'wishlist':arrayUnion(game.id)
            }).catch((err)=>{
                setAddingToWishlist(false);
                // setAddingStatus(false);
                console.log(err);
            }).then(()=>{
                gameCollectionDispatch(addGameToWishlist({ 
                    'userID':signedInUser.uid, 
                    'gameID':game.id
                }));

                setAddingToWishlist(false);
                // setAddingStatus(true);
            })
        }
    }

    return { addingToProfile, addingToWishlist, addingToCP, addGameToUserCollection,
             addGameToUserWishlist, addGameToCurrentlyPlaying
    };
}