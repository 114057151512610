// styles
import './consoleMenu.css';
// react router dom
import { useNavigate } from 'react-router-dom';
// Custom hooks
import { useGetAllConsoles } from '../../../Hooks/Consoles/useGetAllConsoles';
// Material UI
import { CircularProgress, Menu, MenuItem, Button } from '@mui/material';

export default function ConsoleMenuDropdown({ consoleMenuAnchor, setConsoleMenuAnchor }){
    const { allConsoleData, loadingAllConsoles } = useGetAllConsoles();

    const handleCloseMenuDropdown = ()=>{
        setConsoleMenuAnchor(null);
    }

    const consoleMenuNav = useNavigate();
    const navigateToConsolePage = (console_id)=>{
        if(console_id !== null && console_id !== undefined){
            handleCloseMenuDropdown();
            consoleMenuNav(`/consoles/${console_id}`);
        }
    }

    return(
        <Menu anchorEl={consoleMenuAnchor}
              open={Boolean(consoleMenuAnchor)}
              onClose={()=>handleCloseMenuDropdown()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 0.5,
                horizontal: 0,
              }}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#191917",
                  color:'#f8f8f8',
                  padding:'2%',
                  height:'auto',
                  width:'85%'
                }
              }}
        >
        {
            loadingAllConsoles && 
            <div className="console-menu-loading">
                <CircularProgress sx={{ color:'#f8f8f8' }}/>
                <p>Loading all consoles...</p>
            </div>
        }
        {
            !loadingAllConsoles && allConsoleData &&
            <div className="console-menu-data">
            {
                allConsoleData.map((console)=>(
                    <MenuItem key={console.id} onClick={()=>navigateToConsolePage(console.id)}>
                        <Button sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    textTransform: 'none',
                                    width: '100%',
                                    padding: '10px 20px',
                                    color: 'white',
                                }}
                        >
                            <img src={console.pictures[0]} alt={console.acronym} style={{ width: 35, height: 35, marginRight: 10 }}/>
                            <p>{console.acronym}</p>
                        </Button>
                    </MenuItem>
                ))
            }
            </div>
        }
        </Menu>
    );
}