// styles
import './forum_home_page.css';
// react states
import { useState } from 'react';
// Custom components
import { CreateDiscussion } from '../../Components/Forums/CreateDiscussion/createDiscussion';
import { selectUser } from '../../Features/userSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AllForums from '../../Components/Forums/AllForums/all_forums';

export default function ForumHomePage(){

    // To get the current user data
    const current_user = useSelector(selectUser);

    const authNavigate = useNavigate();

    const forumComponents = [
        <CreateDiscussion key={0} signedInUser={current_user}/>,
        <AllForums key={1}/>
    ];

    const [activeComp, setActiveComp] = useState(1);
    
    const toggleComponent = (compIndex) =>{
        if(compIndex === 0){
            if(current_user === null){
                authNavigate('/user_auth');
            }else{
                setActiveComp(compIndex);
            }
        }else{
            setActiveComp(compIndex);
        }
    }

    return(
        <div className='forum-homepage-container'>
            <div className="all-forums-heading">
                <h4 onClick={()=>toggleComponent(1)}
                    onKeyDown={()=>toggleComponent(1)}
                    style={{ 'cursor':'pointer' }}
                >
                    Forums
                </h4>
                <div className="forum-action-container">
                    <button className='new-discussion-cta'
                            onClick={()=>toggleComponent(0)}
                    >
                        New Discussion
                    </button>
                </div>
            </div>
            <div className="active-forum-component">
            {
                activeComp === 0 && forumComponents[0]
            }
            {
                activeComp === 1 && forumComponents[1]
            }
            </div>
        </div>
    )
}