// styles
import './gameProfileGenres.css';
// react router dom
import { useNavigate } from 'react-router-dom';

export default function GameProfileGenre({ gameGenreDetails }){
    
    // To navigate to the console page
    const gameGenreNavigate = useNavigate();
    const handleConsoleNavigation = ()=>{
        gameGenreNavigate(`/genres/${gameGenreDetails.id}`);
    }

    return(
        <p className='game-genre-cta'
                onClick={()=>handleConsoleNavigation()}
        >
            {gameGenreDetails.name}
        </p>
    )
}