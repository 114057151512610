/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './articleReadPage.css';
// react states
import { useState, useEffect } from 'react';
// react router dom
import { useParams, useNavigate } from 'react-router-dom';
// custom hook(s)
import { useGetArticleData } from '../../Hooks/Articles/useGetArticleData';
import { useFetchMultipleGameData } from '../../Hooks/GameData/useFetchMultipleGameData';
import { useGetMultipleConsoles } from '../../Hooks/Consoles/useGetMultipleConsoles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArticles, selectAllArticles } from '../../Features/articleSlice';
// custom component(s)
import NextArticleButton from '../../Components/ArticleComponents/nextArticleBtn';
// For SEO
import { Helmet } from 'react-helmet';
// Sharing logos
import fbLogo from '../../assets/Logo/Facebook Brand Asset Pack/Logo/Primary Logo/Facebook_Logo_Primary.png'
import xLogo from '../../assets/Logo/X-Logo/logo-white.png';
import linkedLogo from '../../assets/Logo/LinkedIn-Logo/LI-In-Bug.png';
import redditLogo from '../../assets/Logo/Reddit_Icon_2Color@2x.png';
import whatsappLogo from '../../assets/Logo/WhatsApp-Brand-Resource-Center/01_Glyph/01_Digital/03_PNG/Green/Digital_Glyph_Green.png';

export default function ArticleReadPage(){

    const { article_id } = useParams();

    // For SEO and cononicalising the url for search indexing by different
    // search platforms
    const canonical_article_url = 'https://thegamerbroz.com/articles/';

    // To get the article data
    const { articleData } = useGetArticleData(article_id);
    // console.log(articleData);

    // To fetch all article data
    const articleEventDispatch = useDispatch();
    const { articles } = useSelector(selectAllArticles);
    useEffect(()=>{
        if(articles.length === 0){
            articleEventDispatch(fetchArticles({
                'batchLimit':25,
                'startAfterVisible':null
            }));
        }
    },[articles.length, articleEventDispatch]);

    // Utility function for obtining random index for the article
    function getRandomInt(min, max) {
        min = Math.ceil(min);  // Inclusive lower bound
        max = Math.floor(max);  // Exclusive upper bound
        return Math.floor(Math.random() * (max - min)) + min;
    }
    // To fetch the next article.
    const [nextArticle, setNextArticle] = useState(null);
    useEffect(()=>{
        if(articles.length > 0){
            var randomIndex = getRandomInt(0, articles.length);

            while(true){
                if(articles[randomIndex].id !== article_id){
                    setNextArticle(articles[randomIndex]);
                    break;
                }else{
                    randomIndex = getRandomInt(0, articles.length);
                }
            }
        }
    },[article_id, articles])

    // Once we get the article data, we now start with the game fetching 
    // and console fetching.
    // Tp get the relevant games
    const { multipleGameData, fetchMultipleGameData } = useFetchMultipleGameData();
    
    // To get the relevant consoles
    const { multipleConsoleData, getMultipleConsoles } = useGetMultipleConsoles();

    //To get the date in the format wanted.
    const [articleDate, setArticleDate] = useState(null); 

    useEffect(()=>{
        if(articleData !== undefined && articleData !== null){
            fetchMultipleGameData(articleData.relevantGames);
            getMultipleConsoles(articleData.relevantConsoles);
            
            // Timestamp conversion to date format desired.
            const date = new Date(articleData.createdAt.seconds * 1000 + articleData.createdAt.nanoseconds / 1000000);
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            const formattedDate = date.toLocaleDateString('en-US', options);
            setArticleDate(formattedDate);
        }
    },[articleData])

    // For navigation events
    const articleReadNav = useNavigate();
    // To navigate user to the relevant on click event
    const handleNavigateToGame = ( game_id )=>{
        articleReadNav(`/game_page/${game_id}`)
    }
    // To navigate user to the console page on click event
    const handleNavigateToConsole = ( console_id )=>{
        articleReadNav(`/consoles/${console_id}`)
    }

    const sharingUrl = `https://thegamerbroz.com/articles/${article_id}`;
    const encodedUrl = encodeURIComponent(sharingUrl);
    const shareText = encodeURIComponent(articleData ? articleData.titleText : 'Check out this page!');
    const shareTextWhatsapp = encodeURIComponent(articleData ? (articleData.titleText + "\n" + sharingUrl) : ('check out this page!\n'+sharingUrl));


    const [bannerImg, setBannerImg] = useState(null);
    useEffect(()=>{
        if(multipleGameData.length > 0 && multipleConsoleData.length > 0){
            setBannerImg(multipleGameData[0].cover_image_url);
        }else if(multipleGameData.length === 0 && multipleConsoleData.length > 0){
            setBannerImg(multipleConsoleData[0].pictures[1]);
        }else if(multipleGameData.length > 0 && multipleConsoleData.length === 0){
            setBannerImg(multipleGameData[0].cover_image_url);
        }
    },[multipleConsoleData, multipleGameData])

    return(
        <div className="article-read-page">
            <Helmet>
                {articleData && <title>{articleData.titleText}</title>}
                <link rel="canonical" href={canonical_article_url}/>
                <meta name='tagline' content={'For the gamers. By the gamers.'}/>
                {articleData && <meta property="og:description" content={`${articleData.titleText}`}/>}
                {
                    (multipleGameData.length > 0 && multipleConsoleData.length === 0) &&  
                    <meta property="og:image" content={multipleGameData[0].cover_image_url} />
                }
                {
                    multipleGameData.length === 0 && multipleConsoleData.length > 0 && 
                    <meta property="og:image" content={multipleConsoleData[0].pictures[1]} />
                }
                {
                    multipleGameData.length > 0 && multipleConsoleData.length > 0 && 
                    <meta property="og:image" content={multipleGameData[0].cover_image_url} />
                }
                <meta property="og:url" content={sharingUrl} />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="article-read-ad-component-1">
            </div>
            <div className="main-article-read-area">
                <div className="article-banner-container">
                {
                    bannerImg !== null && bannerImg !== undefined && articleData !== null &&
                    <div className="article-banner-bg-container">
                        <img src={bannerImg} alt={articleData.titleText} />
                    </div>
                    // multipleGameData && multipleConsoleData &&
                    // <div className="article-banner-bg-container">
                    // {
                    //     (multipleGameData.length > 0 && multipleConsoleData.length === 0) && 
                    //     <img src={multipleGameData[0].cover_image_url} alt={multipleGameData[0].name} />
                    // }
                    // {
                    //     (multipleGameData.length === 0 && multipleConsoleData.length > 0) && 
                    //     <img src={multipleConsoleData[0].pictures[1]} alt={multipleConsoleData[0].name} />
                    // }
                    // {
                    //     (multipleGameData.length > 0 && multipleConsoleData.length > 0) && 
                    //     <img src={multipleGameData[0].cover_image_url} alt={multipleGameData[0].name} />
                    // }
                    // </div>
                }
                    <div className="article-banner-overlay">
                        {
                            articleData && articleData.title !== null
                            && 
                            <div className='article-banner-title'
                                dangerouslySetInnerHTML={{
                                    __html:articleData.title
                                }}
                            ></div>
                        }
                        { articleDate && <p className='article-banner-date'>{articleDate}</p> }
                    </div>
                </div>
                <div className="article-sharing-area">
                    <p className='article-sharing-text'>Share on : </p>
                    
                    <a className='article-sharing-a' 
                       href={`https://api.whatsapp.com/send?text=${shareTextWhatsapp}`}
                       target='_blank'
                       rel='noopener noreferrer'
                    >
                        <div className="article-fb-share-container">
                            <img src={whatsappLogo} alt='Share on Whatsapp' />
                        </div>
                    </a>

                    <a className='article-sharing-a' 
                       href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                       target='_blank'
                       rel='noopener noreferrer'
                    >
                        <div className="article-fb-share-container">
                            <img src={fbLogo} alt='Share on facebook' />
                        </div>
                    </a>

                    <a className='article-sharing-a' 
                       href={`https://twitter.com/intent/tweet?text=${shareText}&url=${encodedUrl}`}
                       target='_blank'
                       rel='noopener noreferrer'
                    >
                        <div className="article-fb-share-container">
                            <img src={xLogo} alt='Share on twitter(X)' />
                        </div>
                    </a>

                    <a className='article-sharing-a' 
                       href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${shareText}`}
                       target='_blank'
                       rel='noopener noreferrer'
                    >
                        <div className="article-linked-in-share-container">
                            <img src={linkedLogo} alt='Share on LinkedIn' />
                        </div>
                    </a>

                    <a className='article-sharing-a' 
                       href={`https://www.reddit.com/submit?url=${encodedUrl}&title=${shareText}`}
                       target='_blank'
                       rel='noopener noreferrer'
                    >
                        <div className="article-fb-share-container">
                            <img src={redditLogo} alt='Share on Reddit' />
                        </div>
                    </a>
                </div>
                {
                    articleData && articleData.description !== null 
                    && articleData.description !== undefined && 
                    <div className="article-para-texts"
                        dangerouslySetInnerHTML={{
                            __html:articleData.description
                        }}
                    ></div>
                }
            </div>
            <div className="article-relevant-items-area">
                <div className="article-read-ad-component-2">
                </div>
                <div className="article-relevant-games-consoles-area">
                {
                    multipleGameData && multipleGameData.length > 0 &&
                    <>
                    <p className='article-rel-games-heading'>Relevant Games</p>
                    <div className="article-read-relevant-games-area">
                    {
                        multipleGameData.map((game)=>(
                            <div key={game.id} className="article-rel-game-tile"
                                 onClick={()=>handleNavigateToGame(game.id)}
                            >
                                <img src={game.thumbnail_image} alt={game.name}/>
                            </div>
                        ))
                    }
                    </div>
                    </>
                }
                <div className="article-read-space-div"></div>
                <div className="article-read-space-div"></div>
                {
                    multipleConsoleData && multipleConsoleData.length > 0 &&
                    <>
                    <p className='article-rel-games-heading'>Relevant Consoles</p>
                    <div className="article-read-relevant-consoles-area">
                    {
                        multipleConsoleData.map((console)=>(
                            <div key={console.id} className="article-rel-console-tile"
                                 onClick={()=>handleNavigateToConsole(console.id)}
                            >
                                <img src={console.pictures[0]} alt={console.name}/>
                            </div>
                        ))
                    }
                    </div>
                    </>
                }
                </div>
                <div className="article-options-div">
                    <NextArticleButton articleInfo={nextArticle}/>
                </div>
            </div>
            
        </div>
    );
}