/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useEffect, useState } from 'react';
// react router dom
import { useNavigate } from 'react-router-dom';
// custom hooks/components
import { useReactions } from '../../Hooks/useReactions';
import { useDiscussionOperations } from '../../Hooks/Forums/useDiscussionOperations';
// to send out notifications
import { sendNotifications } from '../../Firebase/fb-messaging';
import { useSendEmailNotifications } from '../EmailNotifications/useSendEmailNotifications';

export const useGiveReactions = (type, document_id, current_user_id)=>{
    
    // FOR REVIEW REACTIONS
    const { userReaction, reviewLike, reviewDislike, totalLike, totalDislike } = useReactions( document_id, current_user_id, '');

    // FOR DISCUSSION REACTIONS
    const { currentLikes, currentDislikes, userForumReaction
        , likeDiscussion, dislikeDiscussion
      } = useDiscussionOperations(document_id, current_user_id!==null ? current_user_id : null);

    // A stack to mantain the last reactions of the user
    const [prevReaction, setPrevReaction] = useState([]);
    const [tempLikes, setTempLikes] = useState(type === 'review' ? totalLike : currentLikes);
    const [tempDislikes, setTempDislikes] = useState(type === 'review' ? totalDislike : currentDislikes);

    // set reactions on change.
    useEffect(()=>{
        if(type === 'review')
            setPrevReaction(userReaction);
        else if(type === 'discussion')
            setPrevReaction(userForumReaction);
            // console.log('Getting users previous given reactions');
    },[userReaction, userForumReaction, type])

    useEffect(()=>{
        if(type === 'review'){
            setTempLikes(totalLike);
            setTempDislikes(totalDislike);
        }
        else if(type === 'discussion'){
            setTempLikes(currentLikes);
            setTempDislikes(currentDislikes);
        }
        // console.log('Getting post likes and dislikes');
    },[type, document_id, totalLike, totalDislike, currentLikes, currentDislikes]);

    // for navigation
    const reactionNavigate = useNavigate();

    // FOR EMAIL NOTIFICATIONS
    const { makeEmailHTML } = useSendEmailNotifications();

    const handleClickLike = (currentDoc)=>{
        if(current_user_id === null){
            reactionNavigate('/user_auth');
            return;
        }
        else{
            if(type==='review')
                reviewLike(document_id, current_user_id);
            else if(type==='discussion')
                likeDiscussion(document_id, current_user_id);

            var tempStack = prevReaction;
            if (prevReaction.length === 0) {
                let currentLike = tempLikes;
                currentLike += 1;
                setTempLikes(currentLike);
                tempStack.unshift("L");
                setPrevReaction(tempStack);

                if(type === 'review'){
                    sendNotifications(
                        current_user_id,
                        currentDoc.userID,
                        'review',
                        `https://thegamerbroz.com/full_review/${currentDoc.id}`,
                        'like','review',
                        currentDoc.id
                    );
                    makeEmailHTML('like','review',currentDoc.gameID,`https://thegamerbroz.com/full_review/${currentDoc.id}`, currentDoc);
                }else if(type === 'discussion'){
                    sendNotifications(
                        current_user_id,
                        currentDoc.userID,
                        'discussion',
                        `https://thegamerbroz.com/discussion/${currentDoc.id}`,
                        'like','discussion',
                        currentDoc.id
                    );
                    makeEmailHTML('like','discussion',currentDoc.gameID,`https://thegamerbroz.com/discussion/${currentDoc.id}`, currentDoc);
                }
            } else if (prevReaction.length > 0 && prevReaction[0] === "L") {
                // console.log("You have already liked");
                tempStack.shift();
                let currentLikes = tempLikes;
                currentLikes -= 1;
                setTempLikes(currentLikes);
                setPrevReaction(tempStack);
            } else if (prevReaction.length > 0 && prevReaction[0] === "D") {
                // console.log("You previously disliked");
                tempStack.shift();
                let currentLikes = tempLikes;
                let currentDislikes = tempDislikes;
                currentDislikes -= 1;
                currentLikes += 1;
                setTempLikes(currentLikes);
                setTempDislikes(currentDislikes);
                tempStack.push("L");
                setPrevReaction(tempStack);

                if(type === 'review'){
                    console.log('I am in review type and going to call send notification function')
                    sendNotifications(
                        current_user_id,
                        currentDoc.userID,
                        'review',
                        `https://thegamerbroz.com/full_review/${currentDoc.id}`,
                        'like','review',
                        currentDoc.id
                    );
                    makeEmailHTML('like','review',currentDoc.gameID,`https://thegamerbroz.com/full_review/${currentDoc.id}`,currentDoc);
                }else if(type === 'discussion'){
                    sendNotifications(
                        current_user_id,
                        currentDoc.userID,
                        'discussion',
                        `https://thegamerbroz.com/discussion/${currentDoc.id}`,
                        'like','discussion',
                        currentDoc.id
                    );
                    makeEmailHTML('like','discussion',currentDoc.gameID,`https://thegamerbroz.com/discussion/${currentDoc.id}`, currentDoc);
                }
            }
        }
    }

    const handleClickDislike = ()=>{
        if(current_user_id === null){
            reactionNavigate('/user_auth');
            return;
        }
        else{
            if(type === 'review')
                reviewDislike(document_id, current_user_id);
            else if(type === 'discussion')
                dislikeDiscussion(document_id, current_user_id);

            var tempStack = prevReaction;
            if (prevReaction.length === 0) {
                let currentDislike = tempDislikes;
                currentDislike += 1;
                setTempDislikes(currentDislike);
                tempStack.unshift("D");
                setPrevReaction(tempStack);
            } else if (prevReaction.length > 0 && prevReaction[0] === "L") {
                // console.log("You previously liked");
                tempStack.shift();
                let currentLikes = tempLikes;
                let currentDislikes = tempDislikes;
                currentLikes -= 1;
                currentDislikes += 1;
                setTempDislikes(currentDislikes);
                setTempLikes(currentLikes);
                tempStack.push("D");
                setPrevReaction(tempStack);
            } else if (prevReaction.length > 0 && prevReaction[0] === "D") {
                // console.log("You have already disliked");
                tempStack.shift();
                let currentDislikes = tempDislikes;
                currentDislikes -= 1;
                setTempDislikes(currentDislikes);
                setPrevReaction(tempStack);
            }
        }
    }

    return { prevReaction, tempLikes, tempDislikes, userForumReaction,
        handleClickLike, handleClickDislike };
}