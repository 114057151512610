/* eslint-disable no-unused-vars */
// react states
import { useState, useEffect } from 'react';

// firebase
import { db } from '../Firebase/firebase';
import { collection, getDocs, doc, getDoc } from "firebase/firestore";

// Global variable to be used.
export var allGamesData = [];

export const useGetAllGames = ()=>{
    const [loadingGames, setLoadingGames] = useState(false);
    const [errorLoadingGames, setErrorLoadingGames] = useState(null);
    const [allGamesID, setAllGamesID] = useState([]);

    // useEffect(()=>{
    //     if(allGamesData.length > 0){
    //         return;
    //     }
    //     if(allGamesID.length > 0){
    //         setAllGamesID(allGamesID);
    //         return;
    //     }
    //     else{
    //         getAllGames();
    //     }
    // },[])

    useEffect(()=>{
        getAllGames();
    },[])

    const getAllGames = async()=>{
        setLoadingGames(true);
        const gameQuerySnapshot = await getDocs(collection(db, "Games"))
        .catch((err)=>{
            setErrorLoadingGames(err.message);
            setLoadingGames(false);
            return;
        });
        var tempIDarr = [];
        for(const game of gameQuerySnapshot.docs){
            
            var tempObj = {};

            tempObj = {
                'id':game.id,
                ...game.data(),
            }
            
            tempIDarr.push({
                'id':game.id,
                'name':game.data().name
            });
            allGamesData.push(tempObj);
        }
        setAllGamesID(tempIDarr);
        setErrorLoadingGames(null);
        setLoadingGames(false);
    }

    return { loadingGames, errorLoadingGames, allGamesID };
}