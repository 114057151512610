// styles
import { CircularProgress } from '@mui/material';
import GenreAbout from '../../Components/GenreComponents/genreAbout';
import { useGetGenre } from '../../Hooks/Genres/useGetGenre';
import { useGetGenreGames } from '../../Hooks/Genres/useGetGenreGames';
import './genrePage.css';
// react router dom
import { useParams, useNavigate } from 'react-router-dom';
import { useGetAllGenres } from '../../Hooks/Genres/useGetAllGenres';
// For SEO and indexing purpose
import { Helmet } from 'react-helmet';

export default function GenrePage(){
    
    const { genre_id } = useParams();

    // For SEO purposes
    const genre_canonical_url = `https://thegamerbroz.com/genres/cHfyc0CsY7H3jQvCeDSh`;
    
    // To navigate to different genres
    const genreNav = useNavigate();
    const handleNavigateToOtherGenre = (genre)=>{
        if(genre !== genre_id){
            genreNav(`/genres/${genre}`)
        }
    }

    // To navigate to different games
    const navigateToSelectedGame = (game_id)=>{
        genreNav(`/game_page/${game_id}`);
    }

    // To get the genre details
    const { genreData } = useGetGenre(genre_id);
    // To get the genre games
    const { genreGames, loadingGenreGames } = useGetGenreGames(genre_id);
    // To get the list of all the genres
    const { allGenres, loadingAllGenres } = useGetAllGenres();

    return(
        <div className="genre-page-container">
            <Helmet>
                {genreData && <title>{genreData.name}</title>}
                <link rel="canonical" href={genre_canonical_url}/>
                <meta name='tagline' content={'For the gamers. By the gamers.'}/>
                <meta name='keywords' content={'game, genres, gaming genres, video game genres'}/>
            </Helmet>
            <div className="all-genres-list-container">
            {
                loadingAllGenres === false && allGenres
                && allGenres.length > 0 &&
                allGenres.map((genre)=>(
                    <button key={genre.id} 
                            className={(genre_id !== genre.id) ? "all-genre-tile" : "all-genre-tile selected"}
                            onClick={()=>handleNavigateToOtherGenre(genre.id)}
                    >
                        {genre.name}
                    </button>
                ))
            }
            </div>
            <div className="selected-genre-details-container">
                <div className="genre-page-ad-component-1">
                </div>
                {
                    genreData !== null && genreData !== undefined &&
                    <div className='genre-about-container'>
                        <h1 className='genre-page-heading'>{genreData.name}</h1>   
                        <GenreAbout genreDetails={genreData}/>
                        <p className='genre-page-sub-heading'>
                            {`Games is ${genreData.name}`}
                        </p>
                        <div className="genre-games-list-component">
                        {
                            loadingGenreGames === true &&
                            <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                        }
                        {
                            loadingGenreGames === false && genreGames
                            && genreGames.length > 0 &&
                            genreGames.map((genreGame)=>(
                                <div key={genreGame.id} className="genre-game-tile"
                                     onClick={()=>navigateToSelectedGame(genreGame.id)}
                                >
                                    <img src={genreGame.thumbnail_image} alt={genreGame.name} />
                                </div>
                            ))
                        }
                        </div>
                    </div>
                }
                <div className="genre-page-ad-component-2">
                </div>
            </div>
        </div>
    )
}