// styles
import './user_signup.css';
// react states
import { useState } from 'react';
// custom hook(s)
import { useUserAuth } from '../../../Hooks/Auths/useUserAuth';
// mui components
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, CircularProgress, TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



export default function UserSignUp({ navPrompt }){

    const welcomeMessage = "Hello there! Welcome. Let's start by knowing you….";

    // Hook to use for logging in
    const { error, setError, mailSent, loading, signUpUser } = useUserAuth();

    // To toggle the visibility of the password
    const [visible, setVisible] = useState(false);
    const toggleVisibility = ()=>{
        if(visible === false){
            setVisible(true);
        }else{
            setVisible(false);
        }
    }

    // To handle the submit events
    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    
    const handleSignup = async(e)=>{
        if(mail.length === 0 || pass.length === 0 || confirmPass.length === 0){
            setError("Please fill in the required fields.");
            return;
        }
        if(confirmPass !== pass){
            setError("Both passwords don't match. Please make sure the passwords are same");
            return;
        }

        await signUpUser(mail, pass);
    }

    return(
        <div className="user-signup-container">
            <h4>{welcomeMessage}</h4>
            <div className="user-signup-input-form">
                <TextField variant='outlined' 
                    sx={{ 'width':'100%', '& .MuiOutlinedInput-root': {
                            color: '#f8f8f8', // Text color
                            '& fieldset': {
                                borderColor: '#9e9e9e', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: '#f8f8f8', // Outline color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#f8f8f8', // Outline color when focused
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: '#9e9e9e', // Label color
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#f8f8f8', // Label color when focused
                        },
                        }}
                    label='Enter your email'
                    value={mail}
                    onChange={(e)=>setMail(e.target.value)}
                />
                <TextField variant='outlined' 
                    sx={{ 'width':'100%', '& .MuiOutlinedInput-root': {
                            color: '#f8f8f8', // Text color
                            '& fieldset': {
                                borderColor: '#9e9e9e', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: '#f8f8f8', // Outline color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#f8f8f8', // Outline color when focused
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: '#9e9e9e', // Label color
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#f8f8f8', // Label color when focused
                        },
                        }}
                    label='Enter your Password'
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={()=>toggleVisibility()}
                                edge="end"
                                sx={{ color:'#f8f8f8' }}
                            >
                            {visible === false ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    value={pass}
                    type={visible === true ? 'text' : 'password'}
                    onChange={(e)=>setPass(e.target.value)}
                />
                <TextField variant='outlined' 
                    sx={{ 'width':'100%', '& .MuiOutlinedInput-root': {
                            color: '#f8f8f8', // Text color
                            '& fieldset': {
                                borderColor: '#9e9e9e', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: '#f8f8f8', // Outline color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#f8f8f8', // Outline color when focused
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: '#9e9e9e', // Label color
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#f8f8f8', // Label color when focused
                        },
                        }}
                    label='Confirm your Password'
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={()=>toggleVisibility()}
                                edge="end"
                                sx={{ color:'#f8f8f8' }}
                            >
                            {visible === false ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    value={confirmPass}
                    type={visible === true ? 'text' : 'password'}
                    onChange={(e)=>setConfirmPass(e.target.value)}
                />
                {
                    loading === false && mailSent !== true &&
                    <Button variant='contained'
                        sx={{ width:'100%', height:'45px', backgroundColor:'#ffe74c', color:'#080808', 
                            borderRadius:'5px 5px 5px 5px',
                            '&:hover': {
                                backgroundColor: '#fff74c', // Hover color
                            },
                        }}
                        endIcon={<ArrowForwardIcon/>}
                        onClick={(e)=>handleSignup(e)}
                    >
                        Sign up
                    </Button>
                }
                {
                    loading === true && <CircularProgress size={24} sx={{ color:'#ffe74c' }}/>
                }
                {
                    error !== null && <p className='sending-email-feedback'>{error}</p>
                }
                {
                    mailSent === true && <p className='sending-email-feedback'>Verification link sent successfully! Please check your mail.(Note: Please also check the "Spam" folder.)</p>
                }
                <p className='sending-email-feedback'>Already have an account? <a style={{ textDecoration:'None', color:'#f8f8f8', fontStyle:'italic' }} href='https://thegamerbroz.com/user_auth'>Log in</a></p>
            </div>
        </div>
    )
}