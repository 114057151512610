// react states
import { useState } from 'react';
// firebase 
import { auth, db } from '../../Firebase/firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
// react-redux
import { useDispatch } from 'react-redux';

export const useUserAuth = ()=>{
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mailSent, setMailSent] = useState(false);

    const userSignupDispatch = useDispatch();

    const signUpUser = async(mail, password)=>{
        setLoading(true);
        setError(null);

        try {
            // Obtain user credentials for sign up.
            const userCredentials = await createUserWithEmailAndPassword(auth, mail, password);
            const user = userCredentials.user;
            // Now after obtaining these credentials, send verification mail.
            await sendEmailVerification(user);
            // Make sure to let the user know that the verification mail has been sent successfully.
            setMailSent(true);
            // And after you get a confirmation that the email is verified, add the user to the database.
            await createUserData(user.uid, user.email);
            // Finally return the user.
            setLoading(false);
            return user;
        } catch (error) {
            setError(error.message);
            setLoading(false);
            return;
        } finally{
            setLoading(false);
            return;
        }

    }

    const createUserData = async(user_id, email)=>{
        setLoading(true);
        
        await setDoc(doc(db, "Users", `${user_id}`), {
            'Name':email,
            'email':email,
            'all_consoles':[],
            'current_console':"",
            'profile_picture':null,
            'user_cover_image':null,
            'isInDatabase':false
        }).then(async()=>{
            await setDoc(doc(db, `Users/${user_id}/PrivateInfo`,"userInfo"),{
                'DOB':null,
                'favourites':[],
                'game_collection':[],
                'currently_playing':[],
                'games_reviewed':[],
                'country':'',
                'playlist':[],
                'wishlist':[]
            }).catch((profileUpdateError)=>{
                setError(profileUpdateError);
                setLoading(false);
                return;
            });
        }).catch((err)=>{
            // console.log(err.message);
            setError(err.message);
            setLoading(false);
            // setStatus('Something went wrong! Please try again after few minutes...');
            return;
        })
    }

    return { loading, error, setError, mailSent, signUpUser, createUserData };
}