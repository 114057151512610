/* eslint-disable react-hooks/exhaustive-deps */
// THIS IS TEMPORARY SOLUTION.
// FIND A BETTER ONE.
// react states
import { useEffect, useState } from "react";
// database
import { db } from '../../Firebase/firebase';
import { query, collection, getDocs, where } from 'firebase/firestore';

export const useGetConsoleFromAcronym = (acr)=>{
    const [consoleID, setConsoleID ] = useState(null);
    const [loadingConsoleDetails, setLoadingConsoleDetails] = useState(false);
    const [errorConsoleDetails, setErrorConsoleDetails] = useState(null);

    const getConsoleIDFromAcronym = async()=>{
        const consoleAcrKey = acr;
        const acrCachedData = sessionStorage.getItem(consoleAcrKey);
        if(acrCachedData){
            setConsoleID(JSON.parse(acrCachedData));
        }else{
            setLoadingConsoleDetails(true);
            setErrorConsoleDetails(null);

            const acrQuery = query(collection(db, 'Consoles'),where('acronym','==',`${acr}`));
            const acrSnapshot = await getDocs(acrQuery)
            .catch((err)=>{
                setErrorConsoleDetails(err.message);
                setLoadingConsoleDetails(false);
                return;
            });

            for(const console of acrSnapshot.docs){
                const consoleID = console.id;
                setConsoleID(consoleID);
                sessionStorage.setItem(consoleAcrKey, JSON.stringify(consoleID));
            }
        }

        setLoadingConsoleDetails(false);
    }

    useEffect(()=>{
        if(acr !== null && acr !== undefined){
            getConsoleIDFromAcronym();
        }
    },[acr])

    return { consoleID, loadingConsoleDetails, errorConsoleDetails };
}