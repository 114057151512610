// react states
import { useState } from 'react';
// firebase
import { auth } from '../Firebase/firebase';
import { confirmPasswordReset } from 'firebase/auth';


export const usePasswordReset = ()=>{

    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const [resetting, setResetting] = useState(false);

    const resetUserPassword = ( oobCode, newPassword )=>{
        setResetting(true);
        confirmPasswordReset(auth, oobCode, newPassword)
        .then(()=>{
            setStatus('success');
            setError(null);
            setResetting(false);
        }).catch((err)=>{
            setStatus('error');
            setError(err.message);
            setResetting(false);
        })
    }


    return { status, error, resetting, resetUserPassword };

}