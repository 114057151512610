// styles
import './trendingForumArea.css';
import TrendingForumCarousel from './TrendingForumCarousel/trendingForumCarousel';

export default function TrendingForumArea(){
    return(
        <div className="trending-forum-area">
            <p className='trending-forum-area-heading'>Trending forums</p>
            <TrendingForumCarousel/>
        </div>
    )
}